import React from "react";
import logo from "../../assets/images/logo-dark.png";
import "./style.scss";
import { Avatar, Divider, Menu, MenuItem } from "@mui/material";
import { getUserName, removeUserInfo, removeUserName } from "../../utils/user";
import ButtonComponent from "../Button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { setDrawerClose, setDrawerOpen } from "../../redux/actions";
import { connect } from "react-redux";
import { rootPath } from "../../utils/path";

const mapStateToProps = (state) => ({
  nav: state.drawerReducer.nav,
});

const mapDispatchToProps = (dispatch) => ({
  onSetDrawerOpen: (data) => dispatch(setDrawerOpen(data)),
  onSetDrawerClose: (data) => dispatch(setDrawerClose(data)),
});

const NewNavbar = ({ onSetDrawerOpen, onSetDrawerClose, nav }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    removeUserInfo();
    removeUserName();
    navigate(rootPath + "/");
    localStorage.clear();
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <div
      className={
        "navbar-cont" +
        (nav.width === 270 ? " width-minus-270px" : " width-minus-69px")
      }
    >
      <div className="left-menu-cont">
        <svg
          onClick={() => onSetDrawerOpen({ open: true, width: 270 })}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="menu_icon"
          style={{ width: 30 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
        <img alt="Bultaminds Jobs" src={logo} style={{ height: 30 }} />
      </div>
      <div className="right-menu-cont">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="icon"
          style={{ width: 25 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="icon"
          style={{ width: 25 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
          />
        </svg>
        <svg
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
          style={{ width: 23 }}
        >
          <path
            d="M19.5 12.75C19.5 10.868 18.391 9.252 16.8 8.484V6.972C16.8 3.128 13.637 0 9.75 0C5.863 0 2.7 3.128 2.7 6.972V8.484C1.109 9.252 0 10.868 0 12.75C0 15.369 2.131 17.5 4.75 17.5C5.715 17.5 6.5 16.715 6.5 15.75V9.75C6.5 8.785 5.715 8 4.75 8C4.562 8 4.383 8.034 4.2 8.056V6.973C4.2 3.955 6.69 1.501 9.75 1.501C12.81 1.501 15.3 3.956 15.3 6.973V8.056C15.117 8.035 14.938 8 14.75 8C13.785 8 13 8.785 13 9.75V15.75C13 16.144 13.136 16.505 13.357 16.798C13.038 16.931 12.692 17 12.336 17H11.597C11.396 16.623 11.011 16.357 10.554 16.357H8.947C8.286 16.357 7.751 16.893 7.751 17.553C7.751 18.213 8.287 18.75 8.947 18.75H10.554C10.82 18.75 11.052 18.646 11.251 18.5H12.336C13.348 18.5 14.294 18.126 15.05 17.47C17.527 17.312 19.5 15.267 19.5 12.75ZM4.75 9.5C4.888 9.5 5 9.612 5 9.75V15.75C5 15.888 4.888 16 4.75 16C2.958 16 1.5 14.542 1.5 12.75C1.5 10.958 2.958 9.5 4.75 9.5ZM14.75 16C14.612 16 14.5 15.888 14.5 15.75V9.75C14.5 9.612 14.612 9.5 14.75 9.5C16.542 9.5 18 10.958 18 12.75C18 14.542 16.542 16 14.75 16Z"
            fill="black"
          />
        </svg>
        <div className="avatar-cont" onClick={handleClick}>
          <Avatar
            style={{ fontSize: "16px" }}
            {...stringAvatar(getUserName())}
          />
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <div className="profile-cont">
              {/* <Avatar {...stringAvatar(getUserName())} /> */}
              <h4 className="profile-name">{getUserName()}</h4>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/employer/profile");
            }}
          >
            <div className="profile-cont">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                class="icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <p className="menu-item-title">View Profile</p>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className="profile-cont">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                class="icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                />
              </svg>
              <p className="menu-item-title">Company Profile</p>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              logout();
            }}
          >
            <div className="profile-cont">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                class="icon sign-out"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>
              <p className="menu-item-title sign-out">Sign out</p>
            </div>
          </MenuItem>
        </Menu>
        {/* <div className="post-job-btn-cont">
          <ButtonComponent
            title={"Post a job"}
            variant={"contained"}
            style={{ width: "fit-content" }}
            onClick={() => navigate("/employer/jobs/post-job")}
          />
        </div> */}
        {/* <div
          onClick={() => navigate("/employer/jobs/post-job")}
          className="post-job-icon-cont">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            class="icon"
            style={{ width: 20, color: "white" }}>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
        </div> */}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewNavbar);
