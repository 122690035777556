import React, {useState, useEffect} from 'react'
import Footer from '../../../components/Footer'
import AppNavbar from '../../../components/Navbar'
import CandidateNavBar from '../../../components/Candidate/Navbar'
import { Link } from "react-router-dom"
import { getToken } from "../../../utils/user"
import { request } from "../../../utils/api"
import { SyncLoader } from "react-spinners"
import { candidatePath } from '../../../utils/path'

export default function AppliedJobs() {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request
      .post(
        "jobs/candidate/list",
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);

        setAppliedJobs(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <CandidateNavBar />
      <div className="section margin-top-65">
        <div className="container">
          <div className="row form-group">
            <div className="col-md-12">
              <ul className="nav">
                <li>
                  <span className="application-status-badge bg-white">
                    Job applies ({appliedJobs.length})
                  </span>
                </li>
                <li>
                  <span className="application-status-badge bg-white">
                    Shortlisted (0)
                  </span>
                </li>
                <li>
                  <span className="application-status-badge bg-white">
                    Rejected (0)
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            {appliedJobs.map((item, index) => (
              <div className="col-md-4 form-group" key={index}>
                <div className="card border-radius-8">
                  <div className="card-body">
                    <Link
                      to={
                        candidatePath +
                        "/application-status/" +
                        item.job_unique_code
                      }
                    >
                      <h5 className="inner-heading">{item.job_title}</h5>
                      <p className="card-text mt-2">
                        {item.company_hiring_for}
                      </p>
                      <p className="card-text mt-4 mb-2">
                        <span className="application-status-badge">
                          <i
                            className="fa fa-check-circle mr-2"
                            style={{ color: "#47b749", fontSize: "14px" }}
                          ></i>
                          {item.application_status} on {item.apply_date}
                        </span>
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
