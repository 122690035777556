import React, { useState } from "react";
// import "../Login/style.scss";
import "../Login/style.scss";
import "./style.scss";
import registerbgimg from "../../../assets/images/employer/registerbgimg.png";
import logo from "../../../assets/images/employer/logo.png";
import { faInfo, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import BasicDetailsForm from "./BasicDetails";
import AccountInformationForm from "./AccountInformation";
import ContactInformationForm from "./ContactInformation";
import PasswordForm from "./PasswordForm";
import OtpForm from "./OtpForm";
import IconComponent from "../../../components/Icon";
import ButtonComponent from "../../../components/Button";
import AppNavbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";

const mapStateToProps = (state) => ({
  basicDetails: state.userDetailsReducer.basicDetails,
  accountInformation: state.userDetailsReducer.accountInformation,
  contactInformation: state.userDetailsReducer.contactInformation,
});

const mapDispatchToProps = (dispatch) => ({});

const SignUp = ({ basicDetails, accountInformation, contactInformation }) => {
  const [screen, setScreen] = useState(1);

  const getRegisterCard = () => {
    switch (screen) {
      case 1:
        return <BasicDetailsForm setScreen={setScreen} screen={screen} />;
      case 2:
        return <OtpForm />;
      // case 3:
      //   return <ContactInformationForm setScreen={setScreen} screen={screen} />;
      // case 5:
      //   return <PasswordForm setScreen={setScreen} screen={screen} />;
      // case 6:
      //   return <OtpForm />;
      default:
        break;
    }
  };

  const textComponent = (title, value) => {
    return (
      <div className="text-component-cont">
        <p className="text-heading">{title}</p>
        <p className="text-value">{value ? value : "N/A"}</p>
      </div>
    );
  };

  const basicDetailsPreview = () => {
    return (
      <div className="preview-section-cont">
        {textComponent("Who am I?", basicDetails.whoami.title)}
        {textComponent("Lead Source", basicDetails.leadSource.title)}
        {textComponent("Organisation Name", basicDetails.orgName)}
        {textComponent("Email", basicDetails.email)}
      </div>
    );
  };

  const accountDetailsPreview = () => {
    return (
      <div className="preview-section-cont">
        {/* {textComponent("Organisation Code", accountInformation.orgCode)} */}
        {textComponent("Organisation Name", accountInformation.displayName)}
        {textComponent("Website", accountInformation.website)}
        {textComponent("Default GSTIN", accountInformation.gstin)}
        {textComponent("PAN Card Number", accountInformation.pancard)}
        {textComponent("Industry", accountInformation.industry.title)}
        {/* {textComponent("Segment", accountInformation.segment.title)} */}
      </div>
    );
  };

  const contactDetailsPreview = () => {
    return (
      <div className="preview-section-cont">
        {textComponent("Pincode", contactInformation.pincode)}
        {textComponent("Locality", contactInformation.locality)}
        {textComponent("Address", contactInformation.address)}
        {textComponent("City/District", contactInformation.cityDist)}
        {textComponent("State", contactInformation.state.title)}
        {textComponent("Landline No", contactInformation.landlineNo)}
        {textComponent("Alternate Mobile No", contactInformation.altMobileNo)}
        {textComponent("Alternate Email", contactInformation.altEmail)}
        {textComponent("Landmark", contactInformation.landmark)}
      </div>
    );
  };

  const handleSubmit = () => {
    setScreen(screen + 1);
  };

  return (
    <>
      <AppNavbar />
      <div className="employer-auth-container">
        {screen === 4 ? (
          <div className="preview-cont">
            {/* <img className="logo" src={logo} alt="logo" /> */}
            <h1 className="preview-heading">Create an account</h1>
            <h3 className="preview-sub-heading">
              Signup now and unlock exclusive access!
            </h3>
            <div className="heading-edit-cont">
              <h4 className="heading">Basic Details</h4>
              <IconComponent onClick={() => setScreen(1)}>
                <FontAwesomeIcon fontSize={12} icon={faPen} />
              </IconComponent>
            </div>
            {basicDetailsPreview()}
            <div className="heading-edit-cont">
              <h4 className="heading">Account Details</h4>
              <IconComponent onClick={() => setScreen(2)}>
                <FontAwesomeIcon fontSize={12} icon={faPen} />
              </IconComponent>
            </div>
            {accountDetailsPreview()}
            <div className="heading-edit-cont">
              <h4 className="heading">Contact Details</h4>
              <IconComponent onClick={() => setScreen(3)}>
                <FontAwesomeIcon fontSize={12} icon={faPen} />
              </IconComponent>
            </div>
            {contactDetailsPreview()}
            <div className="submit-cont">
              <ButtonComponent
                style={{ width: 250 }}
                title={"Next"}
                variant={"contained"}
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : (
          <div className="login-cont">
            <div className="login-card-cont register-card-cont">
              {/* <img className="logo" src={logo} alt="logo" /> */}
              <h1 className="login-heading">Create an account</h1>
              <h3 className="login-sub-heading">
                Signup now and unlock exclusive access!
              </h3>
              {getRegisterCard()}
            </div>
            <div className="login-bg-img-cont">
              <img
                className="login-bg-img"
                src={registerbgimg}
                alt="login-bg-img"
              />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
