import React, { useEffect, useState } from "react";
import "./style.scss";
// import IconComponent from "../../Components/Icon";
import { employerPath } from "../../../utils/path";
import {
  faBriefcase,
  faCalendar,
  faFemale,
  faGraduationCap,
  faLaptopHouse,
  faMapMarkerAlt,
  faPhone,
  faStar,
  faUserTie,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import SmallCandidateCard from "../../Components/SmallCandidateCard";
// import studentsList from "../../Data/studentsList.json";
import { Link, useParams } from "react-router-dom";
import AppNavbar from "../../../components/Navbar";
import Drawer from "../../../components/Drawer";
import { request } from "../../../utils/api";
import { getToken } from "../../../utils/user";
import { SyncLoader } from "react-spinners";
import NewNavbar from "../../../components/NewNavbar";
import { setDrawerOpen } from "../../../redux/actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  nav: state.drawerReducer.nav,
});
const mapDispatchToProps = (dispatch) => ({
  onSetDrawerOpen: (data) => dispatch(setDrawerOpen(data)),
});


const JobDescription = ({nav}) => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  //console.log(id);

  useEffect(() => {
    setLoading(true);
    request
      .post(
        "jobDeatilsById",
        {
          jobUniqueCode: id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        //console.log("res.data.data: ", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [id]);

  //render header
  const renderHeader = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-between pb-2">
            <div className="float-start">
              <h1 className="job-heading">
                {data && data.job_title}{" "}
                <span>
                  (#{data && data.job_unique_code})
                </span>
              </h1>
              Company: {data && data.company_hiring_for}
            </div>

            <div></div>
          </div>
        </div>
      </div>
    );
  };

  //render job details
  const renderJobDetail = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-8">
            {data && (
              <>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h3 className="inner-heading ">Basic Details</h3>
                      <IconButton
                        onClick={() =>
                          navigate(employerPath + "/jobs/post-job", {
                            state: { jId: id, screen: 1 },
                          })
                        }
                        color="primary"
                        aria-label="edit job"
                        component="span"
                      >
                        <EditNoteIcon fontSize="small" />
                      </IconButton>
                    </div>
                    <div className="content-inner">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faMapMarkerAlt}
                          />
                          <span
                            className="inner-sub-heading"
                            style={{ marginLeft: 10 }}
                          >
                            Location:{" "}
                          </span>
                          <span>{data && data.location}</span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faWallet}
                          />
                          <span
                            className="inner-sub-heading"
                            style={{ marginLeft: 10 }}
                          >
                            Salary:{" "}
                          </span>
                          <span>
                            {data && data.currency} {data.minimum_salary ?? 0} -{" "}
                            {data.maximum_salary ?? 0}
                          </span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faStar}
                          />
                          <span
                            className="inner-sub-heading"
                            style={{ marginLeft: 10 }}
                          >
                            Experience:{" "}
                          </span>
                          <span>
                            {data && data.minimum_experience} -
                            {data.maximum_experience} Years
                          </span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faBriefcase}
                          />
                          <span
                            className="inner-sub-heading"
                            style={{ marginLeft: 10 }}
                          >
                            Segment:{" "}
                          </span>
                          <span>{data && data.category}</span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faLaptopHouse}
                          />
                          <span
                            className="inner-sub-heading"
                            style={{ marginLeft: 10 }}
                          >
                            Employment Mode:
                          </span>
                          <span> {data && data.work_location_type}</span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faBriefcase}
                          />
                          <span
                            className="inner-sub-heading"
                            style={{ marginLeft: 10 }}
                          >
                            Employment Type:{" "}
                          </span>
                          <span>{data && data.employment_type}</span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faGraduationCap}
                          />
                          <span
                            className="inner-sub-heading"
                            style={{ marginLeft: 10 }}
                          >
                            Degree:{" "}
                          </span>
                          <span>{data && data.degree}</span>
                        </div>
                        <div className="col-md-8 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faBriefcase}
                          />
                          <span
                            className="inner-sub-heading"
                            style={{ marginLeft: 10 }}
                          >
                            Department:{" "}
                          </span>
                          <span>{data && data.department}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h3 className="inner-heading ">Job Description</h3>
                      <IconButton
                        onClick={() =>
                          navigate(employerPath + "/jobs/post-job", {
                            state: { jId: id, screen: 2 },
                          })
                        }
                        color="primary"
                        aria-label="edit job"
                        component="span"
                      >
                        <EditNoteIcon fontSize="small" />
                      </IconButton>
                    </div>
                    <div className="content-inner">
                      <div className="row">
                        <div className="col-md-12">
                          <p>{data && data.job_description}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {data &&
                          (data.minimum_education !== "" ||
                            data.degree !== "") ? (
                            <div className="d-flex">
                              <h3
                                className="inner-sub-heading"
                                style={{ marginRight: 10 }}
                              >
                                Qualification:{" "}
                              </h3>
                              <span
                                style={{ marginRight: 10, lineHeight: "16px" }}
                              >
                                {data.degree ?? ""}
                              </span>
                              <span style={{ lineHeight: "16px" }}>
                                {data.minimum_education ?? ""}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12 mt-2">
                          <div className="d-flex">
                            <h3 className="inner-sub-heading">
                              Skills Required:
                            </h3>
                            {data.skills.map((skill) => {
                              return (
                                <span
                                  key={skill.name}
                                  style={{ lineHeight: "16px" }}
                                >
                                  {skill.name}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <div className="d-flex">
                            <h3 className="inner-sub-heading">
                              Additional Perks:
                            </h3>
                            {data.additional_perks !== "" ||
                            data.additional_perks !== null
                              ? data.additional_perks
                                  .split(", ")
                                  .map((perk, index) => {
                                    return (
                                      <span
                                        key={index}
                                        style={{ lineHeight: "16px" }}
                                      >
                                        {perk}
                                      </span>
                                    );
                                  })
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h3 className="inner-heading ">Other Informations</h3>
                      <IconButton
                        onClick={() =>
                          navigate(employerPath + "/jobs/post-job", {
                            state: { jId: id, screen: 3 },
                          })
                        }
                        color="primary"
                        aria-label="edit job"
                        component="span"
                      >
                        <EditNoteIcon fontSize="small" />
                      </IconButton>
                    </div>
                    <div className="content-inner">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faUserTie}
                          />
                          <span className="inner-sub-heading ml-2">
                            Interview Mode:
                          </span>
                          <span>{data && data.interview_mode}</span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faPhone}
                          />
                          <span className="inner-sub-heading ml-2">
                            Contact Candidate:
                          </span>
                          <span>
                            {data && data.contact_candidate_via_call_whatsapp}
                          </span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faFemale}
                          />
                          <span className="inner-sub-heading ml-2">
                            Female Candidates Allowed:
                          </span>
                          <span>{data && data.female_candidate_allowed}</span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faWallet}
                          />
                          <span className="inner-sub-heading ml-2">
                            Joining Fee Required:
                          </span>
                          <span>
                            {data && (data.joining_fee === "1" ? "Yes" : "No")}
                          </span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faWallet}
                          />
                          <span className="inner-sub-heading ml-2">
                            Payment Type:
                          </span>
                          <span>{data && data.payment_type}</span>
                        </div>
                        <div className="col-md-6 form-group">
                          <FontAwesomeIcon
                            color="#aeaeae"
                            fontSize={13}
                            icon={faCalendar}
                          />
                          <span className="inner-sub-heading ml-2">
                            Posted Date:
                          </span>
                          <span>{data && data.posted_date}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-md-4">{renderBillingDetails()}</div>
        </div>
      </>
    );
  }

  const renderBillingDetails = () => {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="inner-heading">Billing Details</h3>
          <table className="billing-table">
            <tbody>
              <tr>
                <td className="heading">Service Type</td>
                <td>Pay Per Hire</td>
              </tr>
              <tr>
                <td className="heading">Service Plan</td>
                <td>SME Lite</td>
              </tr>
              <tr>
                <td className="heading">HSN Code</td>
                <td>998512</td>
              </tr>
              <tr>
                <td className="heading">Service Fee</td>
                <td>61.15%</td>
              </tr>
              <tr>
                <td className="heading">Invoice To Raise By</td>
                <td>7th Oct 2023</td>
              </tr>
              <tr>
                <td className="heading">Client To Pay By</td>
                <td>15th Oct 2023</td>
              </tr>
              <tr>
                <td className="heading">Total</td>
                <td>
                  <span className="fa fa-rupee"></span> 999.00/-
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <AppNavbar />
      <div className="main d-flex">
        <Drawer />
        <div
          className="content mobile-content"
          style={{ marginLeft: nav.width }}
        >
          <div className="content-inner">
            {renderHeader()}
            {renderJobDetail()}
          </div>
        </div>
        {loading ? (
          <div className="loader-cont-fixed">
            <SyncLoader
              color={"rgba(54, 215, 183, 1)"}
              loading={loading}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

//export default JobDescription;
export default connect(mapStateToProps, mapDispatchToProps)(JobDescription);
