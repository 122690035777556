import React from "react";
import "./Checkbox.css";
import { Checkbox } from "@mui/material";

const CheckboxComponent = ({ text, value, onChange }) => {
  return (
    <div className="checkbox-cont">
      <Checkbox checked={value} onChange={() => onChange(!value)} />
      <p className="checkbox-text">{text}</p>
    </div>
  );
};

export default CheckboxComponent;
