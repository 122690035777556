import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getToken } from "../../../utils/user";
import { request } from "../../../utils/api";
import CandidateJobCard from "../../../components/JobCard/CandiateJobCard";
import { SyncLoader } from "react-spinners";
import NotFoundIcon from "../../../components/NotFoundIcon";
import { candidatePath } from "../../../utils/path";

export default function MyJob() {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request
      .post(
        "jobs/candidate/list",
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);

        setAppliedJobs(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="app-card">
          <div className="d-flex justify-content-between">
            <h3 className="card-heading">Applied Jobs</h3>
            <div className="nav">
              <Link to={candidatePath + "/applied-jobs"} className="view-more">
                View more
              </Link>
            </div>
          </div>
          <div className="cards-list">
            {loading ? (
              <div className="loader-cont">
                <SyncLoader
                  color={"rgba(54, 215, 183, 1)"}
                  loading={loading}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : appliedJobs.length === 0 ? (
              <NotFoundIcon
                text={"Seems like you do not have applied to any jobs!"}
              />
            ) : (
              <div className="list">
                {appliedJobs.map((job, index) => {
                  if (index < 9) {
                    return (
                      <CandidateJobCard
                        key={job.job_unique_code}
                        data={job}
                        type={"my_jobs"}
                      />
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
