import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import JobsCategory from "../../components/Home/JobsCategory";
import TopBanner from "../../components/Home/TopBanner";
import AppNavbar from "../../components/Navbar";
import { candidatePath, rootPath } from "../../utils/path";
import { getUserInfo, isLoggedIn } from "../../utils/user";

const CONSTANTS = {
  USER_TYPE: {
    EMPPLOYER: "Employer",
    CANDIDATE: "Candidate",
  },
};

export default function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn()) {
      const userInfo = getUserInfo();
      if (userInfo.userType === CONSTANTS.USER_TYPE.CANDIDATE) {
        navigate(candidatePath + "/dashboard");
      } else if (userInfo.userType === CONSTANTS.USER_TYPE.EMPPLOYER) {
        navigate("/employer/jobs");
      }
    }
  }, []);
  return (
    <>
      <AppNavbar />
      <TopBanner />
      <JobsCategory />
      <Footer />
    </>
  );
}
