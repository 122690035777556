import { candidatePath } from "../utils/path";
import Login from "../pages/Candidate/Auth/Login";
import Signup from "../pages/Candidate/Auth/Signup";
import Dashboard from "../pages/Candidate/Dashboard";
import AppliedJobs from "../pages/Candidate/AppliedJobs";
import RecommendedJobs from "../pages/Candidate/Jobs/RecommendedJobs";
import MyProfile from "../pages/Candidate/Profile";
import ReferAFriend from "../pages/Candidate/ReferAFriend";
import Faqs from "../pages/Candidate/Faqs";
import Settings from "../pages/Candidate/Settings";
import ForgotPassword from "../pages/Candidate/Auth/ForgotPassword";
import OtpVerification from "../pages/Candidate/Auth/OtpVerification";
import ChangePassword from "../pages/Candidate/Auth/ChangePassword";
import JobDescription from "../pages/Candidate/JobDescription";
import JobApplicationStatus from "../pages/Candidate/Jobs/JobApplicationStatus";

const candidateRoutes = [
  {
    path: candidatePath + "/auth/login",
    title: "Login | Bultaminds Jobs",
    component: <Login />,
    isPublic: true,
  },
  {
    path: candidatePath + "/auth/forgot-password",
    title: "Forgot Password | Bultaminds Jobs",
    component: <ForgotPassword />,
    isPublic: true,
  },
  {
    path: candidatePath + "/auth/otp-verification",
    title: "OTP Verification | Bultaminds Jobs",
    component: <OtpVerification />,
    isPublic: false,
  },
  {
    path: candidatePath + "/auth/change-password",
    title: "Change Password | Bultaminds Jobs",
    component: <ChangePassword />,
    isPublic: false,
  },
  {
    path: candidatePath + "/auth/signup",
    title: "Signup | Bultaminds Jobs",
    component: <Signup />,
    isPublic: true,
  },
  {
    path: candidatePath + "/dashboard",
    title: "Dashboard | Bultaminds Jobs",
    component: <Dashboard />,
    isPublic: false,
  },
  {
    path: candidatePath + "/applied-jobs",
    title: "My Jobs",
    component: <AppliedJobs />,
    isPublic: false,
  },
  {
    path: candidatePath + "/recommended-jobs",
    title: "Recommended Jobs",
    component: <RecommendedJobs />,
    isPublic: false,
  },
  {
    path: candidatePath + "/myprofile",
    title: "Profile",
    component: <MyProfile />,
    isPublic: false,
  },
  {
    path: candidatePath + "/refer-a-friend",
    title: "Refer a Friend",
    component: <ReferAFriend />,
    isPublic: false,
  },
  {
    path: candidatePath + "/faqs",
    title: "Help Center",
    component: <Faqs />,
    isPublic: false,
  },
  {
    path: candidatePath + "/settings",
    title: "Account Settings",
    component: <Settings />,
    isPublic: false,
  },
  {
    path: candidatePath + "/jobs/:id",
    title: "View Jobs",
    component: <JobDescription />,
    isPublic: false,
  },
  {
    path: candidatePath + "/application-status/:id",
    title: "Application History | Bultaminds",
    component: <JobApplicationStatus />,
    isPublic: false
  }
];

export default candidateRoutes;
