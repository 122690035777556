import React, { useState } from "react";
import "./style.scss";
import { MuiOtpInput } from "mui-one-time-password-input";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSnackbarMessage, toggleSnackbar } from "../../../../redux/actions";
import ButtonComponent from "../../../../components/Button";
import { request } from "../../../../utils/api";
import otpSVG from "../../../../assets/images/employer/otp.svg";
import { ClipLoader } from "react-spinners";
import {
  setEmployerID,
  setUserInfo,
  setUserName,
} from "../../../../utils/user";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const OtpForm = ({ setSnackbarState, setSnackbarMessage }) => {
  const navigate = useNavigate();
  const [emailOtp, setEmailOtp] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const email = localStorage.getItem("email");
  const mobile = localStorage.getItem("mobile");
  const employerId = localStorage.getItem("employer_id");
  const userId = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);

  const handleSubmitOtp = () => {
    if (emailOtp.length !== 6 || mobileOtp.length !== 6) {
      setSnackbarState(true);
      setSnackbarMessage("Invalid OTP!");
      return;
    }

    const reqBody = {
      employerId: employerId,
      userId: userId,
      email_otp: emailOtp,
      mobile_otp: mobileOtp,
    };

    setLoading(true);

    request
      .post("employer/otp-verification", reqBody)
      .then((res) => {
        console.log(res);

        setLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          setUserInfo({
            token: res.data.data.token,
            name: res.data.data.employers.email_id,
            userType: "Employer",
          });
          setUserName(res.data.data.employers.organization_name);
          setEmployerID(res.data.data.employers.employer_id);
          navigate("/employer/edit-profile");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };

  const handleChangeEmailOTP = (newValue) => {
    setEmailOtp(newValue);
  };

  const handleChangeMobileOTP = (newValue) => {
    setMobileOtp(newValue);
  };

  const getMaskedMobile = () => {
    const firstPart = mobile.substring(0, 2) + "XXX ";
    const secondPart = "XX" + mobile.substring(7, 10);

    return firstPart + secondPart;
  };

  return (
    <div className="otp-form-cont">
      <img className="otp-svg" src={otpSVG} alt="otp-svg" />
      <h4 className="heading">OTP Verification</h4>
      <p className="enter-otp-text">Enter the OTP sent to {email}</p>
      <MuiOtpInput
        value={emailOtp}
        onChange={handleChangeEmailOTP}
        length={6}
        TextFieldsProps={{ type: "number" }}
      />
      <br />
      <p className="enter-otp-text">
        Enter the OTP sent to +91 {getMaskedMobile()}
      </p>
      <MuiOtpInput
        value={mobileOtp}
        onChange={handleChangeMobileOTP}
        length={6}
        TextFieldsProps={{ type: "number" }}
      />
      <div className="next-btn-cont">
        {loading ? (
          <ClipLoader
            color={"rgba(54, 215, 183, 1)"}
            loading={loading}
            size={35}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <ButtonComponent
            title={"Verify"}
            variant={"contained"}
            onClick={handleSubmitOtp}
          />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpForm);
