import React from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../../../components/Button";
import { useState } from "react";
import { request } from "../../../../utils/api";
import { getToken } from "../../../../utils/user";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../../redux/actions";
import { ClipLoader } from "react-spinners";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const UploadPopup = ({
  closePopup,
  setSnackbarState,
  setSnackbarMessage,
  handleUploadPhoto,
  loading,
}) => {
  const [imageFileSrc, setImageFileSrc] = useState("");
  const [imageFile, setImageFile] = useState("");

  const hiddenFileInput = React.useRef(null);

  const handleTriggerFileUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (e) => {
    // console.log(e.target.files[0]);
    setImageFile(e.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      setImageFileSrc(reader.result);
      localStorage.setItem("ORG_LOGO", reader.result);
    };
  };

  // const handleUploadPhoto = () => {
  //   setLoading(true);
  //   request
  //     .post(
  //       "employer/logo/update",
  //       { logo: imageFile },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${getToken()}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       setLoading(false);

  //       setSnackbarState(true);
  //       setSnackbarMessage(response.data.message);

  //       if (response.data.status) {
  //         localStorage.setItem("ORG_LOGO", imageFileSrc);
  //         closePopup();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //       setSnackbarState(true);
  //       setSnackbarMessage("Something went wrong!");
  //     });
  // };

  const handleClosePopup = () => {
    localStorage.removeItem("ORG_LOGO");
    closePopup();
  };

  return (
    <div className="upload-popup-cont">
      <div className="upload-popup-body">
        {imageFileSrc ? (
          <div className="org-logo-img-cont">
            <img className="org-logo-img" src={imageFileSrc} alt="org-logo" />
          </div>
        ) : (
          <FontAwesomeIcon fontSize={45} color="grey" icon={faCameraRetro} />
        )}
        <ButtonComponent
          title={imageFileSrc ? "Change photo" : "Upload photo"}
          variant={"contained"}
          style={{ width: "fit-content" }}
          onClick={handleTriggerFileUpload}
        />
        <input
          type="file"
          accept="image/*"
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <p className="popup-title-keys">
          Supported file types:{" "}
          <span className="popup-title-values">PNG and JPG/JPEG</span>
        </p>
        <p className="popup-title-keys">
          Maximum file size: <span className="popup-title-values">20MB</span>
        </p>
        <p className="popup-title-keys">
          Recommended image size:{" "}
          <span className="popup-title-values">110x110 or bigger</span>
        </p>
      </div>
      <br />
      <br />
      <div className="upload-popup-footer">
        <ButtonComponent
          title={"Cancel"}
          variant={"outlined"}
          style={{ width: "fit-content" }}
          onClick={handleClosePopup}
        />
        {loading ? (
          <ClipLoader
            color={"rgba(54, 215, 183, 1)"}
            loading={loading}
            size={35}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <ButtonComponent
            title={"Save"}
            variant={"contained"}
            style={{ width: "fit-content" }}
            disabled={imageFileSrc ? false : true}
            onClick={() => handleUploadPhoto(imageFile)}
          />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPopup);
