import React, { useEffect } from "react";
import "./SkillsForm.css";
import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { request } from "../../../utils/api";
import { getToken } from "../../../utils/user";
import ButtonComponent from "../../Button";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { connect } from "react-redux";
import SkillCard from "../../../pages/Candidate/Profile/Skills";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

// const suggestedSkills = [
//   "Java",
//   "C++",
//   "Python",
//   "JavaScript",
//   "PHP",
//   "Ruby",
//   "Swift",
//   "Objective-C",
//   "C#",
//   "SQL",
//   "HTML",
//   "CSS",
//   "TypeScript",
//   "Kotlin",
//   "R",
//   "Go",
//   "Scala",
//   "Perl",
//   "Shell",
//   "Assembly",
// ];

const SkillsForm = (props) => {
  const [skillsCopy, setSkillsCopy] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [currentSkills, setCurrentSkills] = useState([]);
  const [skillTyped, setSkillTyped] = useState("");

  useEffect(() => {
    if (props.skills) {
      setCurrentSkills(props.skills);
      // let newSkills = props.skills.map((skill) => {
      //   return skill.title;
      // });
      // setSelectedSkills(newSkills);
    }
    request
      .get("master/skills", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);

        let newSkillsArray = [];
        response.data.data.map((skill) => {
          newSkillsArray.push(skill.title);
        });

        setSkills(newSkillsArray);
        setSkillsCopy(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const handleClick = (skill) => {
  //   //add skills to skills array

  //   let newSkills = [...selectedSkills];
  //   newSkills.push(skill);
  //   setSelectedSkills(newSkills);
  // };

  const handleAddSkills = () => {
    //save skills in the db

    let newSkills = [];

    selectedSkills.map((skill) => {
      let foundInCurrent = currentSkills.find(
        (eachSkill) => eachSkill.name === skill
      );

      if (foundInCurrent) {
        console.log("found");
        return;
      }
      let foundSkill = skillsCopy.find(
        (eachSkill) => eachSkill.title === skill
      );

      if (foundSkill) {
        newSkills.push(foundSkill);
      } else {
        newSkills.push({
          id: "",
          name: skill,
        });
      }
    });

    newSkills = [...newSkills, ...currentSkills];

    let foundInSkillTyped = currentSkills.find(
      (eachSkill) => eachSkill.name === skillTyped
    );

    if (skillTyped.length > 0 && !foundInSkillTyped) {
      newSkills.push({ id: "", name: skillTyped });
    }

    console.log(newSkills);

    request
      .post(
        "candidate/skills/store",
        { skills: newSkills },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        console.log(response);
        props.setSnackbarState(true);
        props.setSnackbarMessage(response.data.message);

        if (response.data.status) {
          props.onCloseModal();
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          props.setSnackbarState(true);
          props.setSnackbarMessage(error.response.data.message);
        }
      });
  };

  const deleteCurrentSkill = (id) => {
    //delete current skill

    let newSkills = currentSkills.filter((skill) => skill.id !== id);

    setCurrentSkills(newSkills);
  };

  return (
    <div className="skills-form-cont">
      <Autocomplete
        multiple
        freeSolo
        value={selectedSkills}
        onChange={(e, value) => setSelectedSkills(value)}
        disablePortal
        id="combo-box-demo"
        options={skills}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            onChange={(e) => setSkillTyped(e.target.value)}
            {...params}
          />
        )}
      />
      <br />
      {/* {currentSkills.length === 0 ? (
        <p className="current-skills-heading">
          You have not added any skills yet. Please add it above.
        </p>
      ) : null} */}
      {currentSkills.length > 0 && (
        <>
          <p className="current-skills-heading">Your current skills</p>
          <div className="current-skills-cont">
            {currentSkills.map((skill) => {
              return (
                <SkillCard
                  key={skill.id}
                  name={skill.name}
                  handleDelete={() => deleteCurrentSkill(skill.id)}
                />
              );
            })}
          </div>
        </>
      )}
      {/* <div className="suggested-skills-cont">
        <p className="suggested-skills-heading">
          Suggested based on your profile
        </p>
        <div className="suggested-skills">
          {suggestedSkills.map((skill, index) => {
            return (
              <Chip
                key={index}
                label={skill}
                onClick={() => handleClick(skill)}
              />
            );
          })}
        </div>
      </div> */}
      <ButtonComponent
        title={"Save"}
        variant={"contained"}
        onClick={handleAddSkills}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsForm);
