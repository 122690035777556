/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
//import AppNavbar from "../../../components/Navbar";
import CandidateNavBar from '../../../components/Candidate/Navbar'
import { rootPath } from "../../../utils/path";
import { isLoggedIn } from "../../../utils/user";
import "../Candidate.css";
import BasicInfo from "./BasicInfo";
import MyJob from "./MyJob";
import RecommendedJob from "./RecommendedJob";

export default function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate(rootPath + "/");
    }
  }, []);

  return (
    <>
      <CandidateNavBar/>
      <div
        className="section margin-top-65"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <div className="container">
          <BasicInfo />
          <MyJob />
          <RecommendedJob />
        </div>
      </div>
      <Footer />
    </>
  );
}
