import React from "react";
import "./style.scss";
import AppNavbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { useState } from "react";
import ButtonComponent from "../../../components/Button";
import NormalInput from "../../../components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SelectComponent from "../../../components/Select";
import { useNavigate } from "react-router-dom";
// import jobsList from "../../../data/jobsList.json";
import JobCard from "../../../components/JobCard";
import Drawer from "../../../components/Drawer";
import { Pagination } from "@mui/material";
import { useEffect } from "react";
import { request } from "../../../utils/api";
import { getEmployerID, getToken } from "../../../utils/user";
import { SyncLoader } from "react-spinners";
import NewNavbar from "../../../components/NewNavbar";
import {
  resetCandidateRequirements,
  resetJobDetails,
  resetInterviewInformation,
  setDrawerOpen,
} from "../../../redux/actions";
import { connect } from "react-redux";

const searchList = [
  {
    id: 1,
    title: "Job Code",
  },
  {
    id: 2,
    title: "Job Code",
  },
  {
    id: 3,
    title: "Job Code",
  },
  {
    id: 4,
    title: "Job Code",
  },
  {
    id: 5,
    title: "Job Code",
  },
  {
    id: 6,
    title: "Job Code",
  },
  {
    id: 7,
    title: "Job Code",
  },
  {
    id: 8,
    title: "Job Code",
  },
  {
    id: 9,
    title: "Job Code",
  },
];
const mapStateToProps = (state) => ({
  nav: state.drawerReducer.nav,
});
const mapDispatchToProps = (dispatch) => ({
  onSetDrawerOpen: (data) => dispatch(setDrawerOpen(data)),
  onResetCandidateRequirements: () => dispatch(resetCandidateRequirements()),
  onResetJobDetails: () => dispatch(resetJobDetails()),
  onResetInterviewInformation: () => dispatch(resetInterviewInformation()),
});

const EmployerJobs = ({
  onResetCandidateRequirements,
  onResetJobDetails,
  onResetInterviewInformation,
  nav,
}) => {
  const [search, setSearch] = useState("");
  const [activeSearchList, setActiveSearchList] = useState(searchList[0]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobsList, setJobsList] = useState([]);
  const [completeJobListForSearch, setCompleteJobListForSearch] = useState([]);
  const [jobsListPerPage, setJobsListPerPage] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const handleJobsPerPage = (data, pageNum) => {
    let jobs = data.filter((job, index) => {
      if (index >= 8 * (pageNum - 1) && index < 9 * pageNum) {
        return job;
      }
    });

    //console.log(jobs);

    setJobsListPerPage(jobs);
  };

  const getFilteredData = (searchFieldData, searchText) => {
    setSearchText(searchText);
    if (searchText === "" || searchText === null) {
      setJobsList(completeJobListForSearch);
      handleJobsPerPage(completeJobListForSearch, currPage);
    } else {
      setJobsList(searchFieldData);
      handleJobsPerPage(searchFieldData, currPage);
    }
  };
  useEffect(() => {
    console.log("handleJobsPerPage: ", jobsListPerPage);
  }, [jobsListPerPage]);

  const fetchDetails = () => {
    request
      .post(
        `employer/details`,
        {
          employerId: getEmployerID(),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("Employer details", res.data);
        localStorage.setItem("ORG_LOGO", res.data.data.branding.logo);
        localStorage.setItem(
          "CONTACT_NUMBER",
          res.data.data.basic_info.mobile_no
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDetails();
    setLoading(true);

    const reqBody = {
      employerId: getEmployerID(),
    };

    request
      .post("jobs/employer/list", reqBody, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log("job list by employer: ", res.data.data);
        setLoading(false);

        setJobsList(res.data.data);
        setCompleteJobListForSearch(res.data.data);
        handleJobsPerPage(res.data.data, currPage);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // useEffect(() => {}, [currPage]);

  const handlePagination = (event, value) => {
    //console.log(value);

    setCurrPage(value);
    handleJobsPerPage(jobsList, value);
  };

  //search box design
  const renderSearchBox = () => {
    return (
      <div className="search-title-cont">
        <div className="search-job-input">
          <NormalInput
            id={"search"}
            name={"search"}
            title={""}
            placeholder={"Enter job title or keyword"}
            type={"text"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              margin: 0,
              backgroundColor: "white",
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </NormalInput>
        </div>
        <div className="vertical-divider"></div>
        <div className="search-select-cont">
          <SelectComponent
            placeholder="Select your experience"
            style={{ width: "100%", margin: 0 }}
            title={""}
            list={searchList}
            value={activeSearchList}
            onChange={(value) => setActiveSearchList(value)}
          />
        </div>
        <div className="vertical-divider"></div>
        <div className="search-job-input">
          <NormalInput
            id={"search"}
            name={"search"}
            title={""}
            placeholder={"Enter location"}
            type={"text"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              margin: 0,
              backgroundColor: "white",
            }}
          ></NormalInput>
        </div>
        <div className="btn-cont">
          <button className="btn find-job-btn text-white">Find Jobs</button>
          {/* <ButtonComponent
            title={"Find jobs"}
            variant={"contained"}
            style={{
              width: "fit-content",
              fontSize: 14,
              padding: "5px 15px",
            }}
          /> */}
        </div>
        <div className="select-btn-mobile-cont">
          <SelectComponent
            style={{ width: "70%", margin: 0, backgroundColor: "white" }}
            title={""}
            list={searchList}
            value={activeSearchList}
            onChange={(value) => setActiveSearchList(value)}
          />
          <ButtonComponent
            title={"Find jobs"}
            variant={"contained"}
            style={{
              width: "fit-content",
              fontSize: 14,
              padding: "5px 15px",
            }}
          />
        </div>
      </div>
    );
  };

  //render pagination
  const renderPagination = () => {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          onChange={handlePagination}
          className="pagination"
          count={Math.ceil(jobsList.length / 10)}
          color="primary"
        />
      </div>
    );
  };

  //render job cards
  const renderJobs = () => {
    return (
      <div className="row">
        {loading ? (
          <div className="loader-cont">
            <SyncLoader
              color={"rgba(54, 215, 183, 1)"}
              loading={loading}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            {jobsListPerPage.map((job, index) => {
              return <JobCard job={job} key={index} />;
            })}
          </>
        )}
      </div>
    );
  };

  //render header
  const renderHeader = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-between pb-2">
            <h1 className="all-jobs-heading float-start">
              You have {jobsList.length} jobs are active{" "}
              {searchText !== "" ? `For "${searchText}"` : null}
            </h1>
            <div>
              <button
                type="button"
                className="post-new-job-btn"
                onClick={async () => {
                  await onResetInterviewInformation();
                  await onResetCandidateRequirements();
                  await onResetJobDetails();
                  navigate("/employer/jobs/post-job");
                }}
              >
                Post new job
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <AppNavbar
        isSearch={true}
        searchData={completeJobListForSearch}
        getChildData={{ getFilteredData }}
      />
      <div className="main d-flex">
        <Drawer />
        <div
          className="content mobile-content"
          style={{ marginLeft: nav.width }}
        >
          <div className="content-inner">
            {renderHeader()}
            {renderJobs()}
            {renderPagination()}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerJobs);
