import React, { useEffect } from "react";
import "./EmploymentForm.css";
import NormalInput from "../../Input";
import MultilineInput from "../../MultilineInput";
import DatePicker from "../../DatePicker";
import CheckboxComponent from "../../Checkbox";
import ButtonComponent from "../../Button";
import SelectComponent from "../../Select";
import FileInputComponent from "../../File Input";
import { useState } from "react";
import { request } from "../../../utils/api";
import { getToken } from "../../../utils/user";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { employmentSchema } from "../../../schemas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGlobe,
  faLocationDot,
  faSackDollar,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { EngineeringOutlined } from "@mui/icons-material";
import { numberToAmount } from "../../../utils/numberToAmount";
import dayjs from "dayjs";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const employmentTypes = [
  { id: 1, title: "Full Time", code: "F" },
  { id: 2, title: "Part Time", code: "P" },
  { id: 3, title: "Internship", code: "I" },
  { id: 4, title: "Freelance", code: "F" },
];

const EmploymentForm = (props) => {
  const [empType, setEmpType] = useState(employmentTypes[0]);
  const [noticePeriod, setNoticePeriod] = useState(null);
  const [noticePeriodList, setNoticePeriodList] = useState([]);
  const [currencyCode, setCurrencyCode] = useState(null);
  const [currencyCodeList, setCurrencyCodeList] = useState([]);
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [media, setMedia] = useState("");

  let newCurrencyCodeList;
  let newNoticePeriodList;

  const getDateFormat = (dt) => {
    const date = new Date(dt);

    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const handleAddEmployment = (values) => {
    //api call to store employment data in db
    const {
      empTitle,
      location,
      company,
      website,
      amount,
      industry,
      description,
      startDate,
      endDate,
    } = values;

    if (!currentlyWorking && !endDate) {
      props.setSnackbarState(true);
      props.setSnackbarMessage("End date is required");
      return;
    }

    if (currentlyWorking && !noticePeriod) {
      props.setSnackbarState(true);
      props.setSnackbarMessage("Notice period is required");
      return;
    }

    request
      .post(
        "candidate/employment/store",
        {
          designation: empTitle,
          location: location,
          companyName: company,
          employmentType: empType.code,
          isCurrentEmployment: Number(currentlyWorking).toString(),
          joiningDate: getDateFormat(startDate),
          workedTill: getDateFormat(endDate),
          noticePeriod: currentlyWorking ? noticePeriod.id : "",
          salaryCurrency: currencyCode.id,
          salary: amount,
          jobProfile: description,
          employementId: props.id ? props.id : null,
        },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        console.log(response);
        props.setSnackbarState(true);
        props.setSnackbarMessage(response.data.message);
        if (response.data.status) {
          props.onCloseModal();
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          props.setSnackbarState(true);
          props.setSnackbarMessage(error.response.data.message);
        }
      });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, setValues } =
    useFormik({
      initialValues: {
        empTitle: "",
        company: "",
        website: "",
        location: "",
        amount: "",
        industry: "",
        description: "",
        startDate: "",
        endDate: "",
      },
      validationSchema: employmentSchema,
      onSubmit: (values) => {
        handleAddEmployment(values);
      },
    });

  console.log(errors, values);

  const fetchNoticePeriodList = async () => {
    //fetch notice period list
    return request
      .get("master/noticePeriod", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        console.log(response);

        setNoticePeriodList(response.data.data);
        setNoticePeriod(response.data.data[0]);

        newNoticePeriodList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCurrencyList = async () => {
    //fetch currency list
    return request
      .get("master/currencies", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        console.log(response);

        setCurrencyCodeList(response.data.data);
        setCurrencyCode(response.data.data[0]);
        newCurrencyCodeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchNoticePeriodList().then(() => {
      fetchCurrencyList().then(() => {
        if (props.id) {
          request
            .get(`candidate/employment/${props.id}`, {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            })
            .then((response) => {
              console.log(response.data.data[0]);

              const {
                location,
                joining_date,
                job_profile,
                is_current_employment,
                employment_type,
                designation,
                company_name,
                worked_till,
                salary_currency,
                salary,
                notice_period,
              } = response.data.data[0];

              setCurrentlyWorking(Boolean(Number(is_current_employment)));

              const newEmploymentType = employmentTypes.find(
                (employmentType) => employmentType.code === employment_type
              );

              setEmpType(newEmploymentType);
              setValues({
                ...values,
                startDate: new Date(joining_date),
                endDate: Boolean(Number(is_current_employment))
                  ? ""
                  : new Date(worked_till),
                amount: salary,
                location: location,
                description: job_profile,
                company: company_name,
                empTitle: designation,
              });

              const newCurrencyCode = newCurrencyCodeList.find(
                (currencyCode) => currencyCode.id === salary_currency
              );

              setCurrencyCode(newCurrencyCode);

              const newNoticePeriod = newNoticePeriodList.find(
                (noticePeriod) => noticePeriod.id === notice_period
              );

              setNoticePeriod(newNoticePeriod);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    });

    const apiCall = async () => {};

    apiCall();

    //fetch employment by id if id is coming through props
  }, []);

  const handleStartDate = (date) => {
    if (!date) {
      setValues({
        ...values,
        startDate: "",
      });
      return;
    }
    setValues({
      ...values,
      startDate: new Date(`${date.$y}-${date.$M + 1}-${date.$D}`),
    });
  };

  const handleEndDate = (date) => {
    if (!date) {
      setValues({
        ...values,
        endDate: "",
      });
      return;
    }
    setValues({
      ...values,
      endDate: new Date(`${date.$y}-${date.$M + 1}-${date.$D}`),
    });
  };

  // console.log(noticePeriod);

  return (
    <div className="employment-form-cont">
      <NormalInput
        id="empTitle"
        name="empTitle"
        title={"Title"}
        placeholder={"Enter employment title"}
        type={"text"}
        value={values.empTitle}
        onChange={handleChange}
        error={errors.empTitle ? true : false}
        errorMsg={errors.empTitle}
        onBlur={handleBlur}>
        {/* <TitleIcon fontSize="medium" /> */}
        <FontAwesomeIcon icon={faUser} />
      </NormalInput>
      <div className="company-website-cont">
        <NormalInput
          id="company"
          name="company"
          title={"Company Name"}
          placeholder={"e.g. ABC Pvt Ltd"}
          type={"text"}
          value={values.company}
          onChange={handleChange}
          error={errors.company ? true : false}
          errorMsg={errors.company}
          onBlur={handleBlur}>
          <FontAwesomeIcon icon={faBuilding} />
        </NormalInput>
        <NormalInput
          id="website"
          name="website"
          title={"Website"}
          placeholder={"www.example.com"}
          type={"text"}
          value={values.website}
          onChange={handleChange}
          error={errors.website ? true : false}
          errorMsg={errors.website}
          onBlur={handleBlur}>
          <FontAwesomeIcon icon={faGlobe} />
        </NormalInput>
      </div>
      <div className="location-employment-cont">
        <NormalInput
          id="location"
          name="location"
          title={"Location"}
          placeholder={"e.g. Mumbai"}
          type={"text"}
          value={values.location}
          onChange={handleChange}
          error={errors.location ? true : false}
          errorMsg={errors.location}
          onBlur={handleBlur}>
          <FontAwesomeIcon icon={faLocationDot} />
        </NormalInput>
        <SelectComponent
          title={"Employment Type"}
          list={employmentTypes}
          value={empType}
          onChange={(type) => setEmpType(type)}
        />
      </div>
      <MultilineInput
        id="description"
        name="description"
        title={"Description"}
        placeholder={
          "e.g. I have joined ABC company as a Frontend Developer and helped them in redesigning their landing page..."
        }
        value={values.description}
        onChange={handleChange}
        error={errors.description ? true : false}
        errorMsg={errors.description}
        onBlur={handleBlur}
      />
      <div className="start-end-current-work-cont">
        <DatePicker
          id={"startDate"}
          name={"startDate"}
          title={"Start Date"}
          value={dayjs(values.startDate)}
          onChange={(date) => {
            handleStartDate(date);
          }}
          error={errors.startDate ? true : false}
          errorMsg={errors.startDate}
        />
        {currentlyWorking ? null : (
          <DatePicker
            id={"endDate"}
            name={"endDate"}
            title={"End Date"}
            value={dayjs(values.endDate)}
            onChange={(date) => {
              handleEndDate(date);
            }}
            error={errors.endDate ? true : false}
            errorMsg={errors.endDate}
          />
        )}
      </div>
      {values.endDate ? null : (
        <CheckboxComponent
          text={"I currently work here"}
          value={currentlyWorking}
          onChange={(bool) => {
            setCurrentlyWorking(bool);
            setValues({ ...values, endDate: "" });
          }}
        />
      )}
      <div className="notice-period-salary-cont">
        {values.endDate ? null : (
          <SelectComponent
            title={"Notice Period"}
            list={noticePeriodList}
            value={noticePeriod}
            onChange={(type) => setNoticePeriod(type)}
          />
        )}
        <div className="salary-heading-cont">
          <p className="salary-heading">
            Salary{" "}
            {/* <span className="salary-in-words">
              {currencyCode.code === "$"
                ? values.amount + " $"
                : values.amount / 100000 + " Lakhs"}{" "}
              per annum
            </span> */}
            {values.amount ? (
              <span className="salary-in-words">
                {numberToAmount(values.amount, currencyCode.code)} per annum
              </span>
            ) : null}
          </p>
          <div className="salary-cont">
            <SelectComponent
              title={""}
              list={currencyCodeList}
              value={currencyCode}
              onChange={(type) => setCurrencyCode(type)}
              showCode={true}
            />
            <NormalInput
              id="amount"
              name="amount"
              title={""}
              placeholder={"e.g. 500000"}
              type={"text"}
              value={values.amount}
              onChange={handleChange}
              error={errors.amount ? true : false}
              errorMsg={errors.amount}
              onBlur={handleBlur}>
              <FontAwesomeIcon icon={faSackDollar} />
            </NormalInput>
          </div>
        </div>
      </div>
      <NormalInput
        id="industry"
        name="industry"
        title={"Industry"}
        placeholder={"e.g. Software Developer"}
        type={"text"}
        value={values.industry}
        onChange={handleChange}
        error={errors.industry ? true : false}
        errorMsg={errors.industry}
        onBlur={handleBlur}>
        <EngineeringOutlined fontSize="medium" />
      </NormalInput>
      <FileInputComponent
        title={"Add Media (optional)"}
        placeholder={"Insert a file"}
      />
      <div className="close-save-btn-cont">
        <ButtonComponent
          title={"Cancel"}
          variant={"outlined"}
          onClick={props.onCloseModal}
        />
        <ButtonComponent
          title={"Save"}
          variant={"contained"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentForm);
