import React from "react";
import ".././Candidate.css";
import AppNavbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import NormalInput from "../../../components/Input";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ButtonComponent from "../../../components/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl, candidatePath } from "../../../utils/path";
import axios from "axios";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { pureFinalPropsSelectorFactory } from "react-redux/es/connect/selectorFactory";
import { Snackbar } from "@mui/material";
import { request } from "../../../utils/api";
import { setUserId } from "../../../utils/user";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../../../schemas";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const ForgotPassword = (props) => {
  const navigate = useNavigate();

  const handleSubmitEmail = (values) => {
    const { email } = values;
    //code to api call

    request
      .post("candidate/forgotPassword", {
        email: email,
      })
      .then((response) => {
        console.log(response);
        props.setSnackbarState(true);
        props.setSnackbarMessage(response.data.message);

        setUserId(response.data.data.userId);

        navigate(candidatePath + "/auth/otp-verification");
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          props.setSnackbarState(true);
          props.setSnackbarMessage(error.response.data.message);
        }
      });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, setValues } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: forgotPasswordSchema,
      onSubmit: (values) => {
        // console.log(values);
        handleSubmitEmail(values);
      },
    });

  return (
    <>
      <AppNavbar />
      <div className="section">
        <form className="forgot-password-form">
          <p className="forgot-password-heading">Forgot Password ?</p>
          <p className="forgot-password-text">
            Don't worry! It happens. Please enter the address associated with
            your account.
          </p>
          <NormalInput
            id="email"
            name="email"
            title={"Email"}
            placeholder={"abc@domain.xy"}
            type={"text"}
            value={values.email}
            onChange={handleChange}
            error={errors.email ? true : false}
            errorMsg={errors.email}
            onBlur={handleBlur}>
            <AlternateEmailIcon fontSize="medium" />
          </NormalInput>
          <br />
          <ButtonComponent
            title={"Submit"}
            variant={"contained"}
            onClick={handleSubmit}
          />
        </form>
      </div>
      <Footer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
