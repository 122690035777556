import React from "react";
import "./Branding.scss";

const Branding = ({ data }) => {
  return (
    <div className="branding-cont">
      <p className="branding-title">Tell candidates about your company</p>
      <p className="branding-vision-title">
        Things that make up our culture are{" "}
        <span className="branding-vison-value">{data.vision}</span>
      </p>
      <p className="branding-values-title">
        Our values are{" "}
        <span className="branding-vison-value">{data.company_values}</span>
      </p>
      <p className="branding-mission-title">
        Our mision is{" "}
        <span className="branding-vison-value">{data.mission}</span>
      </p>
      <p className="branding-goal-title">
        A goal we are working towards is{" "}
        <span className="branding-vison-value">{data.goals}</span>
      </p>
    </div>
  );
};

export default Branding;
