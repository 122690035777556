//snackbar actions

export const TOGGLESNACKBAR = "TOGGLESNACKBAR";
export const SETSNACKBARMESSAGE = "SETSNACKBARMESSAGE";

//employer actions

export const BASICDETAILS = "BASICDETAILS";
export const ACCOUNTINFORMATION = "ACCOUNTINFORMATION";
export const CONTACTINFORMATION = "CONTACTINFORMATION";

export const JOBDETAILS = "JOBDETAILS";
export const CANDIDATEREQUIREMENTS = "CANDIDATEREQUIREMENTS";
export const INTERVIEWINFORMATION = "INTERVIEWINFORMATION";

//drawer actions

export const OPENDRAWER = "OPENDRAWER";
export const CLOSEDRAWER = "CLOSEDRAWER";
