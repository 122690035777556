import React from "react";
import "./style.scss";

const NotFoundIcon = ({ style, text }) => {
  return (
    <div className="not-found-cont">
      <svg
        className="not-found-icon"
        viewBox="0 0 382 337"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M368.25 70.55V70.42C362.87 51.85 346.63 39.79 330.6 30.59C298.25 12.02 268.25 23.83 221.6 35.59C174.95 47.35 146.6 35.27 104.26 22.24C41 2.79001 -6.64998 62.79 2.00002 122.37C6.09002 150.44 18.75 176.3 23.88 204.14C31.39 244.95 1.81001 263.8 25.27 298.22C48.73 332.64 112 340.5 154.21 333.85C179.78 329.85 201.92 315.54 226.09 307.2C248.76 299.38 273.4 301.08 296.09 307.89C317.14 314.2 342.09 320.41 359.93 303.37C380.22 284 387 261.08 377.05 234.78C368.43 212.01 352.25 192.65 344.9 169.37C338.7 149.78 350.5 138.86 359.62 123.01C368.44 107.68 373.23 87.91 368.25 70.55Z"
          fill="#00D5EC"
        />
        <path
          opacity="0.7"
          d="M368.25 70.55V70.42C362.87 51.85 346.63 39.79 330.6 30.59C298.25 12.02 268.25 23.83 221.6 35.59C174.95 47.35 146.6 35.27 104.26 22.24C41 2.79001 -6.64998 62.79 2.00002 122.37C6.09002 150.44 18.75 176.3 23.88 204.14C31.39 244.95 1.81001 263.8 25.27 298.22C48.73 332.64 112 340.5 154.21 333.85C179.78 329.85 201.92 315.54 226.09 307.2C248.76 299.38 273.4 301.08 296.09 307.89C317.14 314.2 342.09 320.41 359.93 303.37C380.22 284 387 261.08 377.05 234.78C368.43 212.01 352.25 192.65 344.9 169.37C338.7 149.78 350.5 138.86 359.62 123.01C368.44 107.68 373.23 87.91 368.25 70.55Z"
          fill="white"
        />
        <path
          d="M205.74 204.7H177.74V167.19H197.4L205.74 174.83V204.7Z"
          fill="black"
        />
        <path
          d="M207.38 203.43H179.38V165.93H199.04L207.38 173.57V203.43Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M199.04 165.93L196.99 174.9L207.38 173.57L199.04 165.93Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M199.04 165.93V173.57H207.38L199.04 165.93Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 179.08H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 182.99H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 186.9H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 190.82H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 194.73H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M340.41 204.7H312.41V167.19H332.07L340.41 174.83V204.7Z"
          fill="black"
        />
        <path
          d="M342.04 203.43H314.04V165.93H333.7L342.04 173.57V203.43Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M333.7 165.93L331.66 174.9L342.04 173.57L333.7 165.93Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M333.7 165.93V173.57H342.04L333.7 165.93Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 179.08H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 182.99H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 186.9H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 190.82H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 194.73H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M68.73 204.7H40.73V167.19H60.39L68.73 174.83V204.7Z"
          fill="black"
        />
        <path
          d="M70.37 203.43H42.37V165.93H62.03L70.37 173.57V203.43Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.03 165.93L59.98 174.9L70.37 173.57L62.03 165.93Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.03 165.93V173.57H70.37L62.03 165.93Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 179.08H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 182.99H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 186.9H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 190.82H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 194.73H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M205.74 298.42H177.74V260.91H197.4L205.74 268.55V298.42Z"
          fill="black"
        />
        <path
          d="M207.38 297.15H179.38V259.65H199.04L207.38 267.29V297.15Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M199.04 259.65L196.99 268.62L207.38 267.29L199.04 259.65Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M199.04 259.65V267.29H207.38L199.04 259.65Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 272.8H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 276.71H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 280.63H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 284.54H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 288.45H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M340.41 298.42H312.41V260.91H332.07L340.41 268.55V298.42Z"
          fill="black"
        />
        <path
          d="M342.04 297.15H314.04V259.65H333.7L342.04 267.29V297.15Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M333.7 259.65L331.66 268.62L342.04 267.29L333.7 259.65Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M333.7 259.65V267.29H342.04L333.7 259.65Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 272.8H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 276.71H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 280.63H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 284.54H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 288.45H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M68.73 298.42H40.73V260.91H60.39L68.73 268.55V298.42Z"
          fill="black"
        />
        <path
          d="M70.37 297.15H42.37V259.65H62.03L70.37 267.29V297.15Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.03 259.65L59.98 268.62L70.37 267.29L62.03 259.65Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.03 259.65V267.29H70.37L62.03 259.65Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 272.8H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 276.71H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 280.63H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 284.54H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 288.45H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M136.06 249.75H108.06V212.25H127.73L136.06 219.89V249.75Z"
          fill="black"
        />
        <path
          d="M137.7 248.49H109.7V210.98H129.36L137.7 218.62V248.49Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M129.36 210.98L127.32 219.95L137.7 218.62L129.36 210.98Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M129.36 210.98V218.62H137.7L129.36 210.98Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.06 224.14H131.02"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.06 228.05H131.02"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.06 231.96H131.02"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.06 235.87H131.02"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.06 239.78H131.02"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M205.74 110.98H177.74V73.47H197.4L205.74 81.11V110.98Z"
          fill="black"
        />
        <path
          d="M207.38 109.71H179.38V72.21H199.04L207.38 79.85V109.71Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M199.04 72.21L196.99 81.18L207.38 79.85L199.04 72.21Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M199.04 72.21V79.85H207.38L199.04 72.21Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 85.36H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 89.27H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 93.18H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 97.09H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.73 101.01H200.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M273.07 62.31H245.07V24.81H264.74L273.07 32.44V62.31Z"
          fill="black"
        />
        <path
          d="M274.71 61.05H246.71V23.54H266.37L274.71 31.18V61.05Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M266.37 23.54L264.33 32.51L274.71 31.18L266.37 23.54Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M266.37 23.54V31.18H274.71L266.37 23.54Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M252.07 36.69H268.03"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M252.07 40.61H268.03"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M252.07 44.52H268.03"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M252.07 48.43H268.03"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M252.07 52.34H268.03"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M340.41 110.98H312.41V73.47H332.07L340.41 81.11V110.98Z"
          fill="black"
        />
        <path
          d="M342.04 109.71H314.04V72.21H333.7L342.04 79.85V109.71Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M333.7 72.21L331.66 81.18L342.04 79.85L333.7 72.21Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M333.7 72.21V79.85H342.04L333.7 72.21Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 85.36H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 89.27H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 93.18H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 97.09H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M319.4 101.01H335.36"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M68.73 110.98H40.73V73.47H60.39L68.73 81.11V110.98Z"
          fill="black"
        />
        <path
          d="M70.37 109.71H42.37V72.21H62.03L70.37 79.85V109.71Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.03 72.21L59.98 81.18L70.37 79.85L62.03 72.21Z"
          fill="black"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.03 72.21V79.85H70.37L62.03 72.21Z"
          fill="white"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 85.36H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 89.27H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 93.18H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 97.09H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.73 101.01H63.69"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M259.64 297.15C287.111 297.15 309.38 292.87 309.38 287.59C309.38 282.31 287.111 278.03 259.64 278.03C232.169 278.03 209.9 282.31 209.9 287.59C209.9 292.87 232.169 297.15 259.64 297.15Z"
          fill="#00D5EC"
        />
        <path
          opacity="0.5"
          d="M259.64 297.15C287.111 297.15 309.38 292.87 309.38 287.59C309.38 282.31 287.111 278.03 259.64 278.03C232.169 278.03 209.9 282.31 209.9 287.59C209.9 292.87 232.169 297.15 259.64 297.15Z"
          fill="white"
        />
        <path
          d="M221.91 156.51C221.847 156.573 221.772 156.624 221.69 156.66C221.486 156.755 221.252 156.766 221.04 156.689C220.828 156.612 220.655 156.454 220.56 156.25L218.56 152.04C218.486 151.838 218.49 151.616 218.572 151.417C218.653 151.218 218.806 151.057 219.001 150.966C219.195 150.874 219.417 150.859 219.622 150.922C219.828 150.986 220.002 151.124 220.11 151.31L222.11 155.52C222.187 155.685 222.209 155.87 222.173 156.048C222.137 156.226 222.045 156.388 221.91 156.51Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M223.71 166.9C225.855 167.188 227.877 168.068 229.55 169.44C232.35 171.73 238.45 174.78 246.34 176.56C254.23 178.34 256.25 178.56 256.25 178.56L254.25 167.63C254.25 167.63 243.06 167.88 238.25 167.12C233.44 166.36 227.82 162.04 225.25 159.75C222.68 157.46 223.71 166.9 223.71 166.9Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M227.22 167.38C227.123 167.467 227.016 167.541 226.9 167.6C226.592 167.744 226.24 167.761 225.92 167.647C225.599 167.533 225.337 167.297 225.19 166.99L220.19 156.44C220.118 156.288 220.076 156.123 220.068 155.954C220.059 155.786 220.084 155.617 220.141 155.459C220.197 155.3 220.285 155.154 220.398 155.029C220.511 154.904 220.648 154.802 220.8 154.73C220.952 154.658 221.117 154.616 221.286 154.608C221.454 154.599 221.623 154.624 221.781 154.681C221.94 154.737 222.086 154.825 222.211 154.938C222.336 155.051 222.438 155.188 222.51 155.34L227.51 165.89C227.624 166.137 227.657 166.414 227.605 166.682C227.553 166.949 227.418 167.193 227.22 167.38Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M223.56 149.49C221.627 151.288 219.204 152.473 216.597 152.894C213.991 153.315 211.318 152.954 208.917 151.857C206.516 150.759 204.494 148.974 203.107 146.727C201.72 144.48 201.031 141.873 201.126 139.234C201.221 136.596 202.097 134.045 203.641 131.904C205.186 129.763 207.331 128.128 209.805 127.206C212.279 126.284 214.971 126.116 217.54 126.724C220.11 127.331 222.441 128.687 224.24 130.62C226.648 133.215 227.928 136.658 227.801 140.196C227.673 143.733 226.148 147.075 223.56 149.49ZM207.12 131.81C205.556 133.263 204.459 135.147 203.966 137.224C203.473 139.301 203.608 141.478 204.352 143.478C205.097 145.479 206.418 147.214 208.149 148.463C209.88 149.712 211.942 150.42 214.075 150.497C216.209 150.574 218.317 150.017 220.133 148.895C221.95 147.774 223.392 146.139 224.279 144.197C225.166 142.256 225.457 140.095 225.116 137.987C224.774 135.88 223.815 133.922 222.36 132.36C220.41 130.27 217.71 129.039 214.854 128.936C211.997 128.832 209.216 129.866 207.12 131.81Z"
          fill="black"
        />
        <path
          d="M225.46 156.74C225.46 156.74 226.85 156 227.46 157.14C228.07 158.28 229.58 160.65 229.29 161.51C229 162.37 227.38 162.51 226.59 161.62C225.812 160.476 225.181 159.24 224.71 157.94L225.46 156.74Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M220.12 158.44L225.46 156.74C225.652 156.863 225.815 157.027 225.936 157.221C226.057 157.415 226.134 157.633 226.16 157.86C226.27 158.66 228.52 163.11 228.27 164.23C228.02 165.35 223.14 167.4 222.65 166.79C222.16 166.18 219.28 159.23 219.28 159.23C219.28 159.23 219.2 158.7 220.12 158.44Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M226.27 158.66L221.45 160.16"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M226.97 160.74L222.58 162.44"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M227.78 162.67L223.62 165.02"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M247.61 137.66C247.61 137.66 243.28 145.79 242.78 153.93C242.28 162.07 249.64 163.08 250.66 163.84C251.68 164.6 255.49 196.64 255.49 196.64C255.49 196.64 228.79 230.97 228.79 234.78C228.79 238.59 225 276 225 276L230.59 276.26C230.59 276.26 239 252.58 241 247C243 241.42 243.79 232.76 243.79 232.76L268.46 209.62C268.46 209.62 271.25 232.25 273.8 237.08C276.35 241.91 293.88 276.75 293.88 276.75C295.382 277.134 296.914 277.389 298.46 277.51C299.517 277.462 300.554 277.204 301.51 276.75L286.51 233.75C286.51 233.75 287.27 194.59 283.97 188.23C280.67 181.87 272.27 172.72 272.27 172.72C272.27 172.72 265.15 143.23 264.13 138.65C263.11 134.07 257.27 130.26 254.47 130.26C251.67 130.26 247.61 137.66 247.61 137.66Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M243 132.57L246.56 138.16C246.56 138.16 244.56 146.81 246.31 144.78C248.06 142.75 250.88 135.37 252.41 133.84C253.353 132.831 254.039 131.61 254.41 130.28L246.84 126L243 132.57Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M227.21 113.92C227.21 113.92 226.76 118.59 228.28 120.92C229.8 123.25 230.28 123.97 229.81 125.49C229.34 127.01 227.01 127.02 227.52 128.29C228.03 129.56 230.83 130.07 230.83 130.07C230.83 130.07 233.88 137.19 236.67 137.19C239.46 137.19 246.61 130.19 247.62 127.64C248.63 125.09 238.2 118.37 236.42 116.85C234.64 115.33 229.24 111.12 227.21 113.92Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M233.43 122.78C233.71 123.63 233.62 124.43 233.22 124.56C232.82 124.69 232.22 124.11 231.99 123.26C231.76 122.41 231.79 121.61 232.19 121.47C232.59 121.33 233.14 121.92 233.43 122.78Z"
          fill="black"
        />
        <path
          d="M232.65 130.72C232.65 130.72 235.11 130.72 235.93 127.17"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M228.47 102.06H223.21C219.83 102.06 219.46 108.81 226.59 116.32C233.72 123.83 247.98 132.83 256.24 132.83C264.5 132.83 271.63 135.08 274.63 143.34C277.63 151.6 282.51 159.1 297.15 156.1C311.79 153.1 315.54 133.21 305.03 116.32C294.52 99.43 280.63 102.43 273.5 105.81C266.37 109.19 254.36 110.31 252.11 105.81C249.86 101.31 244.54 98.28 238.91 98.28C233.28 98.28 228.47 102.06 228.47 102.06Z"
          fill="black"
        />
        <path
          d="M301.51 276.74L286.51 233.74C286.51 233.74 287.27 194.58 283.97 188.22C281.13 182.77 274.57 175.28 272.76 173.22L253.25 179C254.41 187.6 255.49 196.65 255.49 196.65C255.49 196.65 228.79 230.98 228.79 234.79C228.79 238.6 225 276 225 276L230.59 276.26C230.59 276.26 239 252.58 241 247C243 241.42 243.79 232.76 243.79 232.76L268.46 209.62C268.46 209.62 271.25 232.25 273.8 237.08C276.35 241.91 293.88 276.75 293.88 276.75C295.382 277.134 296.914 277.389 298.46 277.51C299.518 277.459 300.555 277.197 301.51 276.74Z"
          fill="black"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M258.29 137.91C257.145 137.633 255.937 137.792 254.902 138.356C253.867 138.919 253.079 139.848 252.69 140.96C251.17 145.03 257.52 148.59 262.35 153.96C267.18 159.33 281.17 170.23 281.17 170.23L289.31 196.17L293.12 195.4C293.12 195.4 288 165.88 288 165.12C288 164.36 266.65 143.25 265.88 142.49C265.11 141.73 262.07 138.42 262.07 138.42"
          fill="#00D5EC"
        />
        <path
          d="M258.29 137.91C257.145 137.633 255.937 137.792 254.902 138.356C253.867 138.919 253.079 139.848 252.69 140.96C251.17 145.03 257.52 148.59 262.35 153.96C267.18 159.33 281.17 170.23 281.17 170.23L289.31 196.17L293.12 195.4C293.12 195.4 288 165.88 288 165.12C288 164.36 266.65 143.25 265.88 142.49C265.11 141.73 262.07 138.42 262.07 138.42"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M289.31 196.14L287.78 202.75L290.32 207.58C290.32 207.58 292.32 208.34 292.87 207.32L293.37 206.32C293.37 206.32 295.92 206.06 296.17 203.77C296.42 201.48 293.12 195.38 293.12 195.38L289.31 196.14Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M225 276C225 276 212.54 285.16 212 286.68C211.46 288.2 228.78 284.14 230.31 283.88C231.84 283.62 230.56 276.26 230.56 276.26L225 276Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M213.28 285.21C212.776 285.612 212.346 286.1 212.01 286.65C211.5 288.18 228.79 284.11 230.32 283.85C230.84 283.77 231.03 282.85 231.06 281.66L213.28 285.21Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M293.88 276.74C293.88 276.74 283.46 278.52 284.73 280.04C286.653 281.882 289.188 282.95 291.85 283.04C294.39 283.04 295.41 283.8 297.7 285.04C299.99 286.28 301.51 286.31 302.27 284.27C303.03 282.23 301.51 276.65 301.51 276.65C301.51 276.65 295.41 278 293.88 276.74Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M297.7 285.13C299.98 286.4 301.51 286.4 302.27 284.36C302.354 284.128 302.408 283.886 302.43 283.64C298.64 285.55 295.15 281.01 291.97 281.01C289.97 281.01 287.18 279.9 285.33 279.01C284.73 279.35 284.45 279.69 284.73 280.01C286.653 281.852 289.188 282.92 291.85 283.01C294.39 283.09 295.41 283.85 297.7 285.13Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M235.59 114.44C237.59 118.44 240.48 123.21 243.82 125.16"
          stroke="white"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M232.74 107.75C232.74 107.75 233.19 109.06 234.01 110.99"
          stroke="white"
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M305.31 122.68C306.2 123.395 306.988 124.23 307.65 125.16"
          stroke="white"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M226.23 106.52C226.23 106.52 229.75 118.52 241.53 124.1C253.31 129.68 258.06 128.5 273 122C284.44 117 295.68 117.3 302.61 120.93"
          stroke="white"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M284.43 146.25C284.24 142.38 288.2 142.94 288.66 143.09"
          stroke="white"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M264.57 125.51C264.57 125.51 271.43 124.28 284.79 124.45C298.15 124.62 306.42 138.35 303.79 146.61C301.37 154.19 288.89 154.23 285.49 149.61"
          stroke="white"
          stroke-width="0.75"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M121.99 180.41C149.461 180.41 171.73 176.13 171.73 170.85C171.73 165.57 149.461 161.29 121.99 161.29C94.5194 161.29 72.25 165.57 72.25 170.85C72.25 176.13 94.5194 180.41 121.99 180.41Z"
          fill="#00D5EC"
        />
        <path
          opacity="0.5"
          d="M121.99 180.41C149.461 180.41 171.73 176.13 171.73 170.85C171.73 165.57 149.461 161.29 121.99 161.29C94.5194 161.29 72.25 165.57 72.25 170.85C72.25 176.13 94.5194 180.41 121.99 180.41Z"
          fill="white"
        />
        <path
          d="M146.67 17.77C146.67 17.77 146.67 12.77 146.67 9.59C146.67 6.41 151.2 0.869999 158.05 0.589999C164.9 0.309999 176.05 9.27 174.27 14.07C172.49 18.87 169.93 16.07 169.93 16.07C169.93 16.07 171.76 18.13 169.25 19.27C166.74 20.41 165 15.72 165 15.72C165 15.72 161.57 12.52 158.37 12.52C155.17 12.52 156.09 12.75 153.8 15.94C151.51 19.13 149.46 18.23 149.46 19.37C149.46 20.51 151.06 22.37 148.55 22.37C146.04 22.37 146.67 17.77 146.67 17.77Z"
          fill="black"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M48.69 129.45C48.69 129.45 41.22 127.32 40.15 127.96C39.08 128.6 37.8 145.05 38.23 146.54C38.66 148.03 45.23 135.43 45.23 135.43L48.43 135.01L48.69 129.45Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M41.27 127.85C40.8934 127.791 40.5079 127.829 40.15 127.96C39.08 128.6 37.8 145.05 38.23 146.54C38.33 146.89 38.77 146.46 39.39 145.6L41.27 127.85Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M110.43 75.2C108.444 78.0337 106.726 81.0468 105.3 84.2C103.38 88.68 101.24 93.38 101.24 93.38L88.64 121.15C88.64 121.15 65.14 126.71 62.15 127.56C59.16 128.41 48.69 129.48 48.69 129.48L48.48 135.04C48.48 135.04 77.74 135.89 84.58 135.89C91.42 135.89 92.7 137.17 95.26 135.25C97.82 133.33 116.83 101 116.83 101L124.31 100.58C124.31 100.58 145.46 124.93 147.17 127.49C148.88 130.05 154.64 147.14 157.63 152.7C159.432 156.046 161.581 159.193 164.04 162.09L168.74 156.75C168.74 156.75 158.74 124.5 157.42 117.66C156.1 110.82 139.05 88.83 139.05 88.83L140.76 77.51C140.76 77.51 113.2 78.4 110.43 75.2Z"
          fill="black"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M147.57 32.33C147.57 32.33 132.08 31.44 129.85 31.59C127.62 31.74 125.53 32.03 125.24 33.37C124.95 34.71 109.46 74.75 109.46 77.43C109.46 80.11 118.09 83.69 126.13 83.69C131.303 83.6549 136.322 81.9268 140.42 78.77C141.16 78.18 161.26 40.52 161.71 39.62C162.16 38.72 162 37.39 160.52 36.05C159.04 34.71 147.57 32.33 147.57 32.33Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M148.46 30C148.46 30 144.46 30.89 143.4 32.23C142.34 33.57 139.23 45.63 139.23 45.63L147.23 43.99L151.84 50.99C151.84 50.99 154.97 40.87 155.12 36.55C155.27 32.23 150.69 29.5 148.46 30Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M125.38 32.26C120.974 33.6024 116.744 35.4655 112.78 37.81C107.43 41.23 91.84 50.63 91.84 50.63L101.84 53.19L120.21 45.93C120.21 45.93 124.1 37.81 125.38 32.26Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M121.11 52.77C121.11 52.77 111.28 51.7 106.79 50.42C102.3 49.14 98.04 46.57 93.55 49.35C89.06 52.13 89.49 55.35 92.27 57.47C95.05 59.59 111.07 57.68 114.27 57.47C116.164 57.3493 117.998 56.7619 119.61 55.76L121.11 52.77Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M118.76 53C118.76 53 128.58 49.37 129.22 49.37C129.86 49.37 131.15 49.37 131.36 50.86C131.57 52.35 132.43 55.99 131.57 56.2C130.71 56.41 126.23 58.13 125.16 58.13C124.431 58.1055 123.711 57.9602 123.03 57.7C122.208 57.995 121.323 58.0718 120.463 57.9228C119.602 57.7738 118.795 57.4042 118.12 56.85C115.77 54.9 117.47 53.62 118.76 53Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M123.03 57.68L122.17 55.12"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M125.81 52.13L127.3 57.68"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M128.37 50.42L129.86 56.18"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M123.67 53.19L125.16 58.11"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M161 36.69C162.999 38.4971 164.606 40.6959 165.72 43.15C168.01 47.73 174.72 62.41 174.72 62.41C174.72 62.41 176.87 67.41 172.43 69.01C167.99 70.61 167.43 66.32 165.56 63.89C163.69 61.46 156.81 48.94 156.81 48.94L161 36.69Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M165.88 61.33C166.634 60.6861 167.515 60.2096 168.467 59.9321C169.419 59.6545 170.418 59.5822 171.4 59.72C174.9 60.12 176.12 65.51 174.77 68.34C173.42 71.17 169.77 72.24 168.57 71.7C167.37 71.16 163.46 69 165.88 61.33Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M167.55 60.63C167.467 60.6444 167.383 60.6444 167.3 60.63C167.193 60.597 167.093 60.543 167.007 60.4712C166.92 60.3994 166.849 60.3112 166.797 60.2117C166.745 60.1123 166.713 60.0034 166.703 59.8916C166.693 59.7797 166.706 59.667 166.74 59.56L168.14 55.11C168.173 55.0028 168.227 54.9033 168.299 54.8173C168.371 54.7313 168.459 54.6606 168.559 54.6094C168.659 54.5581 168.768 54.5273 168.88 54.5189C168.991 54.5104 169.104 54.5244 169.21 54.56C169.317 54.593 169.417 54.647 169.503 54.7188C169.59 54.7906 169.661 54.8788 169.713 54.9783C169.765 55.0777 169.797 55.1866 169.807 55.2984C169.817 55.4103 169.804 55.523 169.77 55.63L168.37 60.08C168.305 60.244 168.192 60.3844 168.046 60.4826C167.899 60.5808 167.726 60.6322 167.55 60.63Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M164 72.21C163.871 72.2104 163.743 72.1901 163.62 72.15C163.296 72.0465 163.026 71.8193 162.869 71.5178C162.711 71.2163 162.68 70.8649 162.78 70.54L166.29 59.39C166.4 59.0767 166.628 58.8185 166.925 58.6701C167.223 58.5216 167.566 58.4945 167.883 58.5945C168.199 58.6945 168.465 58.9137 168.623 59.2058C168.781 59.4979 168.819 59.8401 168.73 60.16L165.22 71.31C165.14 71.5712 164.979 71.7999 164.759 71.962C164.539 72.1241 164.273 72.2111 164 72.21Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M173.55 56.61C170.91 56.61 168.329 55.827 166.133 54.3601C163.938 52.8932 162.227 50.8082 161.216 48.3688C160.206 45.9294 159.941 43.2452 160.457 40.6555C160.972 38.0659 162.243 35.6872 164.11 33.8201C165.977 31.9531 168.356 30.6816 170.946 30.1665C173.535 29.6514 176.219 29.9158 178.659 30.9262C181.098 31.9366 183.183 33.6478 184.65 35.8431C186.117 38.0385 186.9 40.6196 186.9 43.26C186.895 46.799 185.487 50.1916 182.984 52.694C180.482 55.1965 177.089 56.6047 173.55 56.61ZM173.55 32.47C171.416 32.468 169.328 33.0992 167.553 34.2836C165.777 35.468 164.392 37.1525 163.574 39.1239C162.756 41.0954 162.541 43.2652 162.956 45.3589C163.371 47.4526 164.398 49.3761 165.907 50.8861C167.415 52.3962 169.338 53.4248 171.431 53.8419C173.525 54.2591 175.695 54.046 177.667 53.2296C179.639 52.4132 181.325 51.0303 182.511 49.2557C183.697 47.481 184.33 45.3945 184.33 43.26C184.327 40.4009 183.191 37.6594 181.17 35.6368C179.15 33.6141 176.409 32.4753 173.55 32.47Z"
          fill="black"
        />
        <path
          d="M164.27 60.26C164.27 60.26 162.78 59.72 162.38 60.93C161.98 62.14 160.76 64.7 161.17 65.51C161.58 66.32 163.17 66.18 163.86 65.24C164.469 63.9971 164.922 62.6839 165.21 61.33L164.27 60.26Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M169.79 61.2L164.27 60.26C164.096 60.4092 163.958 60.5952 163.864 60.8044C163.771 61.0136 163.725 61.2409 163.73 61.47C163.73 62.28 162.11 66.99 162.51 68.07C162.91 69.15 168.04 70.49 168.44 69.82C168.84 69.15 170.73 61.87 170.73 61.87C170.73 61.87 170.73 61.33 169.79 61.2Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M163.73 62.28L168.71 63.08"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M163.32 64.43L167.9 65.51"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M162.78 66.45L167.23 68.2"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M148 26.82C148 26.82 146.07 34.56 146.07 37.99C146.07 41.42 146.07 44.24 147.26 43.99C148.45 43.74 152.47 35.99 153.21 32.99C153.95 29.99 151.29 24.14 148 26.82Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M147.58 18.23C147.58 18.23 145.98 15.49 145.07 17.31C144.16 19.13 143.24 23.48 144.61 24.85C145.98 26.22 146.44 24.17 146.44 24.17C146.44 24.17 146.67 30.34 148.95 32.62C151.23 34.9 153.75 36.05 157.41 34.45C161.07 32.85 165.63 26.22 167 21.66C167.726 19.4227 168.262 17.1278 168.6 14.8C168.6 14.8 167.69 11.15 162.43 10C157.17 8.85001 151.7 10.92 151.7 13.2C151.7 15.48 152.84 15.72 151.47 16.4C150.765 16.7908 149.984 17.0261 149.18 17.09C149.18 17.09 148.27 21.88 147.58 18.23Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M162.11 23C161.88 23.8 161.42 24.36 161.11 24.26C160.8 24.16 160.68 23.44 160.91 22.65C161.14 21.86 161.6 21.29 161.91 21.39C162.22 21.49 162.34 22.2 162.11 23Z"
          fill="black"
        />
        <path
          d="M153.82 19.97L157.09 21.96"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M161.21 19.12C161.21 19.12 163.48 16.99 164.21 19.55"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M158.08 18.84C158.08 18.84 157.65 15.71 154.95 16.56"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M160.36 21.39C159.913 21.7285 159.543 22.1572 159.272 22.6482C159.002 23.1391 158.837 23.6815 158.79 24.24C158.79 25.94 160.21 27.51 158.93 27.79C157.65 28.07 155.66 26.66 155.66 26.66"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M156.8 30.21C156.8 30.21 153.1 30.78 152.68 28.08"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M164 162.13C164 162.13 166.14 168.13 167.63 168.13C169.12 168.13 181.09 156.38 181.52 155.13C181.95 153.88 170.41 158.13 170.41 158.13L168.7 156.84L164 162.13Z"
          fill="#00D5EC"
          stroke="#263238"
          stroke-miterlimit="10"
        />
        <path
          d="M179.16 155.21L166.66 167.41C167.01 167.83 167.35 168.12 167.66 168.12C169.16 168.12 181.12 156.37 181.55 155.12C181.69 154.68 180.65 154.83 179.16 155.21Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
        />
      </svg>
      <p className="not-found-text">{text}</p>
    </div>
  );
};

export default NotFoundIcon;
