import React from "react";
import "./Button.css";
import { Button } from "@mui/material";

const ButtonComponent = ({ title, variant, onClick, disabled, style }) => {
  return (
    <Button
      sx={{ width: "100%", ...style }}
      onClick={onClick}
      className="button"
      variant={variant}
      disabled={disabled}>
      {title}
    </Button>
  );
};

export default ButtonComponent;
