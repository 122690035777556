/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo-dark.png";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { candidatePath, employerPath, rootPath } from "../../../utils/path";
//import { setDrawerClose, setDrawerOpen } from "../../redux/actions";
import { connect } from "react-redux";
import {
  getUserInfo,
  getUserName,
  isLoggedIn,
  removeUserInfo,
  removeUserName,
} from "../../../utils/user";
import {
  MdOutlineHandshake,
  MdOutlineHelpCenter,
  MdOutlineLogout,
  MdOutlineSettings,
} from "react-icons/md";

const CONSTANTS = {
  USER_TYPE: {
    EMPPLOYER: "Employer",
    CANDIDATE: "Candidate",
  },
};

const mapStateToProps = (state) => ({
  //nav: state.drawerReducer.nav,
});

const mapDispatchToProps = (dispatch) => ({
//   onSetDrawerOpen: (data) => dispatch(setDrawerOpen(data)),
//   onSetDrawerClose: (data) => dispatch(setDrawerClose(data)),
});

const CandidateNavBar = (props) => {
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState("");
  // const [searchFilteredData, setSearchFilteredData] = useState([]);
  const navigate = useNavigate();

  const generateShortName = () => {
    if (userName.length > 0) {
      const userNameArray = userName.split(" ");
      const firstCharacter = userNameArray[0].charAt(0);
      let lastCharacter = "";
      if (userNameArray.length > 1) {
        lastCharacter = userNameArray[userNameArray.length - 1].charAt(0);
      }
      return firstCharacter + lastCharacter;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (isLoggedIn()) {
      const userInfo = getUserInfo();
      if (userInfo) {
        setUserType(userInfo.userType);
        setUserName(getUserName());
      }
    }
  }, []);

  const logout = () => {
    removeUserInfo();
    removeUserName();
    navigate(rootPath + "/");
    localStorage.clear();
  };
  //console.log(getUserName());

  const candidateRightMenu = () => {
    return (
      <>
        <li className="nav-item">
          <Link to={"#"} className="nav-link active" aria-current="page">
            <span className="fa-sharp fa-regular fa-bell"></span>
          </Link>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {userName}
          </Link>
          <ul className="dropdown-menu">
            <li>
              <Link className="dropdown-item" to={candidatePath + "/myprofile"}>
                <span className="badge badge-primary">
                  View & Update Profile
                </span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={candidatePath + "/settings"}>
                <MdOutlineSettings />
                <span className="pl-2 pt-1">Settings</span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={candidatePath + "/faqs"}>
                <MdOutlineHelpCenter />
                <span className="pl-2 pt-1">FAQs</span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={candidatePath + "/faqs"}>
                <MdOutlineHandshake />
                <span className="pl-2 pt-1">Candidate Agreement</span>
              </Link>
            </li>
            <li>
              <a
                className="dropdown-item"
                to="javascript:void(0)"
                onClick={logout}
              >
                <MdOutlineLogout />
                <span className="pl-2 pt-1">Logout</span>
              </a>
            </li>
          </ul>
        </li>
      </>
    );
  };

  const renderRightMenu = () => {
    return (
      <>
        {isLoggedIn() ? (
          <>
            <ul className="navbar-nav mb-2 mb-lg-0">
              {candidateRightMenu()}
            </ul>
          </>
        ) : (
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to={candidatePath + "/auth/login"}>
                <button className="app-btn login-btn me-2">
                  Candidate Login
                </button>
              </Link>
            </li>
            <li className="nav-item">
              <Link to={rootPath + "/employer/auth/login"}>
                <button className="app-btn bg-blue text-white">
                  Employer Login
                </button>
              </Link>
            </li>
          </ul>
        )}
      </>
    );
  };

  return (
    <nav
      className="navbar navbar-expand-lg bg-body-tertiary fixed-top"
      id="top-navbar"
    >
      <div className="container">
        <a
          className="col-1-md navbar-brand"
          href={rootPath + "/"}
          // style={{ marginLeft: 47 }}
        >
          <img alt="Bultaminds Jobs" src={logo} style={{ height: 30 }} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#app-navbar"
          aria-controls="app-navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="app-navbar">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                to={candidatePath + "/dashboard"}
                className="nav-link"
                style={{ fontSize: 13 }}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={candidatePath + "/recommended-jobs"}
                className="nav-link"
                style={{ fontSize: 13 }}
              >
                Jobs
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" style={{ fontSize: 13 }}>
                Companies
              </Link>
            </li>
          </ul>
          {renderRightMenu()}
        </div>
      </div>
    </nav>
  );
};

//export default connect(mapStateToProps, mapDispatchToProps)(CandidateNavBar);
export default CandidateNavBar;
