export const getToken = () => {
  if (localStorage.AUTH_TOKEN && localStorage.AUTH_TOKEN !== "") {
    return localStorage.AUTH_TOKEN;
  }
  return null;
};

export const setUserInfo = (userInfo) => {
  localStorage.setItem("AUTH_TOKEN", userInfo.token);
  localStorage.setItem("USER_EMAIL", userInfo.name);
  localStorage.setItem("USER_TYPE", userInfo.userType);
  // localStorage.setItem("USER_PROFILE_IMG", userInfo.image);
};

export const getUserInfo = () => {
  return {
    name: localStorage.USER_EMAIL,
    userType: localStorage.USER_TYPE,
    profileImage: localStorage.USER_PROFILE_IMG,
  };
};

export const removeUserInfo = () => {
  localStorage.removeItem("AUTH_TOKEN");
  localStorage.removeItem("USER_EMAIL");
  localStorage.removeItem("USER_TYPE");
  // localStorage.removeItem("USER_PROFILE_IMG");
};

export const setUserId = (id) => {
  localStorage.setItem("USER_ID", id);
};

export const getUserId = () => {
  return localStorage.getItem("USER_ID");
};

export const removeUserId = (id) => {
  localStorage.removeItem("USER_ID");
};

export const setUserName = (name) => {
  localStorage.setItem("USER_NAME", name);
};

export const getUserName = () => {
  return localStorage.getItem("USER_NAME");
};

export const removeUserName = () => {
  localStorage.removeItem("USER_NAME");
};

export const isLoggedIn = () => {
  const token = getToken();
  return token && token !== null ? true : false;
};

export const isCandidate = () => {
  return localStorage.getItem("USER_TYPE") === "Candidate" ? true : false;
};

export const isEmployer = () => {
  return localStorage.getItem("USER_TYPE") === "Employer" ? true : false;
};

export const setEmployerID = (id) => {
  localStorage.setItem("USER_ID", id);
};

export const getEmployerID = () => {
  return localStorage.getItem("USER_ID");
};

export const getUserType = () => {
  return localStorage.getItem("USER_TYPE");
};
