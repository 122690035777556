import React, { useEffect, useState } from "react";
import "./style.scss";
import NormalInput from "../../../../components/Input";
import { useFormik } from "formik";
import SelectComponent from "../../../../components/Select";
import { request } from "../../../../utils/api";
import { getEmployerID, getToken } from "../../../../utils/user";
import ButtonComponent from "../../../../components/Button";
import {
  faBuilding,
  faPen,
  faPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialMediaPopup from "../SocialMediaPopup";
import BlackScreen from "../../../../components/BlackScreen";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../../redux/actions";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import UploadPopup from "../UploadPopup";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const fundingList = [
  {
    id: 1,
    title: "Bootstrapped",
  },
  {
    id: 2,
    title: "Series A",
  },
  {
    id: 3,
    title: "Series B",
  },
  {
    id: 4,
    title: "Series C",
  },
];

const EmployerBranding = ({
  setSnackbarState,
  setSnackbarMessage,
  setPage,
  page,
}) => {
  const [fundingStageList, setFundingStateList] = useState(fundingList);
  const [fundingStage, setFundingStage] = useState(fundingList[0]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [showMediaPopup, setShowMediaPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [imageFileSrc, setImageFileSrc] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const navigate = useNavigate();

  const handleNext = (value) => {
    // console.log(value, currency);

    const {
      aboutCompany,
      vision,
      mission,
      values,
      goal,
      amount,
      linkedin,
      instagram,
      facebook,
      twitter,
    } = value;

    const reqBody = {
      employerId: getEmployerID(),
      profile_info: aboutCompany,
      facebook_link: facebook,
      instagram_link: instagram,
      linkedin_link: linkedin,
      twitter_link: twitter,
      vision: vision,
      mission: mission,
      company_values: values,
      goals: goal,
      stage_of_funding: fundingStage.title,
      currency: currency.id,
      amount: amount,
    };

    setLoading(true);

    request
      .post("employer/branding", reqBody, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        console.log(response);
        setLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(response.data.message);

        if (response.data.status) {
          // setPage(page + 1); //take employer to support page
          navigate("/employer/profile");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState(true);
        setSnackbarMessage("Something went wrong!");
      });
  };

  const { values, setValues, errors, handleSubmit, handleBlur, handleChange } =
    useFormik({
      initialValues: {
        aboutCompany: "",
        vision: "",
        mission: "",
        values: "",
        goal: "",
        amount: "",
        linkedin: "",
        instagram: "",
        facebook: "",
        twitter: "",
      },
      onSubmit: (values) => {
        handleNext(values);
      },
    });

  const fetchDetails = async () => {
    try {
      const res = await request.post(
        `employer/details`,
        {
          employerId: getEmployerID(),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Employer details", res.data);
      setData(res.data.data);

      let branding = res.data.data.branding;

      //set stage of funding

      let funding_stage = fundingStageList.find(
        (list) => list.title === branding.stage_of_funding
      );

      if (funding_stage) {
        setFundingStage(funding_stage);
      }

      setValues({
        ...values,
        aboutCompany: branding.profile_info ? branding.profile_info : "",
        vision: branding.vision ? branding.vision : "",
        mission: branding.mission ? branding.mission : "",
        values: branding.company_values ? branding.company_values : "",
        goal: branding.goals ? branding.goals : "",
        amount: branding.amount ? branding.amount : "",
        linkedin: branding.linkedin_link ? branding.linkedin_link : "",
        instagram: branding.instagram_link ? branding.instagram_link : "",
        facebook: branding.facebook_link ? branding.facebook_link : "",
        twitter: branding.twitter_link ? branding.twitter_link : "",
        recruiterTitle: "",
      });

      return branding;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDetails().then((branding_info) => {
      //get currencies

      request
        .get("master/currencies", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((res) => {
          console.log(res);

          setCurrencyList(res.data.data);
          setCurrency(res.data.data[0]);

          //set currency type

          let curr = res.data.data.find(
            (list) => list.code === branding_info.currency
          );

          if (curr) {
            setCurrency(curr);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    });
  }, []);

  const handleUploadPopup = () => {
    setShowUploadPopup(!showUploadPopup);

    const src = localStorage.getItem("RECRUITER_LOGO");

    if (src) {
      setImageFileSrc(src);
    }
  };

  const handlePopup = () => {
    setShowMediaPopup(!showMediaPopup);
  };

  const handleUploadPhoto = (imageFile) => {
    // upload recruiter image api call
  };

  return (
    <>
      <h2 className="employer-branding-title">
        Let candidates know more about your company
      </h2>
      <div className="employer-branding-form">
        <NormalInput
          id={"aboutCompany"}
          name={"aboutCompany"}
          title={"What does your company do?"}
          placeholder={"Add what your company does"}
          type={"text"}
          value={values.aboutCompany}
          onChange={handleChange}
          error={errors.aboutCompany ? true : false}
          errorMsg={errors.aboutCompany}
          onBlur={handleBlur}
        >
          {/* <FontAwesomeIcon icon={faBuilding} /> */}
        </NormalInput>
        <NormalInput
          id={"vision"}
          name={"vision"}
          title={"What is your company Vision?"}
          placeholder={"Transforming lives through innovative solutions"}
          type={"text"}
          value={values.vision}
          onChange={handleChange}
          error={errors.vision ? true : false}
          errorMsg={errors.vision}
          onBlur={handleBlur}
        >
          {/* <FontAwesomeIcon icon={faBuilding} /> */}
        </NormalInput>
        <NormalInput
          id={"mission"}
          name={"mission"}
          title={"What is your company Mission?"}
          placeholder={"To empower and inspire through innovation"}
          type={"text"}
          value={values.mission}
          onChange={handleChange}
          error={errors.mission ? true : false}
          errorMsg={errors.mission}
          onBlur={handleBlur}
        >
          {/* <FontAwesomeIcon icon={faBuilding} /> */}
        </NormalInput>
        <NormalInput
          id={"values"}
          name={"values"}
          title={"What are your company Values?"}
          placeholder={
            "integrity, collaboration and empathy in everything we do"
          }
          type={"text"}
          value={values.values}
          onChange={handleChange}
          error={errors.values ? true : false}
          errorMsg={errors.values}
          onBlur={handleBlur}
        >
          {/* <FontAwesomeIcon icon={faBuilding} /> */}
        </NormalInput>
        <NormalInput
          id={"goal"}
          name={"goal"}
          title={"What are your company Goals?"}
          placeholder={"Customer Satisfaction and Market Leadership"}
          type={"text"}
          value={values.goal}
          onChange={handleChange}
          error={errors.goal ? true : false}
          errorMsg={errors.goal}
          onBlur={handleBlur}
        >
          {/* <FontAwesomeIcon icon={faBuilding} /> */}
        </NormalInput>
        <SelectComponent
          style={{ width: "100%" }}
          title={"What stage of funding is your company at?"}
          list={fundingStageList}
          value={fundingStage}
          onChange={(value) => setFundingStage(value)}
        />
        <div className="salary-cont">
          <p className="salary-heading">
            What is the amount of funding your company raised in its most recent
            round?
          </p>
          <div className="currency-salary-cont">
            <SelectComponent
              style={{ width: "100%", margin: 0 }}
              title={""}
              list={currencyList}
              value={currency}
              onChange={(value) => setCurrency(value)}
            />
            <NormalInput
              id={"amount"}
              name={"amount"}
              title={""}
              placeholder={""}
              type={"text"}
              value={values.amount}
              onChange={handleChange}
              error={errors.amount ? true : false}
              errorMsg={errors.amount}
              onBlur={handleBlur}
              style={{ margin: 0 }}
            >
              {currency && currency.code}
            </NormalInput>
          </div>
        </div>
        <div className="social-media-cont">
          <p className="social-media-heading">
            Add your companies social media
          </p>
          {values.linkedin ||
          values.instagram ||
          values.facebook ||
          values.twitter ? null : (
            <button onClick={handlePopup} className="open-popup-cont">
              <FontAwesomeIcon icon={faPlus} color="grey" />
              <p className="add-text">Add Social Media</p>
            </button>
          )}
          {(values.linkedin ||
            values.instagram ||
            values.facebook ||
            values.twitter) && (
            <button onClick={handlePopup} className="open-popup-cont2">
              <div className="media-handles">
                {values.linkedin && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36 31C36 33.762 33.762 36 31 36H5C2.239 36 0 33.762 0 31V5C0 2.238 2.239 0 5 0H31C33.762 0 36 2.238 36 5V31Z"
                      fill="#0288D1"
                    />
                    <path
                      d="M6 13H11V30H6V13ZM8.485 11H8.457C6.965 11 6 9.888 6 8.499C6 7.08 6.995 6 8.514 6C10.035 6 10.972 7.08 11 8.499C11 9.887 10.035 11 8.485 11ZM30 30H25V20.901C25 18.703 23.775 17.203 21.808 17.203C20.307 17.203 19.495 18.215 19.101 19.193C18.957 19.543 19 20.511 19 21V30H14V13H19V15.616C19.721 14.5 20.85 13 23.738 13C27.316 13 29.999 15.25 29.999 20.274L30 30Z"
                      fill="white"
                    />
                  </svg>
                )}
                {values.instagram && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.017 36.99L9.01699 37.009C4.61699 37.013 1.01399 33.417 1.00899 29.017L0.989994 9.017C0.985994 4.617 4.58199 1.014 8.98199 1.009L28.982 0.990001C33.382 0.986001 36.985 4.582 36.99 8.982L37.009 28.982C37.014 33.383 33.417 36.986 29.017 36.99Z"
                      fill="url(#paint0_radial_0_1)"
                    />
                    <path
                      d="M29.017 36.99L9.01699 37.009C4.61699 37.013 1.01399 33.417 1.00899 29.017L0.989994 9.017C0.985994 4.617 4.58199 1.014 8.98199 1.009L28.982 0.990001C33.382 0.986001 36.985 4.582 36.99 8.982L37.009 28.982C37.014 33.383 33.417 36.986 29.017 36.99Z"
                      fill="url(#paint1_radial_0_1)"
                    />
                    <path
                      d="M19 26C15.141 26 12 22.86 12 19C12 15.14 15.141 12 19 12C22.859 12 26 15.14 26 19C26 22.86 22.859 26 19 26ZM19 14C16.243 14 14 16.243 14 19C14 21.757 16.243 24 19 24C21.757 24 24 21.757 24 19C24 16.243 21.757 14 19 14Z"
                      fill="white"
                    />
                    <path
                      d="M26.5 13C27.3284 13 28 12.3284 28 11.5C28 10.6716 27.3284 10 26.5 10C25.6716 10 25 10.6716 25 11.5C25 12.3284 25.6716 13 26.5 13Z"
                      fill="white"
                    />
                    <path
                      d="M25 32H13C9.141 32 6 28.86 6 25V13C6 9.14 9.141 6 13 6H25C28.859 6 32 9.14 32 13V25C32 28.86 28.859 32 25 32ZM13 8C10.243 8 8 10.243 8 13V25C8 27.757 10.243 30 13 30H25C27.757 30 30 27.757 30 25V13C30 10.243 27.757 8 25 8H13Z"
                      fill="white"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_0_1"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(14.38 37.035) scale(44.899 44.899)"
                      >
                        <stop stop-color="#FFDD55" />
                        <stop offset="0.328" stop-color="#FF543F" />
                        <stop offset="0.348" stop-color="#FC5245" />
                        <stop offset="0.504" stop-color="#E64771" />
                        <stop offset="0.643" stop-color="#D53E91" />
                        <stop offset="0.761" stop-color="#CC39A4" />
                        <stop offset="0.841" stop-color="#C837AB" />
                      </radialGradient>
                      <radialGradient
                        id="paint1_radial_0_1"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(6.78599 0.540304) scale(29.813 19.8644)"
                      >
                        <stop stop-color="#4168C9" />
                        <stop
                          offset="0.999"
                          stop-color="#4168C9"
                          stop-opacity="0"
                        />
                      </radialGradient>
                    </defs>
                  </svg>
                )}
                {values.facebook && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 0C13.9609 0 9.12816 2.00178 5.56497 5.56497C2.00178 9.12816 0 13.9609 0 19C0 24.0391 2.00178 28.8718 5.56497 32.435C9.12816 35.9982 13.9609 38 19 38C24.0391 38 28.8718 35.9982 32.435 32.435C35.9982 28.8718 38 24.0391 38 19C38 13.9609 35.9982 9.12816 32.435 5.56497C28.8718 2.00178 24.0391 0 19 0Z"
                      fill="#039BE5"
                    />
                    <path
                      d="M21.572 24.036H26.489L27.261 19.041H21.571V16.311C21.571 14.236 22.249 12.396 24.19 12.396H27.309V8.037C26.761 7.963 25.602 7.801 23.412 7.801C18.839 7.801 16.158 10.216 16.158 15.718V19.041H11.457V24.036H16.158V37.765C17.089 37.905 18.032 38 19 38C19.875 38 20.729 37.92 21.572 37.806V24.036Z"
                      fill="white"
                    />
                  </svg>
                )}
                {values.twitter && (
                  <svg
                    width="26"
                    height="18"
                    viewBox="0 0 37 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M37 3.429C35.677 4.015 34.254 4.406 32.753 4.591C34.279 3.685 35.453 2.24 36.004 0.533C34.576 1.37 32.994 1.985 31.311 2.309C29.967 0.884 28.05 0 25.926 0C21.846 0 18.539 3.278 18.539 7.32C18.539 7.892 18.606 8.449 18.732 8.99C12.594 8.682 7.14999 5.764 3.50799 1.336C2.86799 2.418 2.50799 3.685 2.50799 5.022C2.50799 7.563 3.80899 9.8 5.79299 11.118C4.58199 11.081 3.44199 10.744 2.44399 10.204C2.44399 10.226 2.44399 10.259 2.44399 10.29C2.44399 13.841 4.99099 16.798 8.36699 17.471C7.74999 17.64 7.09799 17.734 6.42599 17.734C5.94899 17.734 5.48399 17.68 5.03399 17.599C5.97399 20.501 8.70099 22.622 11.932 22.685C9.40399 24.645 6.21999 25.819 2.75799 25.819C2.15999 25.819 1.57499 25.785 0.996994 25.715C4.26799 27.786 8.15199 29 12.321 29C25.906 29 33.338 17.844 33.338 8.166C33.338 7.849 33.328 7.533 33.313 7.221C34.763 6.197 36.013 4.905 37 3.429Z"
                      fill="#03A9F4"
                    />
                  </svg>
                )}
              </div>
              <FontAwesomeIcon icon={faPen} />
            </button>
          )}
        </div>
      </div>
      <h2 className="employer-branding-title2">
        Tell candidates about yourself
      </h2>
      <div className="recruiter-details-form">
        <p className="recruiter-logo-title">Upload your photo</p>
        <div onClick={handleUploadPopup} className="recruiter-logo-img">
          {imageFileSrc ? (
            <img className="recruiter-img" src={imageFileSrc} alt="org-logo" />
          ) : (
            <FontAwesomeIcon fontSize={40} icon={faUser} color="grey" />
          )}
          <div className="edit-icon-cont">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              style={{ width: 25, color: "black" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
          </div>
        </div>
        <NormalInput
          id={"recruiterTitle"}
          name={"recruiterTitle"}
          title={"What's your title?"}
          placeholder={"Add a suitable title"}
          type={"text"}
          value={values.recruiterTitle}
          onChange={handleChange}
          error={errors.recruiterTitle ? true : false}
          errorMsg={errors.recruiterTitle}
          onBlur={handleBlur}
        >
          {/* <FontAwesomeIcon icon={faBuilding} /> */}
        </NormalInput>
      </div>
      {loading ? (
        <ClipLoader
          color={"rgba(54, 215, 183, 1)"}
          loading={loading}
          size={35}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className="skip-submit-btn-cont">
          <ButtonComponent
            title={"Skip this page"}
            variant={"outlined"}
            style={{ padding: "6px 0" }}
            onClick={() => navigate("/employer/profile")}
          />
          <ButtonComponent
            title={"Submit"}
            variant={"contained"}
            style={{ padding: "6px 0" }}
            onClick={handleSubmit}
          />
        </div>
      )}
      {showMediaPopup ? <BlackScreen closePopup={handlePopup} /> : null}
      {showMediaPopup ? (
        <SocialMediaPopup
          closePopup={handlePopup}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
        />
      ) : null}
      {showUploadPopup ? <BlackScreen closePopup={handleUploadPopup} /> : null}
      {showUploadPopup ? (
        <UploadPopup
          closePopup={handleUploadPopup}
          handleUploadPhoto={handleUploadPhoto}
          loading={uploadLoading}
        />
      ) : null}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerBranding);
