import React from "react";
import "./RecruiterProfile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const RecruiterProfile = () => {
  return (
    <div className="recruiter-profile-cont">
      <div className="recruiter-img-cont">
        <FontAwesomeIcon fontSize={50} color="grey" icon={faUser} />
      </div>
      <div className="recruiter-details-cont">
        <p className="recruiter-name">John Doe</p>
        <p className="recruiter-title">Senior Recruiter</p>
      </div>
    </div>
  );
};

export default RecruiterProfile;
