import React, { useEffect } from "react";
import "./Education.css";
import NormalInput from "../../Input";
import DatePicker from "../../DatePicker";
import CheckboxComponent from "../../Checkbox";
import ButtonComponent from "../../Button";
import SchoolIcon from "@mui/icons-material/School";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SelectComponent from "../../Select";
import { useState } from "react";
import { request } from "../../../utils/api";
import { getToken } from "../../../utils/user";
import YearDatePickerComponent from "../../YearDatePicker";
import PercentIcon from "@mui/icons-material/Percent";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { educationSchema } from "../../../schemas";
import {
  faBuildingColumns,
  faLocationDot,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

// const degreesList = [
//   "Bachelor of Arts (BA)",
//   "Bachelor of Science (BSc)",
//   "Bachelor of Commerce (BCom)",
//   "Bachelor of Engineering (BE)",
//   "Bachelor of Technology (B.Tech)",
//   "Bachelor of Computer Applications (BCA)",
//   "Bachelor of Business Administration (BBA)",
//   "Bachelor of Architecture (B.Arch)",
//   "Bachelor of Education (B.Ed)",
//   "Bachelor of Law (LLB)",
//   "Bachelor of Medicine and Bachelor of Surgery (MBBS)",
//   "Bachelor of Dental Surgery (BDS)",
//   "Bachelor of Pharmacy (B.Pharm)",
//   "Bachelor of Physiotherapy (BPT)",
//   "Bachelor of Science in Nursing (BSc Nursing)",
//   "Bachelor of Hotel Management (BHM)",
//   "Bachelor of Fine Arts (BFA)",
//   "Bachelor of Journalism and Mass Communication (BJMC)",
//   "Bachelor of Design (B.Des)",
//   "Bachelor of Science in Agriculture (BSc Agriculture)",
// ];

// const fieldOfStudyList = [
//   "Arts Humanities",
//   "Science and Mathematics",
//   "Commerce and Business Management",
//   "Engineering and Technology",
//   "Law and Legal Studies",
//   "Medicine and Health Sciences",
//   "Agriculture and Veterinary Science",
//   "Architecture and Planning",
//   "Education and Teaching",
//   "Journalism and Mass Communication",
//   "Fine Arts and Design",
//   "Hospitality and Tourism",
//   "Social Sciences and Humanities",
//   "Environmental Science and Sustainability",
//   "Information Technology and Computer Science",
//   "Sports and Physical Education",
//   "Language and Literature",
//   "Psychology and Counseling",
//   "Library Science and Information Management",
//   "Performing Arts and Theatre",
// ];

const courseTypes = [
  { id: 1, title: "Full Time", code: "F" },
  { id: 2, title: "Part Time", code: "T" },
  { id: 3, title: "Distance Learning", code: "D" },
];

const EducationForm = (props) => {
  // const [currentlyStudy, setCurrentlyStudy] = useState(false);
  const [degrees, setDegrees] = useState([]);
  const [degree, setDegree] = useState(null);
  const [course, setCourse] = useState("");
  const [courses, setCourses] = useState([]);
  const [courseType, setCourseType] = useState(courseTypes[0]);

  const getDateFormat = (dt) => {
    const date = new Date(dt);

    return date.getFullYear();
  };

  const handleAddEducation = (values) => {
    const { instituteName, location, startYear, endYear, marks } = values;

    console.log(
      instituteName,
      location,
      degree,
      course,
      courseType,
      startYear,
      endYear
    );

    //api call to store education data in db

    // const getCourseId = () => {
    //   return courses.find((eachCourse) => eachCourse.course_name === course)
    //     .cid;
    // };

    request
      .post(
        "candidate/education/store",
        {
          institute_name: instituteName,
          location: location,
          degree_id: degree.id,
          course_id: course ? course.id : "",
          course_duration: `${getDateFormat(startYear)}-${getDateFormat(
            endYear
          )}`,
          course_type: courseType.code,
          marks_percentage: marks.toString(),
          education_id: props.id ? props.id : null,
        },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        console.log(response);
        props.setSnackbarState(true);
        props.setSnackbarMessage(response.data.message);

        if (response.data.status) {
          props.onCloseModal();
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          props.setSnackbarState(true);
          props.setSnackbarMessage(error.response.data.message);
        }
      });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, setValues } =
    useFormik({
      initialValues: {
        instituteName: "",
        location: "",
        startYear: "",
        endYear: "",
        marks: "",
      },
      validationSchema: educationSchema,
      onSubmit: (values) => {
        handleAddEducation(values);
      },
    });

  // console.log(values, errors);

  const fetchEducationById = (degree, degrees) => {
    request
      .get(`candidate/education/${props.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(async (response) => {
        console.log(response.data.data[0]);

        const {
          institute_name,
          course_duration,
          marks_percentage,
          location,
          degree_id,
          course_type,
          course_id,
        } = response.data.data[0];

        console.log(degree);

        const newDegree = degrees.find((degree) => degree.id === degree_id);

        const startYear = course_duration.split("-")[0];
        const endYear = course_duration.split("-")[1];

        const newCourseType = courseTypes.find(
          (type) => type.code === course_type
        );

        request
          .get(`master/course?degree=${degree_id}`, {
            headers: { Authorization: `Bearer ${getToken()}` },
          })
          .then((response) => {
            console.log(response);

            let newCourse = response.data.data.find(
              (course) => course.id === course_id
            );

            setCourse(newCourse);
            setCourses(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });

        // console.log(startYear, endYear, newCourseType);

        setDegree(newDegree);
        setValues({
          ...values,
          instituteName: institute_name,
          location: location,
          startYear: new Date(startYear),
          endYear: new Date(endYear),
          marks: marks_percentage,
        });
        setCourseType(newCourseType);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCourse = (degree) => {
    request
      .get(`master/course?degree=${degree}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        console.log(response);

        setCourse(response.data.data[0]);
        setCourses(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const fetchDegreeList = () => {
  //   let degrees;

  //   return degrees;
  // };

  useEffect(() => {
    console.log(props.id);
    //fetch degree list
    request
      .get("master/education/degree", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);

        setDegrees(response.data.data);
        setDegree(response.data.data[0]);

        fetchCourse(response.data.data[0].id);

        //fetch education by id if id is coming through props
        if (props.id) {
          fetchEducationById(response.data.data[0].id, response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="education-form-cont">
      <div className="school-name-location-cont">
        <NormalInput
          id="instituteName"
          name="instituteName"
          title={"Institute Name"}
          placeholder={"e.g. ABC University"}
          type={"text"}
          value={values.instituteName}
          onChange={handleChange}
          error={errors.instituteName ? true : false}
          errorMsg={errors.instituteName}
          onBlur={handleBlur}>
          <FontAwesomeIcon icon={faBuildingColumns} />
        </NormalInput>
        <NormalInput
          id="location"
          name="location"
          title={"Location"}
          placeholder={"e.g. Bhubaneswar"}
          type={"text"}
          value={values.location}
          onChange={handleChange}
          error={errors.location ? true : false}
          errorMsg={errors.location}
          onBlur={handleBlur}>
          <FontAwesomeIcon icon={faLocationDot} />
        </NormalInput>
      </div>
      <div className="start-end-school-cont">
        <YearDatePickerComponent
          id={"startYear"}
          name={"startYear"}
          title={"Start Year"}
          value={dayjs(new Date(values.startYear).getFullYear().toString())}
          onChange={(year) => {
            console.log(year);
            setValues({ ...values, startYear: new Date(`01-01-${year.$y}`) });
          }}
          error={errors.startYear ? true : false}
          errorMsg={errors.startYear}
        />
        <YearDatePickerComponent
          id={"endYear"}
          name={"endYear"}
          title={"End Year"}
          value={dayjs(new Date(values.endYear).getFullYear().toString())}
          onChange={(year) => {
            setValues({ ...values, endYear: new Date(`01-01-${year.$y}`) });
          }}
          error={errors.endYear ? true : false}
          errorMsg={errors.endYear}
        />
      </div>
      {/* <CheckboxComponent
        text={"I currently study here"}
        value={currentlyStudy}
        onChange={(bool) => setCurrentlyStudy(bool)}
      /> */}
      <SelectComponent
        title={"Degree"}
        list={degrees}
        value={degree}
        onChange={(type) => {
          setDegree(type);
          fetchCourse(type.id);
        }}
      />
      <SelectComponent
        title={"Course"}
        list={courses}
        value={course}
        onChange={(course) => setCourse(course)}
      />
      {/* <NormalInput
        title={"Course"}
        placeholder={"e.g. B.Tech"}
        type={"text"}
        value={course}
        disabled={true}></NormalInput> */}
      <SelectComponent
        title={"Course Type"}
        list={courseTypes}
        value={courseType}
        onChange={(type) => {
          setCourseType(type);
        }}
      />
      <NormalInput
        id="marks"
        name="marks"
        title={"Marks (%)"}
        placeholder={"e.g. 85"}
        type={"number"}
        value={values.marks}
        onChange={handleChange}
        error={errors.marks ? true : false}
        errorMsg={errors.marks}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faPercent} />
      </NormalInput>
      <div className="close-save-btn-cont">
        <ButtonComponent
          title={"Cancel"}
          variant={"outlined"}
          onClick={props.onCloseModal}
        />
        <ButtonComponent
          title={"Save"}
          variant={"contained"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EducationForm);
