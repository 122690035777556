import React from "react";
import "./PasswordInput.scss";
import { InputAdornment, TextField } from "@mui/material";

const PasswordInput = ({
  id,
  name,
  title,
  placeholder,
  children,
  type,
  setType,
  value,
  onChange,
  disabled = false,
  error,
  errorMsg,
  onBlur,
  style,
}) => {
  return (
    <div style={style ? style : null} className="password-input-cont">
      {title ? <p className="pasword-input-title">{title}</p> : null}
      <TextField
        id={id}
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        type={type}
        sx={style}
        placeholder={placeholder}
        InputProps={{
          endAdornment:
            type === "text" ? (
              <InputAdornment
                onClick={() => setType("password")}
                className="icon-cont"
                position="end">
                {children}
              </InputAdornment>
            ) : (
              <InputAdornment
                onClick={() => setType("text")}
                className="icon-cont"
                position="end">
                {children}
              </InputAdornment>
            ),
        }}
        error={error}
        helperText={errorMsg}
        onBlur={onBlur}></TextField>
    </div>
  );
};

export default PasswordInput;
