import React, { useState } from "react";
import "./style.scss";
import loginbgimg from "../../../assets/images/employer/loginbgimg.png";
import logo from "../../../assets/images/employer/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { request } from "../../../utils/api";
import {
  setEmployerID,
  setEmployerUserInfo,
  setUserInfo,
  setUserName,
} from "../../../utils/user";
import { loginSchema } from "../../../schemas/employerSchema";
import CheckboxComponent from "../../../components/Checkbox";
import NormalInput from "../../../components/Input";
import ButtonComponent from "../../../components/Button";
import AppNavbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import PasswordInput from "../../../components/Input/PasswordInput";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const Login = ({ setSnackbarMessage, setSnackbarState }) => {
  const navigate = useNavigate();
  const [type, setType] = useState("password");

  const handleLogin = (values) => {
    console.log(values);

    const { email, password } = values;

    const reqBody = {
      username: email,
      password: password,
    };

    request
      .post("employer/login", reqBody)
      .then((res) => {
        console.log(res);

        if (res.data.status) {
          setSnackbarState(true);
          setSnackbarMessage(res.data.message);
          setUserInfo({
            token: res.data.data.token,
            name: email,
            userType: "Employer",
          });
          setUserName(res.data.data.employers.organization_name);
          setEmployerID(res.data.data.employers.employer_id);
          navigate("/employer/jobs");
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };

  const { values, handleBlur, handleSubmit, handleChange, errors, setValues } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        rememberme: false,
      },
      validationSchema: loginSchema,
      onSubmit: (values) => {
        handleLogin(values);
      },
    });

  return (
    <>
      <AppNavbar />
      <div className="employer-auth-container">
        <div className="login-cont">
          <div className="login-card-cont">
            {/* <img className="logo" src={logo} alt="logo" /> */}
            <h1 className="login-heading">Welcome Back!</h1>
            <h3 className="login-sub-heading">Log in to your Account.</h3>
            <NormalInput
              id={"email"}
              name={"email"}
              title={"Email"}
              placeholder={"abc@domain.com"}
              type={"email"}
              value={values.email}
              onChange={handleChange}
              error={errors.email ? true : false}
              errorMsg={errors.email}
              onBlur={handleBlur}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </NormalInput>
            {/* <NormalInput
              id={"password"}
              name={"password"}
              title={"Password"}
              //   placeholder={"abc@domain.com"}
              type={"password"}
              value={values.password}
              onChange={handleChange}
              error={errors.password ? true : false}
              errorMsg={errors.password}
              onBlur={handleBlur}>
              <FontAwesomeIcon icon={faLock} />
            </NormalInput> */}
            <PasswordInput
              id={"password"}
              name={"password"}
              title={"Password"}
              // placeholder={""}
              type={type}
              setType={setType}
              value={values.password}
              onChange={handleChange}
              error={errors.password ? true : false}
              errorMsg={errors.password}
              onBlur={handleBlur}
            >
              {type === "text" ? (
                <FontAwesomeIcon icon={faLockOpen} />
              ) : (
                <FontAwesomeIcon icon={faLock} />
              )}
            </PasswordInput>
            <div className="remember-me-forgot-password">
              <div>
                <CheckboxComponent
                  style={{ width: "fit-content", marginBottom: 0 }}
                  text={"Remember me"}
                  value={values.rememberme}
                  onChange={() => setValues({ rememberme: !values.rememberme })}
                />
              </div>
              <Link
                to={"/employer/auth/forgot-password"}
                className="forgot-password"
              >
                Forgot Password?
              </Link>
            </div>
            <ButtonComponent
              title={"Log in"}
              variant={"contained"}
              onClick={handleSubmit}
              style={{ marginBottom: "25px" }}
            />
            <p className="signup-text">
              Don't have an account?{" "}
              <Link to={"/employer/auth/register"}>Create an account</Link>
            </p>
          </div>
          <div className="login-bg-img-cont">
            <img className="login-bg-img" src={loginbgimg} alt="login-bg-img" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
