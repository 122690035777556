import React, { useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { setSnackbarMessage, toggleSnackbar } from "../../../../redux/actions";
import { passwordSchema } from "../../../../schemas/employerSchema";
import { request } from "../../../../utils/api";
import ButtonComponent from "../../../../components/Button";
import PasswordInput from "../../../../components/Input/PasswordInput";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const PasswordForm = ({
  screen,
  setScreen,
  employerId,
  setEmployerId,
  setSnackbarState,
  setSnackbarMessage,
  setUserId,
}) => {
  const [type, setType] = useState("password");

  const handleNext = (values) => {
    const { password } = values;

    const reqBody = {
      employerId: employerId,
      password: password,
    };

    request
      .post("employer/signup", reqBody)
      .then((res) => {
        console.log(res);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          setScreen(screen + 1);
          setEmployerId(res.data.data.employer_id);
          setUserId(res.data.data.user_id);
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };

  const { values, handleSubmit, handleBlur, handleChange, errors, setValues } =
    useFormik({
      initialValues: {
        password: "",
      },
      validationSchema: passwordSchema,
      onSubmit: (values) => {
        handleNext(values);
      },
    });

  return (
    <div className="password-form-cont">
      <PasswordInput
        id={"password"}
        name={"password"}
        title={"Password"}
        placeholder={"Enter a strong password"}
        type={type}
        setType={setType}
        value={values.password}
        onChange={handleChange}
        error={errors.password ? true : false}
        errorMsg={errors.password}
        onBlur={handleBlur}>
        {type === "text" ? (
          <FontAwesomeIcon icon={faLockOpen} />
        ) : (
          <FontAwesomeIcon icon={faLock} />
        )}
      </PasswordInput>
      <div className="next-btn-cont">
        <ButtonComponent
          title={"Next"}
          variant={"contained"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
