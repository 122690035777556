import axios from "axios";

export const axiosConfig = {
  baseURL: `https://bultaminds.com/bultaminds-job-portal-api/api/`,
  //baseURL: process.env.LOCAL_API_PATH,
  headers: {
    Accept: "application/json",
  },
};

export const request = axios.create(axiosConfig);
