import React from "react";
import "./DatePicker.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";

const DatePickerComponent = ({
  title,
  onChange,
  value,
  id,
  name,
  error,
  errorMsg,
}) => {
  return (
    <div className="date-picker-cont">
      <p className="date-picker-title">{title}</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          id={id}
          name={name}
          sx={{ width: "100%" }}
          className="date-picker"
          format="DD-MM-YYYY"
          orientation="landscape"
          onChange={onChange}
          value={value}
        />
      </LocalizationProvider>
      {error ? <p className="error-msg">{errorMsg}</p> : null}
    </div>
  );
};

export default DatePickerComponent;
