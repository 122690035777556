import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import AppNavbar from "../../../components/Navbar";
import { apiUrl, candidatePath } from "../../../utils/path";
import { setUserInfo } from "../../../utils/user";
import axios from "axios";
import { InputAdornment, TextField } from "@mui/material";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { request } from "../../../utils/api";
import { useFormik } from "formik";
import { loginSchema } from "../../../schemas";
import NormalInput from "../../../components/Input";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

function Login(props) {
  const navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");

  const doLogin = (values) => {
    const { email, password } = values;

    request
      .post(`candidate/login`, {
        email: email,
        password: password,
      })
      .then((response) => {
        console.log(response);
        props.setSnackbarState(true);
        props.setSnackbarMessage(response.data.message);

        setUserInfo({
          token: response.data.data.token,
          name: email,
          userType: "Candidate",
        });

        navigate(candidatePath + "/dashboard");
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          props.setSnackbarState(true);
          props.setSnackbarMessage(error.response.data.message);
        }
      });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, setValues } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: loginSchema,
      onSubmit: (values) => {
        doLogin(values);
      },
    });

  console.log(errors, values);

  useEffect(() => {
    //checkUser();
  }, []);

  const renderLoginFrom = () => {
    return (
      <div className="section" style={{}}>
        <div className="login-form">
          <div className="mb-5">
            <h3 className="page-title-heading">Welcome back!</h3>
          </div>
          <div className="mb-3">
            <NormalInput
              id="email"
              name="email"
              title={"Email"}
              placeholder={"abc@domain.xy"}
              type={"text"}
              value={values.email}
              onChange={handleChange}
              error={errors.email ? true : false}
              errorMsg={errors.email}
              onBlur={handleBlur}>
              <AlternateEmailIcon fontSize="medium" />
            </NormalInput>
          </div>
          <div className="mb-3">
            <div className="input-cont">
              <p className="input-title">Password</p>
              <TextField
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                type={passwordType}
                placeholder={"Enter Password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="icon-cont" position="start">
                      <VpnKeyIcon fontSize="medium" />
                    </InputAdornment>
                  ),
                  endAdornment:
                    passwordType === "text" ? (
                      <InputAdornment
                        onClick={() => setPasswordType("password")}
                        className="icon-cont"
                        position="start">
                        <VisibilityIcon fontSize="medium" />
                      </InputAdornment>
                    ) : (
                      <InputAdornment
                        onClick={() => setPasswordType("text")}
                        className="icon-cont"
                        position="start">
                        <VisibilityOffIcon fontSize="medium" />
                      </InputAdornment>
                    ),
                }}
                error={errors.password ? true : false}
                helperText={errors.password}
                onBlur={handleBlur}></TextField>
            </div>
          </div>
          <div className="mb-3 mt-5 text-center">
            <Link to={candidatePath + "/auth/forgot-password"}>
              <span>Forgot your password</span>
            </Link>
          </div>
          <div className="mb-3">
            <button
              onClick={handleSubmit}
              className="app-btn btn-expanded bg-blue text-white">
              Login
            </button>
          </div>
          <div className="mb-3 text-center">
            <Link to={candidatePath + "/auth/signup"}>
              <span>
                Don't have an account?{" "}
                <span className="text-blue">Register</span>
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <AppNavbar />
      {renderLoginFrom()}
      <Footer />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
