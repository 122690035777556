import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { connect } from "react-redux";
import {
  setAccountInformation,
  setSnackbarMessage,
  toggleSnackbar,
} from "../../../../redux/actions";
import { request } from "../../../../utils/api";
import NormalInput from "../../../../components/Input";
import SelectComponent from "../../../../components/Select";
import ButtonComponent from "../../../../components/Button";
import { accountInformationSchema } from "../../../../schemas/employerSchema";
import {
  faBuilding,
  faGlobe,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = (state) => ({
  accountInformation: state.userDetailsReducer.accountInformation,
});

const mapDispatchToProps = (dispatch) => ({
  onSetAccountInformation: (data) => dispatch(setAccountInformation(data)),
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

// const industryList = [
//   {
//     id: 1,
//     name: "Advertising/PR/Marketing",
//   },
//   {
//     id: 2,
//     name: "Aerospace/Aviation",
//   },
//   {
//     id: 3,
//     name: "Agriculture/Forestry/Fishing/Diary",
//   },
// ];

// const segmentList = [
//   {
//     id: 1,
//     name: "Accounting/Finance/Tax/CS/Audit",
//   },
//   {
//     id: 2,
//     name: "Agent",
//   },
//   {
//     id: 3,
//     name: "AI&ML&IOT",
//   },
//   {
//     id: 4,
//     name: "Airlines",
//   },
// ];

const AccountInformationForm = ({
  setScreen,
  screen,
  accountInformation,
  onSetAccountInformation,
  setSnackbarState,
  setSnackbarMessage,
  employerId,
  setEmployerId,
}) => {
  const [industryList, setIndustryList] = useState([]);
  const [industry, setIndustry] = useState(null);
  const [segmentList, setSegmentList] = useState([]);
  const [segment, setSegment] = useState(null);

  useEffect(() => {
    //fetch industries list

    request
      .get("master/industries")
      .then((res) => {
        console.log(res);
        setIndustryList(res.data.data);
        if (accountInformation.industry) {
          setIndustry(accountInformation.industry);
        } else {
          setIndustry(res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    //fetch segments list

    request
      .get("master/vertical-segments")
      .then((res) => {
        console.log(res);
        setSegmentList(res.data.data);
        if (accountInformation.segment) {
          setSegment(accountInformation.segment);
        } else {
          setSegment(res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleNext = (values) => {
    const { pancard, gstin, website, orgCode, displayName } = values;

    //save account details in db

    console.log(employerId);

    const reqBody = {
      employerId: employerId,
      // displayName: displayName,
      // orgCode: orgCode,
      industryType: industry.id,
      gstNo: gstin,
      pancard: pancard,
      website: website,
      // verticalSegment: segment.id,
    };

    request
      .post("employer/account-info", reqBody)
      .then((res) => {
        console.log(res);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          onSetAccountInformation({
            ...values,
            industry: industry,
            segment: segment,
          });
          setScreen(screen + 1);
          setEmployerId(res.data.data.employer_id);
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };

  const handleBack = () => {
    onSetAccountInformation({
      ...values,
      industry: industry,
      segment: segment,
    });
    setScreen(screen - 1);
  };

  const { values, handleSubmit, handleBlur, handleChange, errors, setValues } =
    useFormik({
      initialValues: {
        // displayName: accountInformation.displayName
        //   ? accountInformation.displayName
        //   : "",
        // orgCode: accountInformation.orgCode ? accountInformation.orgCode : "",
        // businessName: accountInformation.businessName
        //   ? accountInformation.businessName
        //   : "",
        // businessLegalName: accountInformation.businessLegalName
        //   ? accountInformation.businessLegalName
        //   : "",
        website: accountInformation.website ? accountInformation.website : "",
        gstin: accountInformation.gstin ? accountInformation.gstin : "",
        pancard: accountInformation.pancard ? accountInformation.pancard : "",
        // businessAddress: accountInformation.businessAddress
        //   ? accountInformation.businessAddress
        //   : "",
      },
      validationSchema: accountInformationSchema,
      onSubmit: (values) => {
        handleNext(values);
      },
    });

  const getPancardNumber = (value) => {
    return value.substring(2, 12);
  };

  return (
    <div className="account-information-form-cont">
      <h4 className="heading">Account Information</h4>
      {/* <NormalInput
        id={"businessName"}
        name={"businessName"}
        title={"Business Name"}
        placeholder={"ABC Industries Private Ltd"}
        type={"text"}
        value={values.businessName}
        onChange={handleChange}
        error={errors.businessName ? true : false}
        errorMsg={errors.businessName}
        onBlur={handleBlur}></NormalInput>
      <NormalInput
        id={"businessLegalName"}
        name={"businessLegalName"}
        title={"Business Legal Name(As Per GST)"}
        placeholder={"ABC Industries Private Ltd"}
        type={"text"}
        value={values.businessLegalName}
        onChange={handleChange}
        error={errors.businessLegalName ? true : false}
        errorMsg={errors.businessLegalName}
        onBlur={handleBlur}></NormalInput> */}
      {/* <NormalInput
        id={"displayName"}
        name={"displayName"}
        title={"Organisation Name (as per GST)"}
        placeholder={"Enter organisation name"}
        type={"text"}
        value={values.displayName}
        onChange={handleChange}
        error={errors.displayName ? true : false}
        errorMsg={errors.displayName}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faBuilding} />
      </NormalInput> */}
      {/* <NormalInput
        id={"orgCode"}
        name={"orgCode"}
        title={"Organisation Code"}
        placeholder={"Enter organisation code"}
        type={"text"}
        value={values.orgCode}
        onChange={handleChange}
        error={errors.orgCode ? true : false}
        errorMsg={errors.orgCode}
        onBlur={handleBlur}></NormalInput> */}
      <NormalInput
        id={"website"}
        name={"website"}
        title={"Website"}
        placeholder={"http(s)://www.abc.com"}
        type={"text"}
        value={values.website}
        onChange={handleChange}
        error={errors.website ? true : false}
        errorMsg={errors.website}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faGlobe} />
      </NormalInput>
      <NormalInput
        id={"gstin"}
        name={"gstin"}
        title={"Default GSTIN"}
        placeholder={"Enter GSTIN number"}
        type={"text"}
        value={values.gstin}
        onChange={(e) => {
          setValues({
            ...values,
            gstin: e.target.value,
            pancard: getPancardNumber(e.target.value),
          });
        }}
        error={errors.gstin ? true : false}
        errorMsg={errors.gstin}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faIdCard} />
      </NormalInput>
      <NormalInput
        id={"pancard"}
        name={"pancard"}
        title={"Pan Card Number"}
        // placeholder={"http(s)://www.abc.com"}
        type={"text"}
        value={values.pancard}
        onChange={handleChange}
        error={errors.pancard ? true : false}
        errorMsg={errors.pancard}
        onBlur={handleBlur}
        disabled={false}>
        <FontAwesomeIcon icon={faIdCard} />
      </NormalInput>
      {/* <NormalInput
        id={"businessAddress"}
        name={"businessAddress"}
        title={"Business Address"}
        placeholder={"123 Main Street"}
        type={"text"}
        value={values.businessAddress}
        onChange={handleChange}
        error={errors.businessAddress ? true : false}
        errorMsg={errors.businessAddress}
        onBlur={handleBlur}>
      </NormalInput> */}
      <SelectComponent
        style={{ width: "100%" }}
        title={"Industry"}
        list={industryList}
        value={industry}
        onChange={(value) => setIndustry(value)}
      />
      {/* <SelectComponent
        style={{ width: "100%" }}
        title={"Vertical/Segment"}
        list={segmentList}
        value={segment}
        onChange={(value) => setSegment(value)}
      /> */}
      <div className="back-next-btn-cont">
        <ButtonComponent
          onClick={handleBack}
          title={"Back"}
          variant={"outlined"}
        />
        <ButtonComponent
          onClick={handleSubmit}
          title={"Next"}
          variant={"contained"}
        />
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInformationForm);
