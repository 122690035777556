import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import AppNavbar from "../../../components/Navbar";
import Drawer from "../../../components/Drawer";
import NavbarItem from "./NavbarItem";
import JobDetails from "./JobDetails";
// import { Divider } from "@mui/material";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import CandidateRequirements from "./CandidateRequirements";
import InterviewerInformation from "./InterviewerInformation";
import { connect } from "react-redux";
import NewNavbar from "../../../components/NewNavbar";
import { useLocation } from "react-router-dom";
import { setDrawerOpen } from "../../../redux/actions";

const mapStateToProps = (state) => ({
  nav: state.drawerReducer.nav,
  candidateRequirements: state.postJobReducer.candidateRequirements,
  jobDetails: state.postJobReducer.jobDetails,
  interviewInformation: state.postJobReducer.interviewInformation,
});

const mapDispatchToProps = (dispatch) => ({
  onSetDrawerOpen: (data) => dispatch(setDrawerOpen(data))
});

const PostJob = ({
  interviewInformation,
  jobDetails,
  candidateRequirements,
  nav
}) => {
  const location = useLocation();
  const [screen, setScreen] = useState(location.state !== null ? location.state.screen : 1);
  const [jobId, setJobId] = useState(location.state !== null ? location.state.jId : "");
  const isEdit = location.state !== null ? true : false;

  const getScreens = () => {
    switch (screen) {
      case 1:
        return (
          <JobDetails
            screen={screen}
            setScreen={setScreen}
            jobId={jobId}
            setJobId={setJobId}
            isEdit={isEdit}
          />
        );
      case 2:
        return (
          <CandidateRequirements
            screen={screen}
            setScreen={setScreen}
            jobId={jobId}
            setJobId={setJobId}
            isEdit={isEdit}
          />
        );
      case 3:
        return (
          <InterviewerInformation
            screen={screen}
            setScreen={setScreen}
            jobId={jobId}
            setJobId={setJobId}
            isEdit={isEdit}
          />
        );
      default:
        return;
    }
  };

  // const checkPageValidation = (click, index) => {
  //   if (click === undefined) {
  //     if (jobDetails.valid && !candidateRequirements.valid && !interviewInformation.valid) {
  //       return 1;
  //     }
  //     else if (jobDetails.valid && candidateRequirements.valid && !interviewInformation.valid) {
  //       return 2;
  //     }
  //     else if (jobDetails.valid && candidateRequirements.valid && interviewInformation.valid) {
  //       return 3;
  //     }
  //     else {
  //       return 0
  //     }
  //   }
  //   else {
  //     switch (index) {
  //       case 0:
  //         return 0
  //         break;
  //       case 1:

  //         break;
  //       case 2:

  //         break;
  //       default:
  //         break;
  //     }
  //   }


  //   // if (pageNum === 1) {
  //   //   if (jobDetails.valid) {
  //   //     return true;
  //   //   }
  //   //   return false;
  //   // }
  //   // if (pageNum === 2) {
  //   //   if (candidateRequirements.valid) {
  //   //     return true;
  //   //   }
  //   //   return false;
  //   // }
  //   // if (pageNum === 3) {
  //   //   if (interviewInformation.valid) {
  //   //     return true;
  //   //   }
  //   //   return false;
  //   // }
  // };

  // const handleCheckComplete = (pageNum) => {
  //   let valid = checkPageValidation(pageNum);
  //   if (valid) return true;
  //   else return false;
  // };

  return (
    <>
      <AppNavbar />
      <div className="main d-flex">
        <Drawer />
        <div
          className="content mobile-content"
          style={{ marginLeft: nav.width }}
        >
          <div className="content-inner">
            <div className="card">
              <div className="card-body" style={{padding:"10px 20px"}}>
                <Stepper
                  alternativeLabel
                  style={{ width: "100%" }}
                  activeStep={isEdit ? 2 : screen - 1}
                >
                  {[
                    "Job Details",
                    "Candidate Requirements",
                    "Interview Information",
                  ].map((label, index) => (
                    <Step key={label}>
                      <StepButton
                        onClick={() => setScreen(index + 1)}
                        completed={isEdit ? true : screen === 3}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">{getScreens()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostJob);
