import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import AppNavbar from "../../../components/Navbar";
import { Link } from "react-router-dom";
import AppModal from "../../../components/Modal";
import EmploymentForm from "../../../components/Forms/Employment";
import EducationForm from "../../../components/Forms/Education";
import SkillsForm from "../../../components/Forms/Skills";
import { request } from "../../../utils/api";
import { getToken } from "../../../utils/user";
import EducationCard from "./Education";
import EmploymentCard from "./Employment";
import SkillCard from "./Skills";
import FileInputComponent from "../../../components/File Input";
import ButtonComponent from "../../../components/Button";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import CandidateNavBar from "../../../components/Candidate/Navbar";
import BasicInfo from "../Dashboard/BasicInfo";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const PROFILE_LINK = {};

const MyProfile = (props) => {
  const [sidePage, setSidePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [modalName, setModalName] = useState("");
  const [educations, setEducations] = useState([]);
  const [employments, setEmployments] = useState([]);
  const [skills, setSkills] = useState([]);
  const [educationId, setEducationId] = useState("");
  const [employmentId, setEmploymentId] = useState("");
  const [resumeFile, setResumeFile] = useState(null);
  const [resume, setResume] = useState("");

  const handleOpenModal = (name) => {
    setModalName(name);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setEducationId("");
    setEmploymentId("");
    fetchResume();
    fetchSkills();
    fetchEmployments();
    fetchEducations();
  };

  const fetchResume = () => {
    request
      .get("candidate/resumes", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data.data[0][0]);

        if (response.data.data.length > 0) {
          setResume(
            response.data.data[0][0].path + "/" + response.data.data[0][0].file
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchEducations = () => {
    request
      .get("candidate/educations", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        console.log(response);

        setEducations(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchEmployments = () => {
    request
      .get("candidate/employments", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        console.log(response);

        setEmployments(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSkills = () => {
    request
      .get("candidate/skills", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        console.log(response);

        if (response.data.data.length > 0) {
          setSkills(response.data.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    //fetch all educations

    fetchEducations();

    //fetch all employments
    fetchEmployments();

    //fetch all skills
    fetchSkills();

    //fetch resume
    fetchResume();
  }, []);

  const handleUploadFile = () => {
    //check file extension
    console.log(resumeFile.name);

    if (
      resumeFile.name.split(".")[1] !== "pdf" &&
      resumeFile.name.split(".")[1] !== "doc" &&
      resumeFile.name.split(".")[1] !== "docx"
    ) {
      props.setSnackbarState(true);
      props.setSnackbarMessage(
        "Invalid File! Please upload file in valid format"
      );
      setResumeFile(null);
      return;
    }

    //upload resume to db
    request
      .post(
        "candidate/resume/store",
        { resume: resumeFile },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.status == 200) {
          props.setSnackbarState(true);
          props.setSnackbarMessage("Resume successfully uploaded!");

          setResumeFile(null);
          fetchResume();
        }
      })
      .catch((error) => {
        console.log(error);
        props.setSnackbarState(true);
        props.setSnackbarMessage("Something went wrong!");
      });
  };

  const renderResume = () => {
    return (
      <div id="resume" className="bottom-border">
        <h1 className="card-heading">Resume</h1>
        <p className="sub-heading">
          Resume is the most important document recruiters look for. Recruiters
          generally don't look at profiles without resumes.
        </p>
        {/* <div class="mt-5 mb-5 bold-text">
          <span className="resume-text">
            Resume-Gajanan-Mallik-Dec-2022.pdf
          </span>
          <span className="text-grey ml-1">(Uploaded on Apr 1st, 2023)</span>
        </div> */}
        <div className="resume-upload-btn-cont">
          {/* <button className="btn bg-blue-dark text-white">Upload Resume</button> */}
          <div className="resume-upload-cont">
            <FileInputComponent
              title={"Upload Resume"}
              placeholder={"Insert a file"}
              value={resumeFile}
              onChange={(file) => setResumeFile(file)}
            />
          </div>
          <div className="upload-btn-cont">
            <ButtonComponent
              title={"Upload"}
              variant={"contained"}
              onClick={handleUploadFile}
              disabled={resumeFile ? false : true}
            />
          </div>
        </div>
        {resume ? (
          <div className="resume-view-download">
            <a
              className="view-btn"
              href={`http://docs.google.com/gview?url=${resume}&embedded=true`}
              target="_blank">
              View Resume
            </a>
            <div className="icon-cont">
              <a href={resume}>
                <i
                  style={{ color: "white", fontSize: 14 }}
                  class="fas fa-file-download"></i>
              </a>
            </div>
          </div>
        ) : null}
        <p className="mt-2">Upload doc, docx, pdf format only</p>
      </div>
    );
  };

  const renderEmployment = () => {
    return (
      <div id="employment" className="bottom-border pt-4 pb-1">
        <div className="d-flex justify-content-between">
          <h1 className="card-heading mb-4">Employment</h1>
          <div className="nav">
            <button
              onClick={() => handleOpenModal("Add Employment")}
              className="view-more-link">
              <i style={{ color: "white" }} class="fas fa-plus"></i>
            </button>
          </div>
        </div>

        {employments.length > 0 &&
          employments.map((employment) => {
            return (
              <EmploymentCard
                key={employment.eid}
                id={employment.eid}
                designation={employment.designation}
                companyName={employment.company_name}
                empType={employment.employment_type}
                noticePeriod={employment.notice_period}
                joiningDate={employment.joining_date}
                endingDate={employment.worked_till}
                isCurrentEmployment={employment.is_current_employment}
                onClick={(id) => {
                  setEmploymentId(id);
                  handleOpenModal("Add Employment");
                }}
              />
            );
          })}
      </div>
    );
  };

  const renderEducation = () => {
    return (
      <div id="education" className="bottom-border pt-4 pb-1">
        <div className="d-flex justify-content-between">
          <h1 className="card-heading mb-4">Education</h1>
          <div className="nav">
            <button
              onClick={() => handleOpenModal("Add Education")}
              to={"#"}
              className="view-more-link">
              <i style={{ color: "white" }} class="fas fa-plus"></i>
            </button>
          </div>
        </div>

        {/* <EducationCard />
        <EducationCard /> */}

        {educations.length > 0 &&
          educations.map((education) => {
            return (
              <EducationCard
                key={education.eduid}
                id={education.eduid}
                instituteName={education.institute_name}
                location={education.location}
                courseDuration={education.course_duration}
                marksPercent={education.marks_percentage}
                courseType={education.course_type}
                degreeName={education.degree_name}
                courseName={education.course_name}
                onClick={(id) => {
                  setEducationId(id);
                  handleOpenModal("Add Education");
                }}
              />
            );
          })}
      </div>
    );
  };

  const renderSkills = () => {
    return (
      <div id="skills" className="pt-4 pb-1">
        <div className="d-flex justify-content-between">
          <h1 className="card-heading mb-4">Skills</h1>
          <div className="nav">
            <button
              onClick={() => handleOpenModal("Add Skills")}
              to={"#"}
              className="view-more-link">
              <i style={{ color: "white" }} class="fas fa-plus"></i>
            </button>
          </div>
        </div>

        <div className="skills-list-cont">
          {skills.length > 0 &&
            skills.map((skill) => {
              return <SkillCard key={skill.id} name={skill.name} />;
            })}
        </div>
      </div>
    );
  };

  const employmentForm = () => {
    return <EmploymentForm id={employmentId} onCloseModal={handleCloseModal} />;
  };

  const educationForm = () => {
    return <EducationForm id={educationId} onCloseModal={handleCloseModal} />;
  };

  const skillsForm = () => {
    return <SkillsForm skills={skills} onCloseModal={handleCloseModal} />;
  };

  const getModal = () => {
    if (modalName === "Add Employment") {
      return employmentForm();
    } else if (modalName === "Add Education") {
      return educationForm();
    } else {
      return skillsForm();
    }
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);

    const position =
      element.getBoundingClientRect().top + window.pageYOffset - 85;

    window.scrollTo({ top: position, behavior: "smooth" });
  };

  console.log(skills);

  return (
    <>
      <CandidateNavBar />
      <div className="section margin-top-65">
        <div className="container">
          <div className="row form-group">
            <div className="col-md-12">
              <BasicInfo />
            </div>
          </div>
          <div className="app-card p-0">
            <div className="row card-details">
              <div className="profile-side-nav col-md-3 pr-0">
                <ul className="quick-link-list">
                  <li className="card-heading">Quick Links</li>
                  <li onClick={() => handleScroll("resume")}>
                    <p className="quick-link-list-item">Resume</p>
                  </li>
                  <li>
                    <a href="#">Profile Summary</a>
                  </li>
                  <li onClick={() => handleScroll("skills")}>
                    <p className="quick-link-list-item">Skills</p>
                  </li>
                  <li onClick={() => handleScroll("employment")}>
                    <p className="quick-link-list-item">Employment</p>
                  </li>
                  <li onClick={() => handleScroll("education")}>
                    <p className="quick-link-list-item">Education</p>
                  </li>
                  <li onClick={() => handleScroll("education")}>
                    <p className="quick-link-list-item">IT Skills</p>
                  </li>
                  <li onClick={() => handleScroll("education")}>
                    <p className="quick-link-list-item">Online Profiles</p>
                  </li>
                  <li onClick={() => handleScroll("education")}>
                    <p className="quick-link-list-item">Carrer Profile</p>
                  </li>
                  <li>
                    <a href="#">Personal Details</a>
                  </li>
                </ul>
              </div>
              <div
                className="col-md-9 pt-3 pb-3 pl-5 pr-5 card-info"
                style={{ borderLeft: "1px solid #eee", fontSize: "12px" }}
              >
                {renderResume()}
                {renderEmployment()}
                {renderEducation()}
                {renderSkills()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppModal open={open} closeModal={handleCloseModal} name={modalName}>
        {getModal()}
      </AppModal>
      <Footer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
