import React from "react";
import "./style.scss";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { request } from "../../../utils/api";
import { getToken, getUserType } from "../../../utils/user";
import AppNavbar from "../../../components/Navbar";
import Drawer from "../../../components/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faIndianRupeeSign,
  faMapMarkerAlt,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

import ButtonComponent from "../../../components/Button";
import CandidateNavBar from "../../../components/Candidate/Navbar";
import Footer from "../../../components/Footer";
import jobdescriptionbannerImg from "../../../assets/images/employer/jobdescriptionbanner.png";
import IconComponent from "../../../components/Icon";
import SmallJobCard from "../../../components/JobCard/SmallJobCard";
import NotFoundIcon from "../../../components/NotFoundIcon";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const JobDescription = ({ setSnackbarMessage, setSnackbarState }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [recJobsloading, setRecJobsLoading] = useState(false);

  const userType = getUserType();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    setRecJobsLoading(true);
    request
      .post(
        "jobDeatilsById",
        {
          jobUniqueCode: id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    //get recomended jobs
    request
      .post(
        "recommended/jobs",
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res) => {
        setRecommendedJobs(res.data.data);
        setRecJobsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRecJobsLoading(false);
      });
  }, [id]);

  const handleApplyJob = () => {
    setLoading(true);
    const reqBody = {
      jobUniqueCode: data.job_unique_code,
    };

    request
      .post("apply/job", reqBody, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          navigate("/candidate/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };

  return (
    <>
      <CandidateNavBar />
      <div className="section margin-top-65">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {data && (
                <>
                  <div className="app-card">
                    <div className="">
                      <h1 className="card-heading">{data.job_title}</h1>
                      <p className="">{data.company_hiring_for}</p>
                      <hr className="horizontal-divider" />
                    </div>
                    <div className="mb-2 d-flex justify-content-start">
                      <div>
                        <FontAwesomeIcon
                          color="#aeaeae"
                          fontSize={15}
                          icon={faBriefcase}
                        />
                        <span className="ml-2">
                          {data.minimum_experience} - {data.maximum_experience}{" "}
                          Years
                        </span>
                      </div>
                      <div className="ml-5">
                        <FontAwesomeIcon
                          color="#aeaeae"
                          fontSize={15}
                          icon={faIndianRupeeSign}
                        />
                        <span className="ml-2">
                          {data.currency} {data.minimum_salary} -{" "}
                          {data.maximum_salary} ({data.pay_frequency_title})
                        </span>
                      </div>
                    </div>
                    <div className="mb-2">
                      <FontAwesomeIcon
                        color="#aeaeae"
                        fontSize={15}
                        icon={faMapMarkerAlt}
                      />
                      <span className="ml-2">{data.location}</span>
                    </div>
                    <div className="mb-2">
                      <FontAwesomeIcon
                        color="#aeaeae"
                        size={15}
                        icon={faMapPin}
                      />
                      <span className="ml-2">{data.work_location_type}</span>
                    </div>
                    <hr className="horizontal-divider" />
                    <div className="d-flex justify-content-between">
                      <div className="jd">
                        <span className="lists">
                          <label>Posted: </label>
                          <span>{data.posted_date}</span>
                        </span>
                        <span className="lists">
                          <label>Applicants: </label>
                          <span>{100}</span>
                        </span>
                      </div>
                      <div>
                        {location.state && location.state.type === "my_jobs" ? (
                          <span className="applied-text">Applied</span>
                        ) : (
                          <ButtonComponent
                            title={"Apply"}
                            variant={"contained"}
                            style={{ width: "150px" }}
                            onClick={handleApplyJob}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="app-card mt-3">
                    <h3 className="inner-sub-heading">Job Description</h3>
                    <p className="mt-3">{data.job_description}</p>
                    <div className="mt-2">
                      <div className="jd-dept">
                        <label>Department: </label>
                        <span>{data.department}</span>
                      </div>
                      <div className="jd-dept">
                        <label>Category: </label>
                        <span>{data.category}</span>
                      </div>
                      <div className="jd-dept">
                        <label>Employment Type: </label>
                        <span>{data.employment_type}</span>
                      </div>
                      <div className="jd-dept">
                        <label>Payment Type: </label>
                        <span>{data.payment_type}</span>
                      </div>
                      <div className="jd-dept">
                        <label>Additional Perks: </label>
                        {data.additional_perks.split(",").map((perk) => {
                          return <span className="add-perks">{perk}</span>;
                        })}
                        {/* <span>{data.additional_perks}</span> */}
                      </div>
                      <div className="jd-dept">
                        <label>Education: </label>
                        <span>{data.degree}</span>
                      </div>
                    </div>
                    <div className="mt-3 mb-3">
                      <h3 className="inner-sub-heading mb-3">Key Skills</h3>
                      {data.skills.map((skill) => {
                        return <span className="skill">{skill.name}</span>;
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="loader-cont-fixed">
          <SyncLoader
            color={"rgba(54, 215, 183, 1)"}
            loading={loading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : null}
      <Footer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDescription);
