import React from "react";
import "./MultilineInput.css";
import { OutlinedInput, TextField } from "@mui/material";

const style = {
  "& .MuiOutlinedInput-input": {
    padding: "10px 14px",
    width: "100%",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: 0
  },
};

const MultilineInput = ({
  title,
  placeholder,
  value,
  onChange,
  id,
  name,
  error,
  errorMsg,
  onBlur,
  inputPropsStyle={}
}) => {
  return (
    <div className="multiline-input-cont">
      <p className="multiline-input-title">{title}</p>
      <TextField        
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        multiline={true}
        fullWidth={true}
        placeholder={placeholder}
        error={error}
        helperText={errorMsg}
        onBlur={onBlur}
        inputProps={{
          style: inputPropsStyle
        }}
        sx={style}></TextField>
    </div>
  );
};

export default MultilineInput;
