import React from 'react'
import CandidateNavBar from '../../../components/Candidate/Navbar'
import Footer from '../../../components/Footer'
import "./stepper.css"

function JobApplicationStatus() {
  return (
    <>
      <CandidateNavBar />
      <div className="section margin-top-65">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="app-card">
                <h1 className="card-heading">HR Associate</h1>
                <p className="mt-2">Infosys Limited</p>
                <hr />
                <h3 className="inner-heading">Application Status</h3>
                <div className="mt-4">
                  <ol class="stepper">
                    <li class="stepper-item done">
                      <h3 class="inner-sub-heading">Applied</h3>
                      <p class="text-grey mt-2">9th Dec, 2023</p>
                    </li>
                    <li class="stepper-item done">
                      <h3 class="inner-sub-heading">Shortlisted</h3>
                      <p class="text-grey mt-2">12th Dec, 2023</p>
                    </li>
                    <li class="stepper-item">
                      <h3 class="inner-sub-heading">Awaiting Action</h3>
                      <p class="text-grey mt-2"></p>
                    </li>
                    <li class="stepper-item">
                      <h3 class="inner-sub-heading">Awaiting Action</h3>
                      <p class="text-grey mt-2"></p>
                    </li>
                  </ol>
                </div>
                <div className="mt-4">
                  <h3 className="inner-heading">Activities</h3>
                  <div className="actvities">
                    <div>
                      <span className="count">100</span>
                      <span className="text">Total Applications</span>
                    </div>
                    <div>
                      <span className="count">12</span>
                      <span className="text">
                        Applications Viewed by Recruiter
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default JobApplicationStatus
