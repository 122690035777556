import React, { useEffect, useState, useRef, useCallback } from "react";
import "./JobDetails.scss";
import "../../../components/Input/Input.css";
import {
  Autocomplete,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Switch
} from "@mui/material";
import NormalInput from "../../../components/Input";
import SelectComponent from "../../../components/Select";
import {
  faBuilding,
  faDollarSign,
  faMapMarkerAlt,
  faRupeeSign,
  faUserTie,
  faArrowRight,
  faReplyAll
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { request } from "../../../utils/api";
import { getEmployerID, getToken, getUserName } from "../../../utils/user";
import ButtonComponent from "../../../components/Button";
import { connect } from "react-redux";
import {
  setJobDetails,
  setSnackbarMessage,
  toggleSnackbar,
} from "../../../redux/actions";
import { useFormik } from "formik";
import { jobDetailsSchema } from "../../../schemas/employerSchema";
import MapContainer from "../../../components/MapContainer";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { employerPath } from "../../../utils/path";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const mapStateToProps = (state) => ({
  jobDetails: state.postJobReducer.jobDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onSetJobDetails: (data) => dispatch(setJobDetails(data)),
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const locationTypes = [
  {
    id: 1,
    value: "WFO",
    title: "Work From Office",
  },
  {
    id: 2,
    value: "WFH",
    title: "Work From Home",
  },
  {
    id: 3,
    value: "FIELD",
    title: "Field Work",
  },
];

const perkss = [
  {
    id: 1,
    title: "Weekly Payout",
    status: false,
  },
  {
    id: 2,
    title: "Overtime Pay",
    status: false,
  },
  {
    id: 3,
    title: "Joining Bonus",
    status: false,
  },
  {
    id: 4,
    title: "Annual Bonus",
    status: false,
  },
  {
    id: 5,
    title: "Travel Allowance",
    status: false,
  },
  {
    id: 6,
    title: "Petrol Allowance",
    status: false,
  },
  {
    id: 7,
    title: "Mobile Allowance",
    status: false,
  },
  {
    id: 8,
    title: "Internet Allowance",
    status: false,
  },
];
const styles = {
  buttonStyle: {
    backgroundColor: "#1876D1",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    alignSelf: 'flex-end',
    color: "#fff",
    fontFamily: 'Open Sans',
    fontSize: 12,
  },
  buttonWrapper: { width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginTop: 15 },
  formHelperText: { color: '#d32f2f', fontFamily: "Open Sans", marginTop: 3, fontSize: 12, marginLeft: 15 },
  buttonPreviewStyle: {
    backgroundColor: "#000077", paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    alignSelf: 'flex-end',
    color: "#fff",
    fontFamily: 'Open Sans',
    fontSize: 12,
  },
  salaryCardText: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: '500',
  }
}
const Listing = ({ places }) => (
  <ul>{places && places.map(p => <li key={p.id}>{p.name}</li>)}</ul>
);
let mapObject;
let mapprops;
const JobDetails = ({
  screen,
  setScreen,
  jobDetails,
  setSnackbarMessage,
  setSnackbarState,
  onSetJobDetails,
  jobId,
  setJobId,
  isEdit
}) => {
  const [empTypes, setEmpTypes] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [payTypeList, setPayTypeList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [perksList, setPerksList] = useState([
    {
      id: 1,
      title: "Weekly Payout",
      status: false,
    },
    {
      id: 2,
      title: "Overtime Pay",
      status: false,
    },
    {
      id: 3,
      title: "Joining Bonus",
      status: false,
    },
    {
      id: 4,
      title: "Annual Bonus",
      status: false,
    },
    {
      id: 5,
      title: "Travel Allowance",
      status: false,
    },
    {
      id: 6,
      title: "Petrol Allowance",
      status: false,
    },
    {
      id: 7,
      title: "Mobile Allowance",
      status: false,
    },
    {
      id: 8,
      title: "Internet Allowance",
      status: false,
    },
  ]);
  const [perksArr, setPerksArr] = useState([]);
  const [industry, setIndustry] = useState(null);
  const [category, setCategory] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [payFrequencyList, setPayFrequency] = useState([]);
  const [selectedPayFrequency, setSelectedPayFrequency] = useState(null);
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityOffice, setSelectedCityOffice] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [selectedStateOffice, setSelectedStateOffice] = useState(null);
  const [isAnywhereFromIndiaSelected, setIsAnywhereFromIndiaSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataSet, setIsDataSet] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [mapService, setMapService] = useState(null);
  const [places, setPlaces] = useState([]);
  const navigate = useNavigate();
  const inputRef = useRef();
  const [selectedWorkLocations, setSelectedWorkLocations] = useState([]);


  const handleNext = (values) => {

    const {
      title,
      businessName,
      empType,
      locationType,
      address,
      payType,
      minSalary,
      maxSalary,
      isJoiningFee,
      fieldWorkLocation,
      payFrequency,
      cityId,
      officeLat,
      officeLong,
      incentive,
      variable,
      officePin,
      officeLandmark,
      officeCityId,
    } = values;

    //save job details in db

    const reqBody = {
      employerId: getEmployerID(),
      campanyHiringFor: businessName,
      jobTitle: title,
      departmentId: industry.id,
      categoryId: category.id,
      employmentTypeId: empType,
      joiningFee: isJoiningFee,
      locationTypeId: locationType,
      location: address,
      landmark: officeLandmark,
      pin: officePin,
      payTypeId: payType,
      currency: currency.id,
      payFrequency: payFrequency,
      minimumSalary: Number(minSalary),
      maximumSalary: Number(maxSalary),
      additionalPerks: perksArr.join(","),
      jobId: jobId,
      cityId: officeCityId,
      officeLat: officeLat,
      officeLong: officeLong,
      fieldWorkLocation: fieldWorkLocation,
      variableSalary: variable,
      incentiveSalary: incentive,
    };

    setLoading(true);

    request
      .post("job/create", reqBody, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          setScreen(screen + 1);
          setJobId(res.data.data.jobId);

          onSetJobDetails({
            ...values,
            industry: industry,
            category: category,
            currency: currency,
            perks: perksList,
            valid: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };
  // useEffect(() => {
  //   if(isMapReady){
  //     autoCompleteRef.current = new window.google.maps.places.Autocomplete(
  //       inputRef.current,
  //       {
  //         fields: ["address_components", "geometry", "icon", "name"],
  //         types: ["establishment"]
  //        }
  //      );
  //   }
  //  }, [isMapReady]);
  const { values, handleChange, handleBlur, errors, setValues, handleSubmit } =
    useFormik({
      initialValues: {
        title: jobId === "" ? "" : jobDetails.title ? jobDetails.title : "",
        businessName: jobId === "" ? getUserName() : jobDetails.businessName
          ? jobDetails.businessName
          : getUserName(),
        // industry: jobDetails.industry ? jobDetails.industry : "",
        empType: jobId === "" ? "" : jobDetails.empType ? jobDetails.empType : "",
        locationType: jobId === "" ? "" : jobDetails.locationType ? jobDetails.locationType : "",
        address: jobId === "" ? "" : jobDetails.address ? jobDetails.address : "",
        payType: jobId === "" ? "" : jobDetails.payType ? jobDetails.payType : "",
        minSalary: jobId === "" ? "" : jobDetails.minSalary ? jobDetails.minSalary : "",
        maxSalary: jobId === "" ? "" : jobDetails.maxSalary ? jobDetails.maxSalary : "",
        incentive: jobId === "" ? "" : jobDetails.incentive ? jobDetails.incentive : "",
        variable: jobId === "" ? "" : jobDetails.variable ? jobDetails.variable : "",
        isJoiningFee: jobId === "" ? "" : jobDetails.isJoiningFee ? jobDetails.isJoiningFee : "",
        payFrequency: jobId === "" ? "" : jobDetails.payFrequency ? jobDetails.payFrequency : "",
        fieldWorkLocation: jobId === "" ? "" : jobDetails.fieldWorkLocation ? jobDetails.fieldWorkLocation : "",
        cityId: jobId === "" ? "" : jobDetails.cityId ? jobDetails.cityId : "",
        officeLat: jobId === "" ? "" : jobDetails.officeLat ? jobDetails.officeLat : "",
        officeLong: jobId === "" ? "" : jobDetails.officeLong ? jobDetails.officeLong : "",
      },
      validationSchema: jobDetailsSchema,
      onSubmit: (values) => {
        handleNext(values);
      },
    });

  useEffect(() => {
    console.log(jobId)
    if (isEdit && setValues && industryList.length > 0 && empTypes.length > 0 && categoryList.length > 0 && payTypeList.length > 0 && perksList.length > 0 && !isDataSet && citiesList.length > 0 && payFrequencyList.length > 0 && stateList.length > 0) {
      setLoading(true);
      request
        .post(
          "jobDeatilsById",
          {
            jobUniqueCode: jobId,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((res) => {
          // {
          //   job_unique_code: '169462274187',
          //   company_hiring_for: 'Soumendra Nanda Consulting',
          //   job_title: 'Accountant',
          //   department: 'Engineering - Software Development',
          //   category: 'Accounting/Finance',
          //   employment_type: 'Full Time',
          //   joining_fee: '0',
          //   posted_date: '2023-09-13',
          //   closed_date: null,
          //   location: 'Patia,Bhubaneswar',
          //   work_location_type: 'Work From Office',
          //   minimum_salary: '10000',
          //   maximum_salary: '15000',
          //   additional_perks: 'Weekly Payout',
          //   payment_type: 'Fixed Only',
          //   currency: 'Rs',
          //   female_candidate_allowed: 'YES',
          //   minimum_experience: '2',
          //   maximum_experience: '4',
          //   degree: 'Bachlor',
          //   minimum_education: 'B.A',
          //   job_description: 'Test',
          //   skills: [ { id: '4', name: 'Python' } ],
          //   interview_mode: '',
          //   contact_candidate_via_call_whatsapp: ''
          // }
          const jobData = res.data.data;
          setValues(
            {
              title: jobData.job_title,
              businessName: jobData.company_hiring_for,
              empType: empTypes?.find(item => item.title === jobData.employment_type).id,
              locationType: jobData.work_location_type ? locationTypes.find(item => item.title === jobData.work_location_type).value : "",
              address: jobData.location,
              payType: payTypeList?.find(item => item.title === jobData.payment_type).id,
              minSalary: jobData.minimum_salary,
              maxSalary: jobData.maximum_salary,
              incentive: jobData.incentive_salary ? jobData.incentive_salary : "",
              variable: jobData.variable_salary ? jobData.variable_salary : "",
              isJoiningFee: jobData.joining_fee,
              cityId: jobData.city_id,
              fieldWorkLocation: jobData.field_work_location ? jobData.field_work_location : "",
              officeLat: jobData.office_lat ? jobData.office_lat : "",
              officeLong: jobData.office_long ? jobData.office_long : "",
              payFrequency: jobData.pay_frequency ? jobData.pay_frequency : "",
              officeLandmark: jobData.landmark,
              officePin: jobData.pin,
              officeStateId: jobData.state_id,
              officeCityId: jobData.city_id, //jobData.work_location_type_id === 'WFH' ? '' : jobData.city_id,
            }
          )
          console.log("api perk: ", perksList);
          let ar = perksList;
          console.log("Edit perks: ",jobData.additional_perks.split(','))
          // console.log(payTypeList.find(item => item.title === jobData.payment_type));
          jobData.additional_perks.split(',').filter((additional) => {
            return perksList.filter((perk) => {
              if (additional.trim() === perk.title.trim()) {
                let obj = {
                  id: perk.id,
                  title: perk.title,
                  status: true,
                }
                ar[ar.findIndex(item => item.title === additional)] = obj;
              }
            })
          })
          setPerksList(ar);
          console.log(ar, "ar::::");
          let selectedPerks = [];
          ar.map((perk) => {
            if (perk.status) {
              selectedPerks.push(perk.title);
            }
          });
          setPerksArr(selectedPerks);

          setIndustry(industryList.find(item => item.title === jobData.department));
          setCategory(categoryList.find(item => item.title === jobData.category));
          setSelectedPayFrequency(payFrequencyList.find(item => item.id === jobData.pay_frequency))
          if(jobData.work_location_type_id === 'WFH'){
            setIsAnywhereFromIndiaSelected(jobData.city_id === "0" ? true : false);
          }
          setSelectedCity(citiesList.find(item => item.title === jobData.city_name));
          setSelectedStateOffice(stateList.find(item => item.id === jobData.state_id));
          setSelectedWorkLocations(jobData.field_work_location !== '' && jobData.field_work_location !== null ? JSON.parse(jobData.field_work_location) : []);
          // if (jobData.work_location_type_id !== 'WFH') {
          setSelectedCityOffice(citiesList.find(item => item.title === jobData.city_name));
          // }
          // setPerksList(perksList.map(perk => { perk.title === jobData.additional_perks ? perk.status = true : perk.status = false; return perk; }));
          console.log(res.data.data);
          setIsDataSet(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [isEdit, jobId, setValues, industryList, empTypes, categoryList, payTypeList, perksList, isDataSet, citiesList, payFrequencyList, stateList])

  useEffect(() => {
    // initMap();
    setPayFrequency([{ id: "2", title: "Monthly" }, { id: "1", title: "Yearly" }])
    if (!isEdit) {
      setSelectedPayFrequency({ id: "2", title: "Monthly" });
      setValues({ ...values, payType: "1", payFrequency: "2" })
    }
    //set Perks
    // if (jobDetails.perks.length > 0) {
    //   setPerksList(jobDetails.perks);
    // }

    //get states
    request
      .get("master/state", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);
        setStateList(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
    //get cities
    request
      .get("master/cities", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);
        setCitiesList(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });

    //get employment types

    request
      .get("master/employmentTypes", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setEmpTypes(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // setSnackbarState(true);
        // setSnackbarMessage(err.response.data.message);
      });

    //get categories

    request
      .get("master/vertical-segments", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setCategoryList(res.data.data);
        if (jobDetails.category) {
          setCategory(jobDetails.category);
        } else {
          setCategory(res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // setSnackbarState(true);
        // setSnackbarMessage(err.response.data.message);
      });

    //get departments

    request
      .get("master/departments", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setIndustryList(res.data.data);
        if (jobDetails.industry) {
          setIndustry(jobDetails.industry);
        } else {
          setIndustry(res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // setSnackbarState(true);
        // setSnackbarMessage(err.response.data.message);
      });

    //get currencies

    request
      .get("master/currencies", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setCurrencyList(res.data.data);
        if (jobDetails.currency) {
          setCurrency(jobDetails.currency);
        } else {
          setCurrency(res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // setSnackbarState(true);
        // setSnackbarMessage(err.response.data.message);
      });

    //get pay types

    request
      .get("master/payTypes", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setPayTypeList(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // setSnackbarState(true);
        // setSnackbarMessage(err.response.data.message);
      });
  }, []);


  const handleChangePerk = (event) => {
    // console.log(event.target.name, event.target.checked);

    // const perksListNew = [...perksList];
    let selectedPerks = [];
    console.log("before: ", perksList)
    perksList.map((perk) => {
      if (perk.title === event.target.name) {
        perk.status = event.target.checked;
      }
    });
    perksList.map((perk) => {
      if (perk.status) {
        selectedPerks.push(perk.title);
      }
    });
    setPerksArr(selectedPerks);
  };
  const initMap = (mapProps, map) => {
    mapprops = mapProps;
    mapObject = map;
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    setMapService(service);
    setIsMapReady(true);
  }

  const fetchPlaces = () => {
    if (values.officeLandmark !== "" && values.officeLandmark !== undefined && values.officeLandmark !== null) {
      const request = {
        // location: map.center,
        // types: ['(cities)', 'establishment'],
        // radius: '500',
        fields: ['name', 'formatted_address', 'geometry'],
        query: values.officeLandmark,
      };
      mapService.findPlaceFromQuery(request, (results, status) => {
        if (status === mapprops.google.maps.places.PlacesServiceStatus.OK)
          console.log("places google: ", results);
        setPlaces(results)
      });
    }
  }
  const renderCompensesationBreakup = () => {
    let salaryBreakupFixed = false;
    let salaryBreakupIncentive = false;
    let salaryBreakupVariable = false;
    switch (values.payType) {
      case "1":
        //fixed only
        salaryBreakupFixed = true;
        salaryBreakupIncentive = false;
        salaryBreakupVariable = false;
        break;
      case "2":
        //fixed+incentive
        salaryBreakupFixed = true;
        salaryBreakupIncentive = true;
        salaryBreakupVariable = false;
        break;
      case "3":
        //fixed+varibale
        salaryBreakupFixed = true;
        salaryBreakupIncentive = false;
        salaryBreakupVariable = true;
        break;
      case "4":
        //fixed+varibale+incentive
        salaryBreakupFixed = true;
        salaryBreakupIncentive = true;
        salaryBreakupVariable = true;
        break;
      case "5":
        //incentive
        salaryBreakupFixed = false;
        salaryBreakupIncentive = true;
        salaryBreakupVariable = false;
        break;
      default:
        break;
    }
    if ((selectedPayFrequency !== null && selectedPayFrequency !== undefined) && ((values.payType === "1" && values.minSalary !== "" && values.maxSalary !== "") || (values.payType === "2" && values.minSalary !== "" && values.maxSalary !== "" && values.incentive !== "") || (values.payType === "3" && values.minSalary !== "" && values.maxSalary !== "" && values.variable !== "") || (values.payType === "4" && values.minSalary !== "" && values.maxSalary !== "" && values.incentive !== "" && values.variable !== "") || (values.payType === "5" && values.incentive !== ""))) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginLeft: 15 }}>
          <p style={{ marginBottom: 8, fontFamily: 'Open Sans', fontSize: 15, fontWeight: '600' }}>Salary breakup shown to candidates</p>
          <div style={{ backgroundColor: '#FDF8E3', paddingLeft: 15, paddingRight: 15, borderRadius: 5 }}>
            <Divider style={{ width: '100%', marginLeft: 0 }} />
            {salaryBreakupFixed ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><p style={styles.salaryCardText}>Fixed Salay / {selectedPayFrequency.title}</p> <p>{values.minSalary} to {values.maxSalary}</p></div> : null}
            {salaryBreakupIncentive ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><p style={styles.salaryCardText}>Average Incentive / {selectedPayFrequency.title}</p> <p>{values.payType === "5" ? null : '+'} {values.incentive}</p></div> : null}
            {salaryBreakupVariable ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><p style={styles.salaryCardText}>Average Variable / {selectedPayFrequency.title}</p> <p>+ {values.variable}</p></div> : null}
            <Divider style={{ width: '100%', marginLeft: 0, marginTop: 5, marginBottom: 20 }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><p style={styles.salaryCardText}>Earning potential / {selectedPayFrequency.title} </p> <p>{values.payType === "5" ? null : values.minSalary} {values.payType === "5" ? null : 'to'} {Number(values.maxSalary) + Number(values.incentive) + Number(values.variable)}</p></div>
          </div>
        </div>

      )
    }
  }

  const renderAutocomplete = useCallback((val) => {
    if (isDataSet || !isEdit) {
      return (
        <div>
          <Autocomplete
            id={"officeLandmark"}
            name={"officeLandmark"}
            freeSolo
            disableClearable
            clearOnBlur={false}
            disabled={isAnywhereFromIndiaSelected}
            autoComplete
            value={val.officeLandmark}
            inputValue={val.officeLandmark}
            options={places !== null && places.length > 0 ? places.map((option) => option.formatted_address) : []}
            onChange={(event, newValue) => {
              const lati = places.find(item => item.formatted_address === newValue).geometry.location.lat();
              const long = places.find(item => item.formatted_address === newValue).geometry.location.lng();
              console.log("lati, long", lati, long);
              setValues({ ...val, officeLandmark: newValue, officeLat: lati, officeLong: long })
            }
            }
            onInputChange={(event, newInputValue) => {
              setValues({ ...val, officeLandmark: newInputValue });
              fetchPlaces(mapprops, mapObject)
            }}
            onBlur={handleBlur}
            renderInput={(params) => (
              <div className="input-cont">
                <p className="input-title">Office landmark</p>
                <TextField
                  {...params}
                  style={{ backgroundColor: isAnywhereFromIndiaSelected ? 'rgb(235, 235, 228, 0.5)' : 'transparent' }}
                  onBlur={handleBlur}
                // InputProps={{ ...params.InputProps, type: 'search' }}
                >
                </TextField>
              </div>
            )}
          />
          {errors.officeLandmark ? (<div style={{ height: 20, marginTop: -18, marginBottom: 20 }}>
            <FormHelperText style={{ ...styles.formHelperText, marginTop: 0 }}>{errors.officeLandmark}</FormHelperText>
          </div>) : null}</div>
      )
    }
  }, [isDataSet, places, values.officeLandmark, isEdit, isAnywhereFromIndiaSelected, errors.officeLandmark])

  const renderMap = useCallback(() => {
    console.log("renderMap", values.officeLat);
    if (!isAnywhereFromIndiaSelected) {
      return (
        <div className="location-map" style={{ position: 'relative', height: '35vh' }}>
          <Map
            google={window.google}
            style={{ height: 300, width: '100%', position: 'absolute' }}
            center={{ lat: values.officeLat ? values.officeLat : 20.5937, lng: values.officeLong ? values.officeLong : 78.9629 }}
            className="location-map"
            onReady={initMap}
            zoom={14}>
            <Marker
              name="SOMA"
              position={{ lat: values.officeLat, lng: values.officeLong }}
              title="The marker`s title will appear as a tooltip."
            />
          </Map>
        </div>
      )
    }
    else {
      return null;
    }

  }, [values.officeLat, values.officeLong, isAnywhereFromIndiaSelected])
  return (
    <>
      {/* Job Details */}
      <h2 className="heading">Job Details</h2>
      <h4 className="sub_heading">
        We use this information to find the best candidates for the job
      </h4>
      <div className="list-cont">
        <div className="business-name-cont">
          <NormalInput
            id={"businessName"}
            name={"businessName"}
            title={"Company you're hiring for"}
            placeholder={"Enter your business name"}
            type={"text"}
            value={values.businessName}
            onChange={handleChange}
            error={errors.businessName ? true : false}
            errorMsg={errors.businessName}
            onBlur={handleBlur}>
            {/* <FontAwesomeIcon icon={faBuilding} color={values.businessName === undefined || values.businessName === "" || values.businessName === null ? "#757575" : "#1876D1"} /> */}
          </NormalInput>
        </div>
        <div></div>
        <div className="job-title-cont">
          <NormalInput
            id={"title"}
            name={"title"}
            title={"Job Title"}
            placeholder={"Enter a suitable job title"}
            type={"text"}
            value={values.title}
            onChange={handleChange}
            error={errors.title ? true : false}
            errorMsg={errors.title}
            onBlur={handleBlur}>
            {/* <FontAwesomeIcon icon={faUserTie} color={values.title === undefined || values.title === "" || values.title === null ? "#757575" : "#1876D1"} /> */}
          </NormalInput>
        </div>
        <SelectComponent
          style={{ width: "100%" }}
          title={"Department"}
          list={industryList}
          value={industry}
          onChange={(value) => setIndustry(value)}
        />
        <SelectComponent
          style={{ width: "100%" }}
          title={"Category"}
          list={categoryList}
          value={category}
          onChange={(value) => setCategory(value)}
        />
        <div className="radio-group-cont jobType">
          <p className="radio-group-title">Employment Type</p>
          <FormControl error={errors.empType ? true : false} variant="standard">
            <RadioGroup
              className="radio-card-cont"
              row
              name="radio-buttons-group"
              value={values.empType}
              onChange={(e) => {
                setValues({ ...values, empType: e.target.value })
              }
              }>
              {empTypes.map((type) => {
                return (
                  <FormControlLabel
                    key={type.id}
                    className="radio-cont"
                    value={type.id}
                    control={<Radio sx={{ padding: 0 }} size="small" />}
                    label={type.title}
                    checked={type.id === values.empType}
                  />
                );
              })}
            </RadioGroup>
            {errors.empType ? (
              <FormHelperText style={styles.formHelperText}>{errors.empType}</FormHelperText>
            ) : null}
          </FormControl>
        </div>
      </div>
      <Divider />
      {/* Location Details */}
      <h2 className="heading">Location</h2>
      <h4 className="sub_heading">
        Let candidates know where they will be working from
      </h4>
      <div className="list-cont" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="radio-group-cont locationType">
          <p className="radio-group-title">Employment mode</p>
          <FormControl
            error={errors.locationType ? true : false}
            variant="standard">
            <RadioGroup
              className="radio-card-cont"
              row
              name="radio-buttons-group"
              value={values.locationType}
              onChange={(e) => {
                setIsAnywhereFromIndiaSelected(false);
                setSelectedCity(null);
                switch (e.target.value) {
                  case 'WFH':
                    setValues({ ...values, fieldWorkLocation: "", locationType: e.target.value, cityId: null });
                    setSelectedWorkLocations([]);
                    break;
                  case 'WFO':
                    setValues({ ...values, fieldWorkLocation: "", locationType: e.target.value, cityId: null });
                    setSelectedWorkLocations([]);
                    break;
                  case 'FIELD':
                    setValues({ ...values, cityId: null, locationType: e.target.value })
                    break;
                  default:
                    break;
                }
              }}>
              {locationTypes.map((type) => {
                return (
                  <FormControlLabel
                    key={type.id}
                    className="radio-cont"
                    value={type.value}
                    control={<Radio sx={{ padding: 0 }} size="small" />}
                    label={type.title}
                    checked={type.value === values.locationType}
                  />
                );
              })}
            </RadioGroup>
            {errors.locationType ? (
              <FormHelperText style={styles.formHelperText}>{errors.locationType}</FormHelperText>
            ) : null}
          </FormControl>
        </div>
        {values.locationType === 'WFH' ? <div style={{ display: 'flex', flex: 1, alignSelf: 'flex-start' }}>
          <div style={{ display: 'flex', flex: 1, paddingLeft: 10 }}>
            <FormControlLabel control={<Checkbox
              checked={isAnywhereFromIndiaSelected}
              onChange={() => {
                setIsAnywhereFromIndiaSelected(!isAnywhereFromIndiaSelected);
                if (!isAnywhereFromIndiaSelected) {
                  setSelectedCity(null);
                  setSelectedCityOffice(null);
                  setSelectedStateOffice(null);
                  setValues({
                    ...values,
                    cityId: "0",
                    officeCityId: "0",
                    address: "",
                    officePin: "",
                    officeLat: "",
                    officeLong: "",
                    officeLandmark: ""
                  })
                }
                else {
                  setValues({
                    ...values,
                    cityId: "",
                    officeCityId: "",
                    officeStateId: ""
                  })
                }
              }}
              name={"anywhereInIndia"}
            />} label="Anywhere in India" />
          </div>
          {/* <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <SelectComponent
              style={{ marginBottom: 0 }}
              title={"City"}
              list={citiesList}
              value={selectedCity}
              onChange={(value) => {
                setSelectedCity(value);
                setValues({ ...values, cityId: citiesList.find(item => item.title === value.title).id })
              }}
              disabled={isAnywhereFromIndiaSelected}
            />
            {errors.cityId ? <FormHelperText style={styles.formHelperText}>{errors.cityId}</FormHelperText> : null}
          </div> */}
        </div> : null}
        {values.locationType === 'FIELD' ? <div style={{ display: 'flex', width: '50%', flexDirection: 'column', alignSelf: 'flex-start' }}>
          <p className="input-title">Field work address</p>
          <Autocomplete
            multiple
            freeSolo
            id={"fieldWorkLocation"}
            name={"fieldWorkLocation"}
            value={selectedWorkLocations}
            onChange={(e, value) => { setValues({ ...values, fieldWorkLocation: JSON.stringify(value) }); console.log(value); setSelectedWorkLocations(value) }}
            disablePortal
            options={citiesList.map(item => item.title)}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                onChange={(e) => console.log(e.target.value)}
                {...params}
              />
            )}
          />
          {/* <NormalInput
            id={"fieldWorkLocation"}
            name={"fieldWorkLocation"}
            title={"Field work address"}
            placeholder={"Enter field address"}
            type={"text"}
            value={values.fieldWorkLocation}
            onChange={handleChange}
            error={errors.fieldWorkLocation ? true : false}
            errorMsg={errors.fieldWorkLocation}
            onBlur={handleBlur}>
            <FontAwesomeIcon icon={faMapMarkerAlt} color={values.fieldWorkLocation === undefined || values.fieldWorkLocation === "" || values.fieldWorkLocation === null ? "#757575" : "#1876D1"} />
          </NormalInput> */}
        </div> : null}
        <div className="radio-group-cont locationType">
          <p className="radio-group-title">Office address</p>
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ flex: 1, paddingRight: 5 }}>
              <SelectComponent
                title={"State"}
                list={stateList}
                style={{ marginBottom: 0 }}
                value={selectedStateOffice}
                onChange={(value) => {
                  setSelectedStateOffice(value);
                  setValues({ ...values, officeStateId: stateList.find(item => item.title === value.title).id })
                }}
                disabled={values.locationType === 'WFH' ? isAnywhereFromIndiaSelected : false}
              />
              {errors.officeStateId ? <FormHelperText style={styles.formHelperText}>{errors.officeStateId}</FormHelperText> : null}
            </div>
            {console.log("errors: ", errors)}
            <div style={{ flex: 1, paddingLeft: 5, paddingRight: 5 }}>
              <SelectComponent
                title={"City"}
                style={{ marginBottom: 0 }}
                list={citiesList}
                value={selectedCityOffice}
                disabled={values.locationType === 'WFH' ? isAnywhereFromIndiaSelected : false}
                onChange={(value) => {
                  setSelectedCityOffice(value);
                  setValues({ ...values, officeCityId: citiesList.find(item => item.title === value.title).id })
                }}
              />
              {errors.officeCityId ? <FormHelperText style={styles.formHelperText}>{errors.officeCityId}</FormHelperText> : null}
            </div>
            <div style={{ flex: 1, paddingLeft: 5, paddingRight: 5 }}>
              <NormalInput
                id={"address"}
                name={"address"}
                title={"Address"}
                placeholder={"Enter your office address"}
                type={"text"}
                value={values.address}
                onChange={handleChange}
                error={errors.address ? true : false}
                errorMsg={errors.address}
                disabled={values.locationType === 'WFH' ? isAnywhereFromIndiaSelected : false}
                onBlur={handleBlur}>
                {/* <FontAwesomeIcon icon={faMapMarkerAlt} color={values.address === undefined || values.address === "" || values.address === null ? "#757575" : "#1876D1"} /> */}
              </NormalInput>
            </div>
            <div style={{ flex: 1, paddingLeft: 5 }}>
              <NormalInput
                id={"officePin"}
                name={"officePin"}
                title={"Pin"}
                placeholder={"Enter your office address pin code"}
                type={"text"}
                value={values.officePin}
                onChange={handleChange}
                error={errors.officePin ? true : false}
                errorMsg={errors.officePin}
                disabled={values.locationType === 'WFH' ? isAnywhereFromIndiaSelected : false}
                onBlur={handleBlur}>
                {/* <FontAwesomeIcon icon={faMapMarkerAlt} color={values.officePin === undefined || values.officePin === "" || values.officePin === null ? "#757575" : "#1876D1"} /> */}
              </NormalInput>
            </div>

          </div>

        </div>
        <div className="office-addess-cont" style={{ width: '50%', alignSelf: 'flex-start' }}>
          {/* <Map google={window.google} onReady={initMap}
            visible={false}> */}
          {/* <Listing places={places} />
            <p></p> */}
          {/* </Map> */}
          {renderAutocomplete(values)}
          {/* <NormalInput
            id={"address"}
            // ref={inputRef}
            name={"address"}
            title={"Office landmark"}
            placeholder={"Enter your office address"}
            type={"text"}
            value={values.address}
            onChange={(value)=>{ setValues({...values, address: value.target.value}); fetchPlaces(mapprops, mapObject)}}
            error={errors.address ? true : false}
            errorMsg={errors.address}
            onBlur={handleBlur}>
            <FontAwesomeIcon icon={faMapMarkerAlt} color={values.address === undefined || values.address === "" || values.address === null ? "#757575" : "#1876D1"} />
          </NormalInput> */}
        </div>
        {renderMap()}
        {/* <div className="location-map"> */}
        {/* <iframe
            src="https://www.google.com/maps/search/?api=1&query=47.5951518%2C-122.3316393"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe> */}
        {/* <MapContainer address={values.address} /> */}
        {/* </div> */}
      </div>
      <Divider />
      {/* Compensation Details */}
      <h2 className="heading">Compensation</h2>
      <h4 className="sub_heading">
        Job posting with right salary and incentives will help you find the
        right candidates
      </h4>
      <div className="list-cont" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="radio-group-cont" style={{ width: '100%' }}>
          <p className="radio-group-title">What is the pay type?</p>
          <FormControl error={errors.payType ? true : false} variant="standard">
            <RadioGroup
              className="radio-card-cont"
              row
              name="radio-buttons-group"
              value={values.payType}
              onChange={(e) => {
                setValues({ ...values, payType: e.target.value, minSalary: "", maxSalary: "", incentive: "", variable: "" })
              }}>
              {payTypeList.map((type) => {
                return (
                  <FormControlLabel
                    key={type.id}
                    className="radio-cont"
                    value={type.id}
                    control={<Radio sx={{ padding: 0 }} size="small" />}
                    label={type.title}
                    checked={type.id === values.payType}
                  />
                );
              })}
            </RadioGroup>
            {errors.payType ? (
              <FormHelperText style={styles.formHelperText}>{errors.payType}</FormHelperText>
            ) : null}
          </FormControl>
        </div>
        <div style={{ width: '100%', display: 'flex', marginBottom: 15 }}>
          {/* <p className="salary-heading">Fixed salary</p> */}
          <div className="salary-min-max-cont" style={{ width: '50%' }}>
            <SelectComponent
              title={"Pay frequency"}
              list={payFrequencyList}
              value={selectedPayFrequency}
              onChange={(value) => {
                setSelectedPayFrequency(value);
                setValues({ ...values, payFrequency: payFrequencyList.find(item => item.title === value.title)?.id });
              }}
            />
            <SelectComponent
              title={"Currency"}
              list={currencyList}
              value={currency}
              onChange={(value) => setCurrency(value)}
            />
            {values.payType !== "5" ? <>
              <NormalInput
                id={"minSalary"}
                name={"minSalary"}
                title={"Fixed salary starts with"}
                placeholder={"Enter min salary"}
                type={"text"}
                value={values.minSalary}
                onChange={handleChange}
                error={errors.minSalary ? true : false}
                errorMsg={errors.minSalary}
                onBlur={handleBlur}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: values.minSalary === undefined || values.minSalary === "" || values.minSalary === null ? "#757575" : "#1876D1" }}>{currency && currency.code}</div>
              </NormalInput>
              {/* <p className="to">to</p> */}
              <NormalInput
                id={"maxSalary"}
                name={"maxSalary"}
                title={"Fixed salary upto"}
                placeholder={"Enter max salary"}
                type={"text"}
                value={values.maxSalary}
                onChange={handleChange}
                error={errors.maxSalary ? true : false}
                errorMsg={errors.maxSalary}
                onBlur={handleBlur}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: values.maxSalary === undefined || values.maxSalary === "" || values.maxSalary === null ? "#757575" : "#1876D1" }}>{currency && currency.code}</div>
              </NormalInput>
            </> : null}
            {values.payType === "2" || values.payType === "4" || values.payType === "5" ? <NormalInput
              id={"incentive"}
              name={"incentive"}
              title={"Average Incentive"}
              placeholder={"Enter average incentive"}
              type={"text"}
              value={values.incentive}
              onChange={handleChange}
              error={errors.incentive ? true : false}
              errorMsg={errors.incentive}
              onBlur={handleBlur}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: values.incentive === undefined || values.incentive === "" || values.incentive === null ? "#757575" : "#1876D1" }}>{currency && currency.code}</div>
            </NormalInput> : null}
            {values.payType === "3" || values.payType === "4" ? <NormalInput
              id={"variable"}
              name={"variable"}
              title={"Average Variable"}
              placeholder={"Enter average variable"}
              type={"text"}
              value={values.variable}
              onChange={handleChange}
              error={errors.variable ? true : false}
              errorMsg={errors.variable}
              onBlur={handleBlur}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: values.variable === undefined || values.variable === "" || values.variable === null ? "#757575" : "#1876D1" }}>{currency && currency.code}</div>
            </NormalInput> : null}
          </div>
          {renderCompensesationBreakup()}
        </div>
        <div className="additional-perks-cont">
          <p className="perks-heading">Do you offer any additional perks?</p>
          {/* <FormGroup className="perks-cont"> */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {perksList.map((perk) => {
              return (
                <div style={{ marginRight: 20, marginLeft: 10}}>
                  <div class="form-check form-switch" style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 5 }}>
                    <div style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <input class="form-check-input switch-text" type="checkbox" id={perk.title} name={perk.title} checked={perk.status} onChange={handleChangePerk}/>
                    </div>
                    <div style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: 5 }}>
                      <label class="form-check-label switch-text" for={perk.title}>{perk.title}</label>
                    </div>
                  </div>
                </div>
              )
              //   return (
              //     <FormControlLabel
              //       key={perk.id}
              //       className="checkbox-cont"
              //       control={
              //         <Switch
              //         classes={{track: "switch-track-style"}}
              //           checked={perk.status}
              //           onChange={handleChangePerk}
              //           name={perk.title}
              //         /> 
              // }

              //       // {/* <Checkbox
              //       //      checked={perk.status}
              //       //      onChange={handleChangePerk}
              //       //      name={perk.title}
              //       //    /> */}

              //       label = { perk.title }
              //       // sx = {{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              //     />
              // );
            })}
            {/* </FormGroup> */}
          </div>
        </div>
        <div></div>
        <div className="radio-group-cont joining-fee">
          <p className="radio-group-title">
            Is there any joining fee or deposit required from the Candidate?
          </p>
          <RadioGroup
            className="radio-card-cont"
            row
            name="radio-buttons-group"
            value={values.isJoiningFee}
            onChange={(e) =>
              setValues({ ...values, isJoiningFee: e.target.value })
            }>
            <FormControlLabel
              className="radio-cont"
              value={"1"}
              control={<Radio sx={{ padding: 0 }} size="small" />}
              label={"Yes"}
            />
            <FormControlLabel
              className="radio-cont"
              value={"0"}
              control={<Radio sx={{ padding: 0 }} size="small" />}
              label={"No"}
            />
          </RadioGroup>
        </div>
      </div>
      <div className="continue-btn-cont">
        {loading ? (
          <SyncLoader
            color={"rgba(54, 215, 183, 1)"}
            loading={loading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (

          <div style={styles.buttonWrapper}>
            <Button
              onClick={() => navigate(employerPath + `/jobs/${jobId}`)}
              style={styles.buttonPreviewStyle}
              variant="contained">
              <FontAwesomeIcon style={{ marginRight: 5 }} icon={faReplyAll} color={"#fff"} />
              Exit
            </Button>
            <Button
              onClick={handleSubmit}
              style={styles.buttonStyle}
              // className="button"
              variant="contained"
              disabled={JSON.stringify(errors) !== '{}'}>
              Continue
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowRight} color="#fff" />
            </Button>
          </div>

          // <ButtonComponent
          //   title={"Continue"}
          //   variant={"contained"}
          //   style={{ width: "200px" }}
          //   onClick={handleSubmit}
          // />
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
