import React from "react";
import AppNavbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import ButtonComponent from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { apiUrl, candidatePath } from "../../../utils/path";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { connect } from "react-redux";
import { request } from "../../../utils/api";
import { getUserId } from "../../../utils/user";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const OtpVerification = (props) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();

    request
      .post("candidate/verify/otp", {
        otp: otp,
        userId: getUserId(),
      })
      .then((response) => {
        console.log(response);
        props.setSnackbarState(true);
        props.setSnackbarMessage(response.data.message);
        navigate(candidatePath + "/auth/change-password");
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          props.setSnackbarState(true);
          props.setSnackbarMessage(error.response.data.message);
        }
      });
  };

  return (
    <>
      <AppNavbar />
      <div className="section">
        <form className="otp-verification-form">
          <p className="otp-verification-heading">OTP Verification</p>
          <p className="otp-verification-text">
            Enter the verification code we just sent you on your email address.
          </p>
          <MuiOtpInput
            value={otp}
            onChange={handleChange}
            length={6}
            TextFieldsProps={{ type: "number" }}
          />
          <br />
          <p className="otp-verification-text">
            If you didn't receive a code!{" "}
            <span className="resend-btn">Resend</span>
          </p>
          <ButtonComponent
            title={"Verify"}
            variant={"contained"}
            onClick={handleVerifyOtp}
          />
        </form>
      </div>
      <Footer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerification);
