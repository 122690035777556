import React from "react";
import "./Modal.css";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 3,
  borderRadius: "15px",
  maxHeight: "80%",
  overflowY: "scroll",
};

const AppModal = ({ open, closeModal, name, children }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Fade in={open}>
        <Box sx={style} className="modal-cont">
          <div className="modal-heading">
            <p className="modal-name">{name}</p>
            <div className="close-icon-cont" onClick={closeModal}>
              <FontAwesomeIcon icon={faXmark} className="close-icon" />
            </div>
          </div>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default AppModal;
