import * as actions from "./actiontypes";

//snackbar reducer

const initialSnackbarState = {
  open: false,
  message: "",
  vertical: "top",
  horizontal: "center",
  duration: 4000,
};

export const snackbarReducer = (state = initialSnackbarState, action) => {
  switch (action.type) {
    case actions.TOGGLESNACKBAR:
      return { ...state, open: action.payload };
    case actions.SETSNACKBARMESSAGE:
      return { ...state, message: action.payload };
    default:
      return state;
  }
};

//drawer reducer

const initialDrawerState = {
  nav: {
    open: false,
    width: 69,
  },
};

export const drawerReducer = (state = initialDrawerState, action) => {
  switch (action.type) {
    case actions.OPENDRAWER:
      //console.log("open",action.payload);
      return { ...state, nav: action.payload };
    case actions.CLOSEDRAWER:
       //console.log("close", action.payload);
      return { ...state, nav: action.payload };
    default:
      return state;
  }
};

//employer reducers

const initialUserDetailsState = {
  basicDetails: {
    whoami: null,
    leadSource: null,
    orgName: "",
    phoneNumber: "",
    email: "",
  },
  accountInformation: {
    orgCode: "",
    displayName: "",
    website: "",
    gstin: "",
    pancard: "",
    industry: null,
    segment: null,
  },
  contactInformation: {
    pincode: "",
    locality: "",
    address: "",
    cityDist: "",
    state: null,
    landlineNo: "",
    altMobileNo: "",
    altEmail: "",
    landmark: "",
  },
};

export const userDetailsReducer = (state = initialUserDetailsState, action) => {
  switch (action.type) {
    case actions.BASICDETAILS:
      return { ...state, basicDetails: action.payload };
    case actions.ACCOUNTINFORMATION:
      return { ...state, accountInformation: action.payload };
    case actions.CONTACTINFORMATION:
      return { ...state, contactInformation: action.payload };
    default:
      return state;
  }
};

const initialPostJobState = {
  jobDetails: {
    title: "",
    businessName: "",
    industry: "",
    category: "",
    empType: "",
    locationType: "",
    address: "",
    payType: "",
    minSalary: "",
    maxSalary: "",
    isJoiningFee: "",
    perks: [],
    valid: false,
  },
  candidateRequirements: {
    minEducation: "",
    degreePref: "",
    femaleCandidatesPref: "",
    minExperience: "",
    maxExperience: "",
    description: "",
    skills: [],
    valid: false,
  },
  interviewInformation: {
    contactCandidate: "",
    interviewType: "",
    valid: false,
  },
};

export const postJobReducer = (state = initialPostJobState, action) => {
  switch (action.type) {
    case actions.JOBDETAILS:
      return { ...state, jobDetails: action.payload };
    case actions.CANDIDATEREQUIREMENTS:
      return { ...state, candidateRequirements: action.payload };
    case actions.INTERVIEWINFORMATION:
      return { ...state, interviewInformation: action.payload };
    default:
      return state;
  }
};
