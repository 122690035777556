import React from "react";
import "./CandiateJobCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faClock,
  faHotel,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Chip from "../Chip";
import { Link } from "react-router-dom";

const CandiateJobCard = ({ data, type }) => {
  const {
    job_unique_code,
    job_title,
    company_hiring_for,
    location,
    employment_type,
    category,
    minimum_experience,
    maximum_experience,
    organization_name,
    apply_date,
  } = data;

  console.log(type);
  return (
    <Link
      to={`/candidate/jobs/${job_unique_code}`}
      state={{ type: type }}
      className="candidate-job-card"
    >
      <div className="title-img-cont">
        {/* <div className="img-cont">
          <FontAwesomeIcon icon={faHotel} color="#3882a8" fontSize={25} />
        </div> */}
        <div className="title-cont">
          <h2 className="job-title">{job_title}</h2>
          {/* <p className="job_code">#{job_unique_code}</p> */}
        </div>
      </div>
      {/* <p className="company_name">{company_hiring_for}</p> */}
      <div className="icon-details">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <p className="detail">{location}</p>
      </div>
      <div className="icon-details">
        <FontAwesomeIcon icon={faBuilding} />
        <p className="detail">{company_hiring_for || organization_name}</p>
      </div>
      {type === "my_jobs" ? (
        <div className="icon-details">
          <FontAwesomeIcon icon={faClock} />
          <p className="detail">{apply_date}</p>
        </div>
      ) : null}
      {type === "recommended_jobs" ? (
        <div className="jobs-chips-cont">
          <Chip
            title={employment_type}
            style={{ backgroundColor: "#feffd5", color: "#9b8e00" }}
          />
          {minimum_experience && maximum_experience ? (
            <Chip
              title={`${minimum_experience} - ${maximum_experience} Yrs Exp`}
              style={{ backgroundColor: "#ffd5d5", color: "#9b0000" }}
            />
          ) : null}
          <Chip
            title={category}
            style={{ backgroundColor: "#d7ffd5", color: "#009b1c" }}
          />
        </div>
      ) : null}
    </Link>
  );
};

export default CandiateJobCard;
