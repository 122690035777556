import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faHome, faHomeAlt, faLaptop, faSeedling } from '@fortawesome/free-solid-svg-icons'

export default function JobsCategory() {
  return (
    <div className='section bg-job-category' style={{background:'#FFF4E0'}}>
        <h2 className='page-title-heading text-center'>Browse your jobs category</h2>
        <div className='container width-80-cent pt-4 text-center'>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faLaptop} />
                </span>
                <span>Enginnering</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faHomeAlt} />
                </span>
                <span>Technology</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faLaptop} />
                </span>
                <span>Enginnering</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faHomeAlt} />
                </span>
                <span>Technology</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faLaptop} />
                </span>
                <span>Enginnering</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faHomeAlt} />
                </span>
                <span>Technology</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faLaptop} />
                </span>
                <span>Enginnering</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faHomeAlt} />
                </span>
                <span>Technology</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faLaptop} />
                </span>
                <span>Enginnering</span>
            </div>
            <div className='category-card'>
                <span className='icon-box'>
                    <FontAwesomeIcon icon={faHomeAlt} />
                </span>
                <span>Technology</span>
            </div>
        </div>
    </div>
  )
}
