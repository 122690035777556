import * as yup from "yup";

export const employmentSchema = yup.object().shape({
  empTitle: yup.string().required("*Required"),
  company: yup.string().required("*Required"),
  website: yup.string(),
  location: yup.string().required("*Required"),
  amount: yup
    .number()
    .required("*Required")
    .min(0, "Salary must be a positive number"),
  industry: yup.string(),
  description: yup.string().required("*Required"),
  // .max(400, "*Description cannot exceed 400 characters")
  startDate: yup
    .date()
    .required("*Start date is required")
    .max(new Date(), "*Invalid Start Date"),
  // .test(
  //   "startDate",
  //   "*Start date cannot be the same as end date",
  //   function (value) {
  //     const endDate = this.parent.endDate;
  //     return !endDate || value.getTime() !== endDate.getTime();
  //   }
  // )
  endDate: yup
    .date()
    // .required("*End date is required")
    .min(yup.ref("startDate"), "*End date must be after start date")
    .max(new Date(), "*Invalid End Date"),
  // .test(
  //   "endDate",
  //   "*End date cannot be the same as start date",
  //   function (value) {
  //     const startDate = this.parent.startDate;
  //     return !startDate || value.getTime() !== startDate.getTime();
  //   }
  // ),
});

export const educationSchema = yup.object().shape({
  instituteName: yup.string().required("*Required"),
  location: yup.string().required("*Required"),
  startYear: yup
    .date()
    .required("*Start Year is required")
    .max(new Date(), "*Invalid Start Year"),
  endYear: yup
    .date()
    .required("*End year is required")
    .min(yup.ref("startYear"), "*End year must be after start year")
    .max(new Date(), "*Invalid End Year"),
  marks: yup.number().required("*Required"),
});

export const loginSchema = yup.object().shape({
  email: yup.string().required("*Required"),
  // .matches(
  //   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  //   "*Invalid email address"
  // ),

  password: yup.string().required("*Required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[a-z\d@$!%*?&]{8,}$/,
  //   "*Password must be at least 8 characters long and contain at least one lowercase letter, one number, and one special character"
  // ),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  //   "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
  // ),
});

export const registerSchema = yup.object().shape({
  name: yup.string().required("*Required"),
  email: yup
    .string()
    .required("*Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "*Invalid email address"
    ),
  mobileNo: yup
    .string()
    .required("*Required")
    .matches(/^(\[\-\s]?)?[0]?(91)?[6789]\d{9}$/, "*Invalid mobile number"),
  password: yup
    .string()
    .required("*Required")
    // .matches(
    //   /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[a-z\d@$!%*?&]{8,}$/,
    //   "*Password must be at least 8 characters long and contain at least one lowercase letter, one number, and one special character"
    // ),
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("*Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "*Invalid email address"
    ),
});

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("*Required")
    .matches(
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[a-z\d@$!%*?&]{8,}$/,
      "*Password must be at least 8 characters long and contain at least one lowercase letter, one number, and one special character"
    ),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  //   "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
  // ),
  confirmPassword: yup
    .string()
    .required("*Required")
    .oneOf([yup.ref("password"), null], "*Passwords must match"),
});
