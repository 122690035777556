import React from "react";
import { employerPath } from "../utils/path";
import Login from "../pages/Employer/Login";
import SignUp from "../pages/Employer/SignUp";
import ForgotPassword from "../pages/Employer/ForgotPasword";
import EmployerJobs from "../pages/Employer/Jobs";
import PageNotFound from "../pages/PageNotFound";
import PostJob from "../pages/Employer/PostJob";
import JobDescription from "../pages/Employer/JobDescription";
import EditProfile from "../pages/Employer/EditProfile";
import Profile from "../pages/Employer/Profile";
import TotalApplications from "../pages/Employer/Applications/TotalApplications";
import Applications from "../pages/Employer/Applications/Applications";

const routes = [
  {
    path: employerPath + "/auth/login",
    title: "Employer Login",
    component: <Login />,
    isPublic: true,
  },
  {
    path: employerPath + "/auth/forgot-password",
    title: "Forgot Password | Bultaminds Jobs",
    component: <ForgotPassword />,
    isPublic: true,
  },
  {
    path: employerPath + "/auth/register",
    title: "Employer Signup",
    component: <SignUp />,
    isPublic: true,
  },
  //   {
  //     path: employerPath + "/home",
  //     title: "Home | Employer",
  //     component: <EmployerHome />,
  //     isPublic: false,
  //   },
  //   {
  //     path: employerPath + "/company-details",
  //     title: "Company details",
  //     component: <EmployerCompanyDetail />,
  //     isPublic: false,
  //   },
  //   {
  //     path: employerPath + "/contact-details",
  //     title: "Contact details",
  //     component: <EmployerContactDetail />,
  //     isPublic: false,
  //   },
  //   {
  //     path: employerPath + "/address-details",
  //     title: "Address details",
  //     component: <EmployerAddress />,
  //     isPublic: false,
  //   },
  {
    path: employerPath + "/jobs",
    title: "My Jobs",
    component: <EmployerJobs />,
    isPublic: false,
  },
  {
    path: employerPath + "/jobs/:id",
    title: "Jobs | Bultaminds - Job & Applicant Management System",
    component: <JobDescription />,
    isPublic: false,
  },
  {
    path: employerPath + "/jobs/post-job",
    title: "Create Job | Bultaminds - Job & Applicant Management System",
    component: <PostJob />,
    isPublic: false,
  },
  {
    path: employerPath + "/jobs/applications",
    title: "Applications | Bultaminds - Job & Applicant Management System",
    component: <Applications />,
    isPublic: false,
  },
  {
    path: employerPath + "/jobs/total-applications",
    title:
      "Total Applications | Bultaminds - Job & Applicant Management System",
    component: <TotalApplications />,
    isPublic: false,
  },
  {
    path: employerPath + "/jobs/shortlisted-applications",
    title:
      "Shortlisted Applications | Bultaminds - Job & Applicant Management System",
    component: <TotalApplications />,
    isPublic: false,
  },
  {
    path: employerPath + "/jobs/rejected-applications",
    title:
      "Rejected Applications | Bultaminds - Job & Applicant Management System",
    component: <TotalApplications />,
    isPublic: false,
  },
  {
    path: employerPath + "/edit-profile",
    title: "Employer Profile | Bultaminds - Job & Applicant Management System",
    component: <EditProfile />,
    isPublic: false,
  },
  {
    path: employerPath + "/profile",
    title: "Employer Profile | Bultaminds - Job & Applicant Management System",
    component: <Profile />,
    isPublic: false,
  },
  {
    path: employerPath + "/*",
    title: "Page not found",
    component: <PageNotFound />,
    isPublic: true,
  },
];

export default routes;
