import React from "react";
import "./EmploymentCard.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const EmploymentCard = ({
  designation,
  companyName,
  empType,
  noticePeriod,
  joiningDate,
  endingDate,
  isCurrentEmployment,
  onClick,
  id,
}) => {
  const getJoiningDate = () => {
    const date = new Date(joiningDate);
    const month = date.toLocaleString("default", { month: "long" });

    return `${month} ${date.getFullYear()}`;
  };

  const getEndingDate = () => {
    if (isCurrentEmployment === "Yes") {
      return "Present";
    }

    const date = new Date(endingDate);
    const month = date.toLocaleString("default", { month: "long" });

    return `${month} ${date.getFullYear()}`;
  };
  return (
    <div className="employment-card-cont">
      <div className="designation-edit-delete-cont">
        <h4 className="employment-designation">{designation}</h4>
        <div className="edit-delete-cont">
          <div onClick={() => onClick(id)} className="edit-icon">
            <i style={{ fontSize: 12, color: "white" }} class="fas fa-pen"></i>
          </div>
        </div>
      </div>
      <h5 className="employment-company-name">{companyName}</h5>
      <p className="employment-type-date">
        {empType} {getJoiningDate()} to {getEndingDate()}
      </p>
      {noticePeriod ? (
        <p className="employment-notice-period">{noticePeriod} Notice Period</p>
      ) : null}
    </div>
  );
};

export default EmploymentCard;
