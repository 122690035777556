import React from "react";
import "./style.scss";
import NormalInput from "../../../../components/Input";
import ButtonComponent from "../../../../components/Button";

const SocialMediaPopup = ({
  values,
  closePopup,
  handleChange,
  handleBlur,
  errors,
}) => {
  return (
    <div className="social-media-popup-cont">
      <h3 className="heading">Add social media</h3>
      <NormalInput
        id={"linkedin"}
        name={"linkedin"}
        title={"Linkedin url"}
        placeholder={""}
        type={"text"}
        value={values.linkedin}
        onChange={handleChange}
        error={errors.linkedin ? true : false}
        errorMsg={errors.linkedin}
        onBlur={handleBlur}></NormalInput>
      <NormalInput
        id={"instagram"}
        name={"instagram"}
        title={"Instagram url"}
        placeholder={""}
        type={"text"}
        value={values.instagram}
        onChange={handleChange}
        error={errors.instagram ? true : false}
        errorMsg={errors.instagram}
        onBlur={handleBlur}>
        {/* <FontAwesomeIcon icon={faBuilding} /> */}
      </NormalInput>
      <NormalInput
        id={"facebook"}
        name={"facebook"}
        title={"Facebook url"}
        placeholder={""}
        type={"text"}
        value={values.facebook}
        onChange={handleChange}
        error={errors.facebook ? true : false}
        errorMsg={errors.facebook}
        onBlur={handleBlur}>
        {/* <FontAwesomeIcon icon={faBuilding} /> */}
      </NormalInput>
      <NormalInput
        id={"twitter"}
        name={"twitter"}
        title={"Twitter url"}
        placeholder={""}
        type={"text"}
        value={values.twitter}
        onChange={handleChange}
        error={errors.twitter ? true : false}
        errorMsg={errors.twitter}
        onBlur={handleBlur}>
        {/* <FontAwesomeIcon icon={faBuilding} /> */}
      </NormalInput>
      <div className="done-btn-cont">
        <ButtonComponent
          title={"Done"}
          style={{ width: "fit-content", marginTop: "0.5rem" }}
          variant={"contained"}
          onClick={closePopup}
        />
      </div>
    </div>
  );
};

export default SocialMediaPopup;
