/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.scss";
import Drawer from "../../../components/Drawer";
import AppNavbar from "../../../components/Navbar";
import { connect } from "react-redux";
import { setDrawerOpen } from "../../../redux/actions";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppModal from "../../../components/Modal";
import {
  faBriefcase,
  faWallet,
  faBan,
  faAddressCard,
  faCalendarDays,
  faBuilding,
  faBusinessTime,
  faMars,
  faVenus,
  faAt,
  faSquarePhone,
  faCircleDot,
  faDownload,
  faSquareCheck,
  faTrashCan,
  faFilter,
  faStopwatch,
  faCircleUser,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { faSquareCheck as faSquareCheckRegular } from "@fortawesome/free-regular-svg-icons";
import {
  Button,
  Autocomplete,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { getToken } from "../../../utils/user";
import { request } from "../../../utils/api";
import SelectComponent from "../../../components/Select";

const mapStateToProps = (state) => ({
  nav: state.drawerReducer.nav,
});
const mapDispatchToProps = (dispatch) => ({
  onSetDrawerOpen: (data) => dispatch(setDrawerOpen(data)),
});

const Applications = ({ nav }) => {
  const location = useLocation();
  const appliedJobDetails = location.state !== null ? location.state.job : null;
  const selectedApplicationType =
    location.state.selectedApplicationType !== null
      ? location.state.selectedApplicationType
      : null;
  const [applicationList, setApplicationList] = useState([]);
  const [rejectedAppList, setRejectedAppList] = useState([]);
  const [shortlistedAppList, setShortlistedAppList] = useState([]);
  const [appliedAppList, setAppliedList] = useState([]);
  const [hiredAppList, setHiredAppList] = useState([]);
  const [cardlist, setCardlist] = useState([]);
  const [totalClicked, setTotalClicked] = useState(
    selectedApplicationType === "total" ? true : false
  );
  const [hiredClicked, setHiredClicked] = useState(
    selectedApplicationType === "hired" ? true : false
  );
  const [shortlistedClicked, setShortlistedClicked] = useState(
    selectedApplicationType === "shortlisted" ? true : false
  );
  const [rejectedClicked, setRejectedClicked] = useState(
    selectedApplicationType === "rejected" ? true : false
  );
  const [appliedClicked, setAppledClicked] = useState(
    selectedApplicationType === "applied" ? true : false
  );
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillsCopy, setSkillsCopy] = useState([]);
  const [skillTyped, setSkillTyped] = useState("");
  const [degreePrefList, setDegreePrefList] = useState([]);
  const [numberPage, setNumPages] = useState([]);
  const [focusedCandidate, setFocusedCandidate] = useState(null);
  const [focusedCandiateDetails, setFocusedCandidateDetails] = useState(null);
  const [focusedCandidateNotesList, setFocusedCandidateNotesList] = useState(
    []
  );
  const [candidateNote, setCandidateNote] = useState([]);
  const [applicationStatusList, setApplicationStatusList] = useState([]);
  // const [appliedCadidateList, setAppliedCandidateList] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalName, setModalName] = useState("");
  const [remarks, setRemarks] = useState([]);

  const handleOpenModal = (name) => {
    setModalName(name);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const getNotes = () => {
    //**get note api called */
    request
      .post(
        "jobs/employer/notes",
        {
          jobUniqueCode: appliedJobDetails.job_unique_code,
          candidateId: focusedCandidate.candidate_id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log("Get notes API", response);
        setFocusedCandidateNotesList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //master/jobApplicationStatusRemarks
  const getRemarks = (statusId) => {
    request
      .get("master/jobApplicationStatusRemarks?status_id=" + statusId, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);
        setRemarks(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    //** Get applied candidate */
    request
      .post(
        "jobs/applied/candidates",
        {
          jobUniqueCode: appliedJobDetails.job_unique_code,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log("Applied candidate API", response);
        setAppliedList(response.data.data);
        setFocusedCandidate(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
    //******************** Get skills api ***************************/
    request
      .get("master/skills", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);
        let newSkillsArray = [];
        response.data.data.map((skill) => {
          newSkillsArray.push(skill.title);
        });
        setSkills(newSkillsArray);
        setSkillsCopy(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    //*********************Get skills api ends *********************/

    //******************** Get degree api ***************************/
    request
      .get("master/education/degree", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setDegreePrefList(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // setSnackbarState(true);
        // setSnackbarMessage(err.response.data.message);
      });
    //*********************Get degree api ends *********************/

    //******************** Get job application status master api ***************************/
    request
      .get("master/jobApplicationStatus", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        setApplicationStatusList(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
    //*********************Get job application status master api ends *********************/
  }, []);

  useEffect(() => {
    if (focusedCandidate !== null) {
      getNotes();
    }
  }, [focusedCandidate]);

  const handleApplicationStatus = (event) => {
    const applicationStatusId = document.getElementById("application-status");
    const selectedOption =
      applicationStatusId.options[applicationStatusId.selectedIndex];
    const bgColor = selectedOption.getAttribute("data-bg-color");
    const textColor = selectedOption.getAttribute("data-text-color");

    applicationStatusId.style.background = bgColor;
    applicationStatusId.style.color = textColor;

    if (
      selectedOption.getAttribute("value") &&
      (selectedOption.getAttribute("value") !== "" ||
        selectedOption.getAttribute("value") !== 0)
    ) {
      getRemarks(selectedOption.getAttribute("value"));
      setOpen(true);
    } else {
      setRemarks([]);
      setOpen(false);
    }
  };

  const onAddNote = () => {
    //**Add note api called */
    request
      .post(
        "jobs/employer/add-note",
        {
          jobUniqueCode: appliedJobDetails.job_unique_code,
          candidateId: Number(focusedCandidate.candidate_id),
          content: candidateNote,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log("Add note API", response);
        getNotes();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCandidateDetailsById = (cId) => {
    //**get candidate details by ID API */
    request
      .post(
        "candidate/details",
        {
          candidateId: cId,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log("Candidate details: ", response);
        setFocusedCandidateDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderApplicationCount = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="application-count-container">
            {applicationStatusList && applicationStatusList.length > 0 ? (
              applicationStatusList.map((applicationStatus) => (
                <div
                  key={applicationStatus.status_id}
                  onClick={() => {
                    setTotalClicked(true);
                    setHiredClicked(false);
                    setAppledClicked(false);
                    setShortlistedClicked(false);
                    setRejectedClicked(false);
                  }}
                  className="widht-25"
                  style={{
                    borderLeft: `6px solid ${applicationStatus.bg_color_code}`,
                    width: `${100 / applicationStatusList.length}%`,
                  }}
                >
                  <span className="count">{applicationList.length}</span>
                  <span>{applicationStatus.name}</span>
                </div>
              ))
            ) : (
              <div>No status found!</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderCandidateList = () => {
    return (
      <div className="list-section">
        {appliedAppList.map((item, index) => (
          <div
            onClick={() => {
              setFocusedCandidate(item);
              getCandidateDetailsById(item.candidate_id);
            }}
            key={item.candidate_id}
            className="list"
          >
            <h3 className="list-title">{item.name}</h3>
            <p
              className="list-sub-title"
              style={{ marginTop: "-10px", marginBottom: "0px" }}
            >
              {item.current_employment.designation !== undefined &&
              item.current_employment.designation !== null
                ? item.current_employment.designation
                : "Fresher"}
            </p>
            <div>
              <span className="fa fa-calendar mr-2 text-grey text-small"></span>
              <span className="text-grey text-small">
                {/* 15th Nov 2023, 8:40 PM */}
                {item.apply_date}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderCandidateDetail = () => {
    return (
      <div className="details-section">
        <div className="header">
          <div style={{ padding: "5px 0" }}>
            <span className="fa fa-user text-white"></span>
            <span className="text-white ml-2">
              Application Received ({appliedJobDetails.job_title} -{" "}
              {appliedJobDetails.job_unique_code})
            </span>
          </div>
          <div>
            {/* <button className="reject-btn" onClick={() => onRejectClicked()}>
              <span className="fa fa-remove"></span> Reject
            </button> */}
            <select
              id="application-status"
              className="application-status-dropdown"
              onChange={(event) => handleApplicationStatus(event)}
            >
              <option data-bg-color={"#FFF"} data-text-color={"#000"} value="">
                Select
              </option>
              {applicationStatusList && applicationStatusList.length > 0 ? (
                applicationStatusList.map((applicationStatus) => (
                  <option
                    data-bg-color={applicationStatus.bg_color_code}
                    data-text-color={applicationStatus.text_color_code}
                    value={applicationStatus.status_id}
                    key={applicationStatus.status_id}
                  >
                    {applicationStatus.name}
                  </option>
                ))
              ) : (
                <option value="" id="0">
                  No status found!
                </option>
              )}
            </select>
            {/* <button
              className="shortlist-btn"
              onClick={() => onShortListClicked()}
            >
              <span className="fa fa-check"></span> Shortlist
            </button> */}
          </div>
        </div>
        <div className="_content_basic">
          <div className="d-flex justify-content-between">
            <h3 className="inner-heading">
              {focusedCandidate !== null ? focusedCandidate.name : "Name"}
            </h3>
            <span className="text-grey">External</span>
          </div>
          <div>
            <span className="text-grey mr-2">
              {focusedCandiateDetails === null
                ? ""
                : focusedCandiateDetails.email}
            </span>
            <span className="text-grey mr-2">
              {focusedCandiateDetails === null
                ? ""
                : focusedCandiateDetails.mobile_no}
            </span>
            <span className="text-grey mr-2">
              Nayapalli, Bhubaneswar, Odisha - 751024
            </span>
          </div>
        </div>
        <div className="_content_detail">
          <div className="_content_detail_header_tab">
            <div className="d-flex justify-content-between">
              <ul>
                <li className="tab-link">Profile Summary</li>
                <li className="tab-link active">CV Preview</li>
                <li className="tab-link">Skills</li>
              </ul>
              <div>
                <ul>
                  <li>
                    <a href="#">
                      <span className="fa fa-download text-small"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fa fa-print text-small"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">{/* //Add tab content here */}</div>
            <div className="col-md-4 note-section">
              <div className="_content_detail_header_tab">
                <div
                  className="d-flex justify-content-between p-1"
                  style={{ marginTop: "0px", borderTop: "1px solid #ccc" }}
                >
                  <div>
                    <span className="fa fa-sticky-note text-small text-grey mr-2 ml-2"></span>
                    <span>Notes</span>
                  </div>
                  <div>
                    <span className="fa fa-angle-right text-small"></span>
                    <span className="fa fa-angle-right text-small"></span>
                  </div>
                </div>
              </div>
              <form className="p-2 note-form">
                <textarea
                  onChange={(e) => setCandidateNote(e.target.value)}
                  placeholder="please enter notes..."
                ></textarea>
                <button type="button" onClick={() => onAddNote()}>
                  Add Note
                </button>
              </form>
              <div className="all-notes p-2">
                <h6 className="text-grey">Notes</h6>
                <ul>
                  {focusedCandidateNotesList.map((notes) => (
                    <li key={notes.id}>
                      <span className="text-grey text-small">
                        {notes.created_at}
                      </span>
                      <p>{notes.content}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <AppNavbar isSearch={false} searchData={cardlist} />
        <div className="main d-flex">
          <Drawer />
          <div
            className="content mobile-content component-content-wrapper"
            style={{ marginLeft: nav.width }}
          >
            {renderApplicationCount()}
            <div className="row">
              <div className="col-md-3">{renderCandidateList()}</div>
              <div className="col-md-9" style={{ paddingLeft: "0px" }}>
                {renderCandidateDetail()}
              </div>
            </div>
          </div>
        </div>
        <AppModal
          open={open}
          closeModal={handleCloseModal}
          name={"Update Remarks"}
        >
          <div onCloseModal={handleCloseModal}>
            <form action="">
              <div className="form-group">
                <label htmlFor="remarks">Remarks</label>
                <select name="remarks" id="remarks" className="form-control">
                  <option value="">Select</option>
                  {remarks && remarks.length > 0 ? (
                    remarks.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.remarks}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
              <div className="form-group text-right">
                <button className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </AppModal>
      </>
    );
  };
  return render();
};

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
