import React, { useEffect, useState } from "react";
import "./CandidateRequirements.scss";
import { useFormik } from "formik";
import { request } from "../../../utils/api";
import { getToken } from "../../../utils/user";
import {
  Autocomplete,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Button
} from "@mui/material";
import NormalInput from "../../../components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faArrowLeft, faArrowRight, faReplyAll } from "@fortawesome/free-solid-svg-icons";
import SelectComponent from "../../../components/Select";
import SkillCard from "../../Candidate/Profile/Skills";
import MultilineInput from "../../../components/MultilineInput";
import ButtonComponent from "../../../components/Button";
import { candidateRequirementsSchema } from "../../../schemas/employerSchema";
import {
  setCandidateRequirements,
  setSnackbarMessage,
  toggleSnackbar,
} from "../../../redux/actions";
import { connect } from "react-redux";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { employerPath } from "../../../utils/path";

const mapStateToProps = (state) => ({
  candidateRequirements: state.postJobReducer.candidateRequirements,
});

const mapDispatchToProps = (dispatch) => ({
  onSetCandidateRequirements: (data) =>
    dispatch(setCandidateRequirements(data)),
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const styles = {
  buttonContinueStyle: {
    backgroundColor: "#1876D1",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    alignSelf: 'flex-end',
    color: "#fff",
    fontFamily: 'Open Sans',
    fontSize: 12,
  },
  buttonBackStyle: {
    backgroundColor: "#1876D1",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    alignSelf: 'flex-end',
    color: "#fff",
    fontFamily: 'Open Sans',
    marginRight: 10,
    fontSize: 12,
  },
  buttonWrapper: { width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' },
  formHelperText: { color: '#d32f2f', fontFamily: "Open Sans", marginTop: 3, fontSize: 12, marginLeft: 15 },
  buttonPreviewStyle: {
    backgroundColor: "#000077", paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    alignSelf: 'flex-end',
    color: "#fff",
    fontFamily: 'Open Sans',
    fontSize: 12,
  }
}

const CandidateRequirements = ({
  screen,
  setScreen,
  candidateRequirements,
  onSetCandidateRequirements,
  setSnackbarMessage,
  setSnackbarState,
  jobId,
  setJobId,
  isEdit,
}) => {
  const [minEducationList, setMinEducationList] = useState([]);
  const [degreePrefList, setDegreePrefList] = useState([]);
  const [skillsCopy, setSkillsCopy] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillTyped, setSkillTyped] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDataSet, setIsDataSet] = useState(false);
  // const [selectedDegreeId, setSelectedDegreeId] = useState("");
  const [selectedMinEdu, setSelectedMinEdu] = useState("");
  const navigate = useNavigate();

  const handleNext = (values) => {
    // console.log(values);
    // console.log(selectedSkills);

    // onSetCandidateRequirements({ valid: true });

    const {
      minEducation,
      degreePref,
      femaleCandidatesPref,
      minExperience,
      maxExperience,
      description,
    } = values;

    //save candidate requirements in db

    const skillsToSend = [];

    selectedSkills.map((skill) => {
      let foundSkillObj = skillsCopy.find((skill2) => skill2.title === skill);

      skillsToSend.push({ id: foundSkillObj.id, name: foundSkillObj.title });
    });

    if (skillsToSend.length === 0) {
      setSnackbarState(true);
      setSnackbarMessage("Please add the skills required for the role");
      return;
    }

    // console.log(skillsToSend);

    const reqBody = {
      jobId: jobId,
      minimumEducation: minEducation,
      degreePref: degreePref,
      femaleCandidateAllowed: femaleCandidatesPref,
      minimumExperience: minExperience,
      maximumExperience: maxExperience,
      jobDesc: description,
      skills: skillsToSend,
    };

    setLoading(true);

    request
      .post("job/candidateRequirement/create", reqBody, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);

        setLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          setScreen(screen + 1);
          onSetCandidateRequirements({
            ...values,
            skills: selectedSkills,
            valid: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState(true);
        setSnackbarMessage(err.message);
      });
  };

  const { values, handleChange, handleBlur, errors, setValues, handleSubmit } =
    useFormik({
      initialValues: {
        minEducation: !isEdit ? "" : candidateRequirements.minEducation
          ? candidateRequirements.minEducation
          : "",
        degreePref: !isEdit ? "" : candidateRequirements.degreePref
          ? candidateRequirements.degreePref
          : "",
        femaleCandidatesPref: !isEdit ? "" : candidateRequirements.femaleCandidatesPref
          ? candidateRequirements.femaleCandidatesPref
          : "",
        minExperience: !isEdit ? "" : candidateRequirements.minExperience
          ? candidateRequirements.minExperience
          : "",
        maxExperience: !isEdit ? "" : candidateRequirements.maxExperience
          ? candidateRequirements.maxExperience
          : "",
        description: !isEdit ? "" : candidateRequirements.description
          ? candidateRequirements.description
          : "",
      },
      validationSchema: candidateRequirementsSchema,
      onSubmit: (values) => {
        console.log("submit")
        handleNext(values);
      },
    });
  useEffect(() => {
    console.log(jobId)
    if (isEdit && setValues && !isDataSet && degreePrefList.length > 0) {
      setLoading(true);
      request
        .post(
          "jobDeatilsById",
          {
            jobUniqueCode: jobId,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then(async (res) => {
          const jobData = res.data.data;
          console.log(jobData);
          const degreeId = degreePrefList?.find(item => item.title === jobData.degree)?.id;
          // handleSearchDegrees(degreeId);
          request
            .get(`master/course?degree=${degreeId}`, {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            })
            .then((resCourse) => {
              console.log(resCourse);
              setMinEducationList(resCourse.data.data);
              setSelectedMinEdu(jobData.minimum_education);
              setValues(
                {
                  minEducation: resCourse.data.data?.find(item => item.title === jobData.minimum_education)?.id,
                  degreePref: degreeId,
                  femaleCandidatesPref: jobData.female_candidate_allowed,
                  minExperience: jobData.minimum_experience,
                  maxExperience: jobData.maximum_experience,
                  description: jobData.job_description,
                }
              )
              setSelectedSkills(jobData.skills.map(item => item.name))
              setIsDataSet(true);
            })
            .catch((err) => {
              console.log(err.response.data.message);
              // setSnackbarState(true);
              // setSnackbarMessage(err.response.data.message);
            });
          // setSelectedDegreeId(degreeId);

          // setDegreePrefList(degreePrefList.find(item => item.title === jobData.degree))
          // console.log(minEducationList.find(item => item.title === jobData.minimum_education).id);
          // setIndustry(industryList.find(item => item.title === jobData.department));
          // setCategory(categoryList.find(item => item.title === jobData.category));
          // setPerksList(perksList.map(perk => {perk.title === jobData.additional_perks ? perk.status=true : perk.status = false; return perk;}));
          console.log(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [isEdit, setValues, isDataSet, degreePrefList, jobId])

  // useEffect(() => {
  //   console.log("")
  //   if (minEducationList.length > 0 && selectedMinEdu !== "")
  //     setValues(
  //       {
  //         ...values,
  //         minEducation: minEducationList?.find(item => item.title === selectedMinEdu)?.id,
  //       }
  //     )
  // }, [setValues, minEducationList, selectedMinEdu, values])

  // useEffect(()=>{
  //   if(values.degreePref === "" || values.degreePref === "4" || values.degreePref === "5" || values.degreePref === "6" || values.degreePref === "7"){
  //     setValues({
  //       ...values,
  //       minEducation: ""
  //     })
  //   }
  // },[setValues, values])
  useEffect(() => {
    //set Degree Preference if present
    // handleSearchDegrees(candidateRequirements.degreePref);

    //set selected skills
    if (candidateRequirements.skills.length > 0) {
      setSelectedSkills(candidateRequirements.skills);
    }

    //get employment types

    request
      .get("master/education/degree", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setDegreePrefList(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // setSnackbarState(true);
        // setSnackbarMessage(err.response.data.message);
      });

    //get skills

    request
      .get("master/skills", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);

        let newSkillsArray = [];
        response.data.data.map((skill) => {
          newSkillsArray.push(skill.title);
        });

        setSkills(newSkillsArray);
        setSkillsCopy(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleBack = () => {
    setScreen(screen - 1);
  };

  const handleSearchDegrees = (id) => {
    console.log(id);
    //get degree types

    request
      .get(`master/course?degree=${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setMinEducationList(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        // setSnackbarState(true);
        // setSnackbarMessage(err.response.data.message);
      });
  };

  return (
    <>
      {/* Job Details */}
      <h2 className="heading">Candidate Requirements</h2>
      <h4 className="sub_heading">
        We'll use these requirement details to make your job visible to the
        right candidates
      </h4>
      <div className="list-cont">
        {/* <div style={{display: "flex", width: '100%'}}> */}
        {/* <div className="radio-group-cont degreePref"> */}
        {/* <p className="radio-group-title">Degree Preference</p> */}
        {console.log("values.degreePref: ", values.degreePref)}
        {/* <FormControl
            error={errors.degreePref ? true : false}
            variant="standard"> */}
        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}>
          <SelectComponent
            style={{ width: "100%", marginBottom: 0 }}
            title={"Degree Preference"}
            list={degreePrefList}
            value={degreePrefList.find(item => item.id === values.degreePref)}
            onChange={(value) => {
              console.log(value)
              if (value === "" || value.id === "4" || value.id === "5" || value.id === "6" || value.id === "7") {
                setValues({
                  ...values,
                  degreePref: value.id,
                  minEducation: ""
                })
              }
              else {
                setValues({
                  ...values,
                  degreePref: value.id,
                })
              }
              handleSearchDegrees(value.id);
            }}
          />
          {errors.degreePref ? (
            <FormHelperText style={styles.formHelperText}>{errors.degreePref}</FormHelperText>
          ) : null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}>
          {minEducationList.length > 0 ? (
            <>
              {/* <p className="radio-group-title">Minimum Education</p> */}
              {/* <FormControl
              error={errors.minEducation ? true : false}
              variant="standard"> */}
              <SelectComponent
                style={{ width: "100%", marginBottom: 0 }}
                title={"Minimum Education"}
                list={minEducationList}
                value={minEducationList.find(item => item.id === values.minEducation)}
                onChange={(value) => setValues({ ...values, minEducation: value.id })}
              />
              {/* <RadioGroup
                className="radio-card-cont"
                row
                name="radio-buttons-group"
                value={values.minEducation}
                onChange={(e) => {
                  setValues({ ...values, minEducation: e.target.value });
                }}>
                {minEducationList.map((type) => {
                  return (
                    <FormControlLabel
                      key={type.id}
                      className="radio-cont"
                      value={type.id}
                      control={<Radio sx={{ padding: 0 }} size="small" />}
                      label={type.title}
                      checked={type.id === values.minEducation}
                    />
                  );
                })}
              </RadioGroup> */}
              {errors.minEducation ? (
                <FormHelperText style={styles.formHelperText}>{errors.minEducation}</FormHelperText>
              ) : null}
              {/* </FormControl> */}
            </>
          ) : null}
        </div>
        {/* <RadioGroup
              className="radio-card-cont"
              row
              name="radio-buttons-group"
              value={values.degreePref}
              onChange={(e) => {
                if (e.target.value === "" || e.target.value === "4" || e.target.value === "5" || e.target.value === "6" || e.target.value === "7") {
                  setValues({
                    ...values,
                    degreePref: e.target.value,
                    minEducation: ""
                  })
                }
                else {
                  setValues({
                    ...values,
                    degreePref: e.target.value,
                  })
                }
                handleSearchDegrees(e.target.value);
              }}>
              {console.log("degreePrefList", degreePrefList, values.degreePref)}
              {degreePrefList.map((type) => {
                return (
                  <FormControlLabel
                    key={type.id}
                    className="radio-cont"
                    value={type.id}
                    control={<Radio sx={{ padding: 0 }} size="small" />}
                    label={type.title}
                    checked={type.id === values.degreePref}
                  />
                );
              })}
            </RadioGroup> */}

        {/* </FormControl> */}
        {/* </div> */}

        {/* </div> */}
        <div className="radio-group-cont female-candidates">
          <p className="radio-group-title">
            Do you prefer hiring female candidates for this role and promote
            diversity in the workplace?
          </p>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RadioGroup
              className="radio-card-cont"
              row
              name="radio-buttons-group"
              value={values.femaleCandidatesPref}
              onChange={(e) =>
                setValues({ ...values, femaleCandidatesPref: e.target.value })
              }>
              <FormControlLabel
                className="radio-cont"
                value={"YES"}
                control={<Radio sx={{ padding: 0 }} size="small" />}
                label={"Yes"}
              />
              <FormControlLabel
                className="radio-cont"
                value={"NO"}
                control={<Radio sx={{ padding: 0 }} size="small" />}
                label={"No"}
              />
            </RadioGroup>
            {errors.femaleCandidatesPref ? (
              <FormHelperText style={styles.formHelperText}>{errors.femaleCandidatesPref}</FormHelperText>
            ) : null}
          </div>

        </div>
        <div className="experience-cont">
          <p className="experience-heading">Experience in years</p>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 0.2 }}>
              <NormalInput
                id={"minExperience"}
                name={"minExperience"}
                title={""}
                placeholder={"Enter min experience"}
                type={"text"}
                value={values.minExperience}
                onChange={handleChange}
                // error={errors.minExperience ? true : false}
                // errorMsg={errors.minExperience}
                onBlur={handleBlur}
                style={{ margin: 0 }}>
                {/* <FontAwesomeIcon icon={faStar} color={values.minExperience === undefined || values.minExperience === "" || values.minExperience === null ? "#757575" : "#1876D1"} /> */}
              </NormalInput>
              <div style={{ height: 12 }}>
                {errors.minExperience ? (
                  <FormHelperText style={styles.formHelperText}>{errors.minExperience}</FormHelperText>
                ) : null}
              </div>
            </div>
            <div style={{ marginLeft: 5, marginRight: 5 }}>
              <p style={{ fontSize: 12, fontFamily: 'Open Sans' }}>TO</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 0.2 }}>
              <NormalInput
                id={"maxExperience"}
                name={"maxExperience"}
                title={""}
                placeholder={"Enter max experience"}
                type={"text"}
                value={values.maxExperience}
                onChange={handleChange}
                // error={errors.maxExperience ? true : false}
                // errorMsg={errors.maxExperience}
                onBlur={handleBlur}
                style={{ margin: 0 }}>
                {/* <FontAwesomeIcon icon={faStar} color={values.maxExperience === undefined || values.maxExperience === "" || values.maxExperience === null ? "#757575" : "#1876D1"} /> */}
              </NormalInput>
              <div style={{ height: 12 }}>
                {errors.maxExperience ? (
                  <FormHelperText style={styles.formHelperText}>{errors.maxExperience}</FormHelperText>
                ) : null}</div>
            </div>
          </div>
        </div>
        <div className="skills-cont" style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}>
          <p className="skills-heading">Skills Preference</p>
          <p className="skills-sub-heading">
            You can add up to 10 key skills to make your job visible to the
            right candidates
          </p>
          <Autocomplete
            multiple
            freeSolo
            value={selectedSkills}
            onChange={(e, value) => setSelectedSkills(value)}
            disablePortal
            id="combo-box-demo"
            options={skills}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                onChange={(e) => setSkillTyped(e.target.value)}
                {...params}
              />
            )}
          />
        </div>
      </div>
      <Divider />
      {/* Job Description */}
      <h2 className="heading">Job Description</h2>
      <h4 className="sub_heading">
        Describe the responsibilities of this job and other specific
        requirements here
      </h4>
      <div className="list-cont">
        <div className="description-cont">
          <MultilineInput
            inputPropsStyle={{ height: 100 }}
            id="description"
            name="description"
            title={"Description"}
            placeholder={
              "Please describe the responsibilities, qualifications, and expectations for this job position."
            }
            value={values.description}
            onChange={handleChange}
            // error={errors.description ? true : false}
            // errorMsg={errors.description}
            onBlur={handleBlur}
          />
          {errors.description ? (
            <FormHelperText style={styles.formHelperText}>{errors.description}</FormHelperText>
          ) : null}
        </div>
      </div>

      {/* <div className="continue-back-btn-cont"> */}
      {loading ? (
        <div style={{ alignSelf: 'center', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SyncLoader
            color={"rgba(54, 215, 183, 1)"}
            loading={loading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
          {/* <ButtonComponent
              title={"Back"}
              variant={"outlined"}
              onClick={handleBack}
            /> */}
          <Button
            onClick={() => navigate(employerPath + `/jobs/${jobId}`)}
            style={styles.buttonPreviewStyle}
            variant="contained">
            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faReplyAll} color={"#fff"} />
            Exit
          </Button>
          <div style={styles.buttonWrapper}>
            <Button
              onClick={handleBack}
              style={styles.buttonBackStyle}
              // className="button"
              variant="contained">
              <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowLeft} color="#fff" />
              Back
            </Button>
            <Button
              onClick={handleSubmit}
              style={styles.buttonContinueStyle}
              // className="button"
              variant="contained"
              disabled={JSON.stringify(errors) !== '{}'}>
              Continue
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowRight} color="#fff" />
            </Button>
          </div>
          {/* <ButtonComponent
              title={"Continue"}
              variant={"contained"}
              onClick={handleSubmit}
            /> */}
        </div>
      )}
      {/* </div> */}
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateRequirements);
