import React from "react";
import "./style.scss";

const Chip = ({ title, style }) => {
  return (
    <div style={style ? style : null} className="chip-cont">
      <p className="chip-title">{title}</p>
    </div>
  );
};

export default Chip;
