import React from "react";
import "./style.scss";
import AppNavbar from "../../../components/Navbar";
import Drawer from "../../../components/Drawer";
import { useState } from "react";
import { getEmployerID, getToken } from "../../../utils/user";
import { request } from "../../../utils/api";
import { useEffect } from "react";
import CompanyInfo from "./CompanyInfo";
import EmployerBranding from "./EmployerBranding";
import SupportTeam from "./SupportTeam";
import NewNavbar from "../../../components/NewNavbar";

const EditProfile = () => {
  // const [data, setData] = useState(null);
  const [page, setPage] = useState(1);

  // const fetchDetails = () => {
  //   request
  //     .post(
  //       `employer/details`,
  //       {
  //         employerId: getEmployerID(),
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${getToken()}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("Employer details", res.data);
  //       setData(res.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   fetchDetails();
  // }, []);

  const getProfileContent = () => {
    switch (page) {
      case 1:
        return <CompanyInfo setPage={setPage} page={page} />;
      case 2:
        return <EmployerBranding setPage={setPage} page={page} />;
      case 3:
        return <SupportTeam />;
      default:
        return;
    }
  };

  return (
    <div className="profile-cont">
      {/* <AppNavbar /> */}
      <NewNavbar />
      <div className="sidebar-profile-cont">
        <Drawer />
        <div className="profile-listing">{getProfileContent()}</div>
      </div>
    </div>
  );
};

export default EditProfile;
