import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import AppNavbar from "../../../components/Navbar";
import { candidatePath } from "../../../utils/path";
import { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { request } from "../../../utils/api";
import { useFormik } from "formik";
import { registerSchema } from "../../../schemas";
import NormalInput from "../../../components/Input";
import PersonIcon from "@mui/icons-material/Person";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

function Signup(props) {
  const [passwordType, setPasswordType] = useState("password");

  const navigate = useNavigate();

  const doRegister = (values) => {
    const { email, password, name, mobileNo } = values;

    request
      .post("candidate/signup", {
        name: name,
        email: email,
        mobileNo: mobileNo,
        password: password,
      })
      .then((response) => {
        console.log(response);
        props.setSnackbarState(true);
        props.setSnackbarMessage(response.data.message);
        navigate(candidatePath + "/auth/login");
      })
      .catch((error) => {
        props.setSnackbarState(true);
        props.setSnackbarMessage(error.response.data.message);
      });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, setValues } =
    useFormik({
      initialValues: {
        email: "",
        name: "",
        mobileNo: "",
        password: "",
      },
      validationSchema: registerSchema,
      onSubmit: (values) => {
        // console.log(values);
        doRegister(values);
      },
    });

  return (
    <>
      <AppNavbar />
      <div className="section" style={{}}>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="mb-5">
            <h3 className="page-title-heading">Create Account </h3>
          </div>
          <div className="mb-3">
            <NormalInput
              id="name"
              name="name"
              title={"Name"}
              placeholder={"Enter your full name"}
              type={"text"}
              value={values.name}
              onChange={handleChange}
              error={errors.name ? true : false}
              errorMsg={errors.name}
              onBlur={handleBlur}>
              <PersonIcon fontSize="medium" />
            </NormalInput>
          </div>
          <div className="mb-3">
            <NormalInput
              id="email"
              name="email"
              title={"Email"}
              placeholder={"abc@domain.xy"}
              type={"text"}
              value={values.email}
              onChange={handleChange}
              error={errors.email ? true : false}
              errorMsg={errors.email}
              onBlur={handleBlur}>
              <AlternateEmailIcon fontSize="medium" />
            </NormalInput>
          </div>
          <div className="mb-3">
            <NormalInput
              id="mobileNo"
              name="mobileNo"
              title={"Mobile Number"}
              placeholder={"Enter your mobile number"}
              type={"mobile"}
              value={values.mobileNo}
              onChange={handleChange}
              error={errors.mobileNo ? true : false}
              errorMsg={errors.mobileNo}
              onBlur={handleBlur}>
              <PhoneIcon fontSize="medium" />
            </NormalInput>
          </div>
          <div className="mb-3">
            {/* <NormalInput
              id="password"
              name="password"
              title={"Password"}
              placeholder={"Enter password"}
              type={"password"}
              value={values.password}
              onChange={handleChange}
              error={errors.password ? true : false}
              errorMsg={errors.password}
              onBlur={handleBlur}>
              <VpnKeyIcon fontSize="medium" />
            </NormalInput> */}
            <div className="input-cont">
              <p className="input-title">Password</p>
              <TextField
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                type={passwordType}
                placeholder={"Enter Password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="icon-cont" position="start">
                      <VpnKeyIcon fontSize="medium" />
                    </InputAdornment>
                  ),
                  endAdornment:
                    passwordType === "text" ? (
                      <InputAdornment
                        onClick={() => setPasswordType("password")}
                        className="icon-cont"
                        position="start">
                        <VisibilityIcon fontSize="medium" />
                      </InputAdornment>
                    ) : (
                      <InputAdornment
                        onClick={() => setPasswordType("text")}
                        className="icon-cont"
                        position="start">
                        <VisibilityOffIcon fontSize="medium" />
                      </InputAdornment>
                    ),
                }}
                error={errors.password ? true : false}
                helperText={errors.password}
                onBlur={handleBlur}></TextField>
            </div>
          </div>
          <div className="mb-3 mt-5">
            <button
              onClick={handleSubmit}
              className="app-btn btn-expanded bg-blue text-white">
              Create Account
            </button>
          </div>
          <div className="mb-3 text-center">
            <Link to={candidatePath + "/auth/login"}>
              <span>
                Already have an account?{" "}
                <span className="text-blue">Login</span>
              </span>
            </Link>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
