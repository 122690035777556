import React from "react";
import "./BasicInfo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { useState } from "react";
import ButtonComponent from "../../../components/Button";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const BasicInfo = ({ data, setSnackbarState, setSnackbarMessage }) => {
  const { basic_info, branding } = data;
  const [mobileTooltip, setMobileTooltip] = useState(false);
  const [emailTooltip, setEmailTooltip] = useState(false);
  const [websiteTooltip, setWebsiteTooltip] = useState(false);
  const navigate = useNavigate();

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setSnackbarState(true);
        setSnackbarMessage("Copied to clipboard");
      })
      .catch((err) => {
        setSnackbarState(true);
        setSnackbarMessage("Error while copying to clipboard");
        console.error("Error in copying text: ", err);
      });
  };

  const handleTooltipOpen = (tooltipName, text) => {
    copyToClipboard(text);
    if (tooltipName === "mobile") {
      setMobileTooltip(true);
    } else if (tooltipName === "email") {
      setEmailTooltip(true);
    } else {
      setWebsiteTooltip(true);
    }
  };

  const handleTooltipClose = (tooltipName) => {
    if (tooltipName === "mobile") {
      setMobileTooltip(false);
    } else if (tooltipName === "email") {
      setEmailTooltip(false);
    } else {
      setWebsiteTooltip(false);
    }
  };

  return (
    <div className="basic-info-cont">
      <div className="basic-info-header">
        <div className="logo-cont">
          {branding.logo ? (
            <img className="logo-img" src={branding.logo} alt="logo" />
          ) : (
            <FontAwesomeIcon color="grey" fontSize={50} icon={faBuilding} />
          )}
        </div>
        <div className="details-cont">
          <h3 className="org-name">{basic_info.organization_name}</h3>
          <div className="connect-icons-cont">
            <ClickAwayListener onClickAway={() => handleTooltipClose("mobile")}>
              <Tooltip
                onClose={() => handleTooltipClose("mobile")}
                open={mobileTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={basic_info.mobile_no}
                placement="bottom"
              >
                <Link
                  to={"#"}
                  onClick={() =>
                    handleTooltipOpen("mobile", basic_info.mobile_no)
                  }
                  className="mobile-icon-cont"
                  title={basic_info.mobile_no}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    width={20}
                    height={20}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                </Link>
              </Tooltip>
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => handleTooltipClose("email")}>
              <Tooltip
                onClose={() => handleTooltipClose("email")}
                open={emailTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={basic_info.email_id}
                placement="bottom"
              >
                <Link
                  to={"#"}
                  title={basic_info.email_id}
                  onClick={() =>
                    handleTooltipOpen("email", basic_info.email_id)
                  }
                  className="email-icon-cont"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    width={20}
                    height={20}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </Link>
              </Tooltip>
            </ClickAwayListener>
            <ClickAwayListener
              onClickAway={() => handleTooltipClose("website")}
            >
              <Tooltip
                onClose={() => handleTooltipClose("website")}
                open={websiteTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={basic_info.website}
                placement="bottom"
              >
                <Link
                  to={"#"}
                  title={basic_info.website}
                  onClick={() =>
                    handleTooltipOpen("website", basic_info.website)
                  }
                  className="website-icon-cont"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    width={20}
                    height={20}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>
                </Link>
              </Tooltip>
            </ClickAwayListener>
          </div>
        </div>
      </div>
      {branding.profile_info && (
        <p className="basic-info-desc">{branding.profile_info}</p>
      )}
      <div className="basic-info-extra-cont">
        <div className="extra-info-cont">
          <p className="exta-info-title">Industry</p>
          <p className="exta-info-value">{basic_info.industry_type}</p>
        </div>
        <div className="extra-info-cont">
          <p className="exta-info-title">Funding stage</p>
          <p className="exta-info-value">{branding.stage_of_funding}</p>
        </div>
        <div className="extra-info-cont">
          <p className="exta-info-title">Last round funding</p>
          <p className="exta-info-value">
            {branding.currency} {branding.amount}
          </p>
        </div>
        <div className="extra-info-cont">
          <p className="exta-info-title">Active jobs</p>
          <p className="exta-info-value">0</p>
        </div>
      </div>
      <div className="media-handles">
        {branding.linkedin_link && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36 31C36 33.762 33.762 36 31 36H5C2.239 36 0 33.762 0 31V5C0 2.238 2.239 0 5 0H31C33.762 0 36 2.238 36 5V31Z"
              fill="#0288D1"
            />
            <path
              d="M6 13H11V30H6V13ZM8.485 11H8.457C6.965 11 6 9.888 6 8.499C6 7.08 6.995 6 8.514 6C10.035 6 10.972 7.08 11 8.499C11 9.887 10.035 11 8.485 11ZM30 30H25V20.901C25 18.703 23.775 17.203 21.808 17.203C20.307 17.203 19.495 18.215 19.101 19.193C18.957 19.543 19 20.511 19 21V30H14V13H19V15.616C19.721 14.5 20.85 13 23.738 13C27.316 13 29.999 15.25 29.999 20.274L30 30Z"
              fill="white"
            />
          </svg>
        )}
        {branding.instagram_link && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.017 36.99L9.01699 37.009C4.61699 37.013 1.01399 33.417 1.00899 29.017L0.989994 9.017C0.985994 4.617 4.58199 1.014 8.98199 1.009L28.982 0.990001C33.382 0.986001 36.985 4.582 36.99 8.982L37.009 28.982C37.014 33.383 33.417 36.986 29.017 36.99Z"
              fill="url(#paint0_radial_0_1)"
            />
            <path
              d="M29.017 36.99L9.01699 37.009C4.61699 37.013 1.01399 33.417 1.00899 29.017L0.989994 9.017C0.985994 4.617 4.58199 1.014 8.98199 1.009L28.982 0.990001C33.382 0.986001 36.985 4.582 36.99 8.982L37.009 28.982C37.014 33.383 33.417 36.986 29.017 36.99Z"
              fill="url(#paint1_radial_0_1)"
            />
            <path
              d="M19 26C15.141 26 12 22.86 12 19C12 15.14 15.141 12 19 12C22.859 12 26 15.14 26 19C26 22.86 22.859 26 19 26ZM19 14C16.243 14 14 16.243 14 19C14 21.757 16.243 24 19 24C21.757 24 24 21.757 24 19C24 16.243 21.757 14 19 14Z"
              fill="white"
            />
            <path
              d="M26.5 13C27.3284 13 28 12.3284 28 11.5C28 10.6716 27.3284 10 26.5 10C25.6716 10 25 10.6716 25 11.5C25 12.3284 25.6716 13 26.5 13Z"
              fill="white"
            />
            <path
              d="M25 32H13C9.141 32 6 28.86 6 25V13C6 9.14 9.141 6 13 6H25C28.859 6 32 9.14 32 13V25C32 28.86 28.859 32 25 32ZM13 8C10.243 8 8 10.243 8 13V25C8 27.757 10.243 30 13 30H25C27.757 30 30 27.757 30 25V13C30 10.243 27.757 8 25 8H13Z"
              fill="white"
            />
            <defs>
              <radialGradient
                id="paint0_radial_0_1"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(14.38 37.035) scale(44.899 44.899)"
              >
                <stop stop-color="#FFDD55" />
                <stop offset="0.328" stop-color="#FF543F" />
                <stop offset="0.348" stop-color="#FC5245" />
                <stop offset="0.504" stop-color="#E64771" />
                <stop offset="0.643" stop-color="#D53E91" />
                <stop offset="0.761" stop-color="#CC39A4" />
                <stop offset="0.841" stop-color="#C837AB" />
              </radialGradient>
              <radialGradient
                id="paint1_radial_0_1"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(6.78599 0.540304) scale(29.813 19.8644)"
              >
                <stop stop-color="#4168C9" />
                <stop offset="0.999" stop-color="#4168C9" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        )}
        {branding.facebook_link && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 0C13.9609 0 9.12816 2.00178 5.56497 5.56497C2.00178 9.12816 0 13.9609 0 19C0 24.0391 2.00178 28.8718 5.56497 32.435C9.12816 35.9982 13.9609 38 19 38C24.0391 38 28.8718 35.9982 32.435 32.435C35.9982 28.8718 38 24.0391 38 19C38 13.9609 35.9982 9.12816 32.435 5.56497C28.8718 2.00178 24.0391 0 19 0Z"
              fill="#039BE5"
            />
            <path
              d="M21.572 24.036H26.489L27.261 19.041H21.571V16.311C21.571 14.236 22.249 12.396 24.19 12.396H27.309V8.037C26.761 7.963 25.602 7.801 23.412 7.801C18.839 7.801 16.158 10.216 16.158 15.718V19.041H11.457V24.036H16.158V37.765C17.089 37.905 18.032 38 19 38C19.875 38 20.729 37.92 21.572 37.806V24.036Z"
              fill="white"
            />
          </svg>
        )}
        {branding.twitter_link && (
          <svg
            width="26"
            height="18"
            viewBox="0 0 37 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37 3.429C35.677 4.015 34.254 4.406 32.753 4.591C34.279 3.685 35.453 2.24 36.004 0.533C34.576 1.37 32.994 1.985 31.311 2.309C29.967 0.884 28.05 0 25.926 0C21.846 0 18.539 3.278 18.539 7.32C18.539 7.892 18.606 8.449 18.732 8.99C12.594 8.682 7.14999 5.764 3.50799 1.336C2.86799 2.418 2.50799 3.685 2.50799 5.022C2.50799 7.563 3.80899 9.8 5.79299 11.118C4.58199 11.081 3.44199 10.744 2.44399 10.204C2.44399 10.226 2.44399 10.259 2.44399 10.29C2.44399 13.841 4.99099 16.798 8.36699 17.471C7.74999 17.64 7.09799 17.734 6.42599 17.734C5.94899 17.734 5.48399 17.68 5.03399 17.599C5.97399 20.501 8.70099 22.622 11.932 22.685C9.40399 24.645 6.21999 25.819 2.75799 25.819C2.15999 25.819 1.57499 25.785 0.996994 25.715C4.26799 27.786 8.15199 29 12.321 29C25.906 29 33.338 17.844 33.338 8.166C33.338 7.849 33.328 7.533 33.313 7.221C34.763 6.197 36.013 4.905 37 3.429Z"
              fill="#03A9F4"
            />
          </svg>
        )}
        <ButtonComponent
          title={"Edit Profile"}
          variant={"contained"}
          style={{ width: "fit-content" }}
          onClick={() => navigate("/employer/edit-profile")}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
