import * as actions from "./actiontypes";

//snackbar actions

export const toggleSnackbar = (bool) => ({
  type: actions.TOGGLESNACKBAR,
  payload: bool,
});

export const setSnackbarMessage = (msg) => ({
  type: actions.SETSNACKBARMESSAGE,
  payload: msg,
});

//drawer actions

export const setDrawerOpen = (data) => ({
  type: actions.OPENDRAWER,
  payload: data,
});

export const setDrawerClose = (data) => ({
  type: actions.CLOSEDRAWER,
  payload: data,
});

//employer actions

export const setBasicDetails = (data) => ({
  type: actions.BASICDETAILS,
  payload: data,
});

export const setAccountInformation = (data) => ({
  type: actions.ACCOUNTINFORMATION,
  payload: data,
});

export const setContactInformation = (data) => ({
  type: actions.CONTACTINFORMATION,
  payload: data,
});

export const setJobDetails = (data) => ({
  type: actions.JOBDETAILS,
  payload: data,
});

export const setCandidateRequirements = (data) => ({
  type: actions.CANDIDATEREQUIREMENTS,
  payload: data,
});

export const setInterviewInformation = (data) => ({
  type: actions.INTERVIEWINFORMATION,
  payload: data,
});

export const resetJobDetails = () => ({
  type: actions.JOBDETAILS,
  payload: { title: "",
  businessName: "",
  industry: "",
  category: "",
  empType: "",
  locationType: "",
  address: "",
  payType: "",
  minSalary: "",
  maxSalary: "",
  isJoiningFee: "",
  perks: [],
  valid: false,
}
})

export const resetCandidateRequirements = () => ({
  type: actions.CANDIDATEREQUIREMENTS,
  payload:{
    minEducation: "",
    degreePref: "",
    femaleCandidatesPref: "",
    minExperience: "",
    maxExperience: "",
    description: "",
    skills: [],
    valid: false,
  }
})

export const resetInterviewInformation = () => ({
  type: actions.INTERVIEWINFORMATION,
  payload: {
    contactCandidate: "",
    interviewType: "",
    valid: false,
  }
})
