import React from 'react'
import Footer from '../../../components/Footer'
import CandidateNavBar from "../../../components/Candidate/Navbar";
import { MdOutlineCurrencyRupee, MdOutlineInsertDriveFile, MdOutlineWorkOutline } from "react-icons/md";

export default function RecommendedJobs() {
  return (
    <>
      <CandidateNavBar />
      <div className="section margin-top-65">
        <div className="container">
          <div className="row form-group">
            <div className="col-md-12">
              <h1 className="card-heading">Recommended jobs for you</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="app-card border-radius-15 mb-2">
                <h3 className="card-heading">Fullstack Developer</h3>
                <div className="card-content">
                  <h4 className="card-sub-heading">
                    Black And White Business Solutions
                  </h4>
                  <div className="text" style={{ paddingTop: "15px" }}>
                    <MdOutlineWorkOutline size={14} />
                    <span>5-8 Years</span>
                  </div>
                  <div className="text">
                    <MdOutlineCurrencyRupee size={14} />
                    <span>3,00,000/PA</span>
                  </div>
                  <div className="text">
                    <MdOutlineInsertDriveFile size={14} />
                    <span>
                      Experience representing work to a broader product team and
                      other leaders,clearly and succinctly articulating the
                      goals
                    </span>
                  </div>
                  <div className="text">
                    <ul className="skill-list">
                      <li>Skills:</li>
                      <li>Development</li>
                      <li>Java</li>
                      <li>Angular</li>
                    </ul>
                  </div>
                  <div className="">
                    <span className="badge badge-success-light">
                      27th Mar, 2023
                    </span>
                  </div>
                </div>
              </div>

              <div className="app-card border-radius-15">
                <h3 className="card-heading">Mern Stack (Lead Engineer)</h3>
                <div className="card-content">
                  <h4 className="card-sub-heading">
                    Silicon Techlab Lab, Bhubaneswar/Delhi
                  </h4>
                  <div
                    className="d-flex flex-row"
                    style={{ paddingTop: "15px" }}
                  >
                    <div className="text pr-2">
                      <MdOutlineWorkOutline size={14} />
                      <span>5-8 Years</span>
                    </div>
                    <div className="text pr-2">
                      <MdOutlineCurrencyRupee size={14} />
                      <span>3,00,000/PA</span>
                    </div>
                  </div>

                  <div className="text">
                    Experience representing work to a broader product team and
                    other leaders,clearly and succinctly articulating the goals
                  </div>
                  <div className="text">
                    <ul className="skill-list">
                      <li>Skills:</li>
                      <li>Development</li>
                      <li>Java</li>
                      <li>Angular</li>
                    </ul>
                  </div>
                  <div className="">
                    <span className="badge badge-success-light">
                      27th Mar, 2023
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="app-card border-radius-15 mb-2">
                <h3 className="card-heading">Your preferences</h3>
                <div className="card-content pt-3 preferences">
                  <p className="card-text">Your Preferred Job Roles</p>
                  <span className="badge badge-default mb-1">
                    Software Development Engineer
                  </span>
                  <span>&nbsp;&nbsp;</span>
                  <span className="badge badge-default mb-1">
                    Software Engineer
                  </span>
                  <span>&nbsp;&nbsp;</span>
                  <span className="badge badge-default mb-1">
                    Fullstack Engineer
                  </span>
                </div>
                <div className="card-content pt-4">
                  <p className="card-text">Your Preferred Locations</p>
                  <span className="badge badge-default">Bhubaneswar</span>
                  <span>&nbsp;&nbsp;</span>
                  <span className="badge badge-default">Banglore</span>
                  <span>&nbsp;&nbsp;</span>
                  <span className="badge badge-default">Huyderabad</span>
                </div>
              </div>
              <div className="app-card border-radius-15">
                <h3 className="card-heading">Top companies</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
