/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { candidatePath } from "../../../utils/path";
import "./BasicInfo.css";
import AppModal from "../../../components/Modal";
import BasicInfoForm from "../../../components/Forms/BasicInfo";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { request } from "../../../utils/api";
import { getToken, setUserName } from "../../../utils/user";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { numberToAmount } from "../../../utils/numberToAmount";

const mapStateToProps = (state) => ({
  isSnackbarOpen: state.snackbarReducer.open,
  snackbarMessage: state.snackbarReducer.message,
  snackbarVertical: state.snackbarReducer.vertical,
  snackbarHorizontal: state.snackbarReducer.horizontal,
  snackbarDuration: state.snackbarReducer.duration,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

function BasicInfo(props) {
  const [open, setOpen] = useState(false);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [userData, setUserData] = useState(null);
  const [whatsappTooltip, setWhatsappTooltip] = useState(false);
  const [mobileTooltip, setMobileTooltip] = useState(false);
  const [emailTooltip, setEmailTooltip] = useState(false);

  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    //fetch candidate details

    request
      .post(
        "candidate/basic",
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then((response) => {
        console.log(response);

        setUserData(response.data.data);

        setUserName(response.data.data.name);
        if (response.data.data.profile_image) {
          setImageSrc(
            response.data.data.profile_image_path +
              "/" +
              response.data.data.profile_image
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [profileImageFile]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    request
      .post(
        "candidate/profileImage/upload",
        { profile_image: e.target.files[0] },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          props.setSnackbarState(true);
          props.setSnackbarMessage(response.data.message);

          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);

          reader.onload = () => {
            localStorage.setItem("USER_PROFILE_IMG", reader.result);
            // setImageSrc(reader.result);
          };

          setProfileImageFile(e.target.files[0]);
        }
      })
      .catch((error) => {
        console.log(error);
        props.setSnackbarState(true);
        props.setSnackbarMessage("Something went wrong!");
      });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleTooltipOpen = (tooltipName, text) => {
    copyToClipboard(text);
    if (tooltipName === "whatsapp") {
      setWhatsappTooltip(true);
    } else if (tooltipName === "mobile") {
      setMobileTooltip(true);
    } else {
      setEmailTooltip(true);
    }
  };

  const handleTooltipClose = (tooltipName) => {
    if (tooltipName === "whatsapp") {
      setWhatsappTooltip(false);
    } else if (tooltipName === "mobile") {
      setMobileTooltip(false);
    } else {
      setEmailTooltip(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        props.setSnackbarState(true);
        props.setSnackbarMessage("Copied to clipboard");
      })
      .catch((err) => {
        props.setSnackbarState(true);
        props.setSnackbarMessage("Error while copying to clipboard");
        console.error("Error in copying text: ", err);
      });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="app-card" id="profile-card">
          <div className="row">
            <div className="col-md-2 text-center img-mobile-email">
              <div className="profile-img-cont">
                {imageSrc ? (
                  <img className="home-page-profile-img" src={imageSrc} />
                ) : (
                  <div className="no-profile-img-cont">
                    <i
                      style={{ fontSize: 50, color: "#ccc" }}
                      className="fas fa-user"
                    ></i>
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <div onClick={handleClick} className="edit-icon-cont">
                  <i className="fas fa-pen" style={{ fontSize: 12 }}></i>
                </div>
              </div>
              <div className="mobile-email-cont">
                {userData && userData.mobile_no && (
                  <ClickAwayListener
                    onClickAway={() => handleTooltipClose("whatsapp")}
                  >
                    <Tooltip
                      onClose={() => handleTooltipClose("whatsapp")}
                      open={whatsappTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={userData.mobile_no}
                      placement="bottom"
                    >
                      <Link
                        to={"#"}
                        onClick={() =>
                          handleTooltipOpen("whatsapp", userData.mobile_no)
                        }
                        className="wa-icon-cont"
                        title={userData.mobile_no}
                      >
                        <i
                          style={{
                            fontSize: 15,
                            color: "green",
                            fontWeight: 500,
                          }}
                          className="fab fa-whatsapp"
                        ></i>
                      </Link>
                    </Tooltip>
                  </ClickAwayListener>
                )}
                {userData && userData.mobile_no && (
                  <ClickAwayListener
                    onClickAway={() => handleTooltipClose("mobile")}
                  >
                    <Tooltip
                      onClose={() => handleTooltipClose("mobile")}
                      open={mobileTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={userData.mobile_no}
                      placement="bottom"
                    >
                      <Link
                        to={"#"}
                        onClick={() =>
                          handleTooltipOpen("mobile", userData.mobile_no)
                        }
                        className="mobile-icon-cont"
                        title={userData.mobile_no}
                      >
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 364 351"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M320.127 345C325.029 345.003 329.815 343.409 333.702 340.438C337.592 337.463 340.363 333.272 341.504 328.521L341.504 328.52L357.442 262.097L357.446 262.08L357.45 262.063C358.604 257.125 357.928 251.96 355.559 247.473C353.194 242.992 349.308 239.496 344.619 237.535L344.605 237.529L344.59 237.523L270.214 206.866L270.203 206.861C265.861 205.082 261.05 204.71 256.482 205.793C251.914 206.875 247.794 209.364 244.764 212.931C244.764 212.931 244.764 212.932 244.764 212.932C244.762 212.933 244.761 212.935 244.759 212.937L214.784 248.16C167.717 225.762 129.623 189.169 106.292 144.037L142.668 115.403C142.671 115.401 142.673 115.399 142.676 115.396C146.371 112.503 149.026 108.499 150.188 103.963C151.352 99.4203 150.943 94.6376 149.034 90.3526L149.034 90.3519L117.158 18.8193L117.15 18.8013L117.142 18.7834C115.055 14.1817 111.39 10.4786 106.832 8.26308C102.278 6.04936 97.0851 5.43992 92.1341 6.52223L92.1248 6.52427L92.1154 6.52634L23.0517 21.8547L23.0509 21.8549C18.2667 22.9174 13.9531 25.5177 10.8393 29.2837C7.72176 33.0542 5.99919 37.7653 6 42.651H11.9959L6 42.6524C6.00079 210.008 146.972 344.998 320.127 345ZM320.127 345L320.13 339V345C320.129 345 320.128 345 320.127 345Z"
                            stroke="red"
                            strokeWidth="25"
                          />
                        </svg>
                      </Link>
                    </Tooltip>
                  </ClickAwayListener>
                )}
                {userData && userData.email && (
                  <ClickAwayListener
                    onClickAway={() => handleTooltipClose("email")}
                  >
                    <Tooltip
                      onClose={() => handleTooltipClose("email")}
                      open={emailTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={userData.email}
                      placement="bottom"
                    >
                      <Link
                        to={"#"}
                        title={userData.email}
                        onClick={() =>
                          handleTooltipOpen("email", userData.email)
                        }
                        className="email-icon-cont"
                      >
                        <i
                          style={{ fontSize: 14, color: "blue" }}
                          className="far fa-envelope"
                        ></i>
                      </Link>
                    </Tooltip>
                  </ClickAwayListener>
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div className="name-title-edit-cont">
                <div className="name-title">
                  {userData && userData.name && (
                    <h3 className="name">{userData.name}</h3>
                  )}
                  {userData &&
                    userData.current_employment.designation &&
                    userData.current_employment.company_name && (
                      <h5 className="job-title mb-2">
                        {userData.current_employment.designation} at{" "}
                        {userData.current_employment.company_name}
                      </h5>
                    )}
                </div>
              </div>
              <div className="basic-details-grid mt-3">
                {userData && (
                  <div className="d-flex justify-content-start">
                    <span
                      className="fas fa-map-marker-alt text-grey"
                      style={{ lineHeight: "unset" }}
                    ></span>
                    <span className="ml-2">
                      {userData.current_employment.location
                        ? userData.current_employment.location
                        : "NA"}
                    </span>
                  </div>
                )}
                {userData && (
                  <div className="d-flex justify-content-start">
                    <span
                      className="fa-sharp fa-solid fa-briefcase text-grey"
                      style={{ lineHeight: "unset" }}
                    ></span>
                    <span className="ml-2">
                      {userData.experience ? userData.experience : "NA"}
                    </span>
                  </div>
                )}
                {userData && (
                  <div className="d-flex justify-content-start">
                    <span
                      className="fa-solid fa-calendar text-grey"
                      style={{ lineHeight: "unset" }}
                    ></span>
                    <span className="ml-2">
                      {userData.current_employment.notice_period
                        ? userData.current_employment.notice_period +
                          " notice period"
                        : "NA"}
                    </span>
                  </div>
                )}
                {userData && (
                  <div className="d-flex justify-content-start">
                    <span
                      className="fa-sharp fa-solid fa-indian-rupee-sign text-grey"
                      style={{ lineHeight: "unset" }}
                    ></span>
                    <span className="ml-2">
                      {userData.current_employment.salary
                        ? numberToAmount(
                            userData.current_employment.salary.split(" ")[1],
                            userData.current_employment.salary.split(" ")[0]
                          )
                        : "NA"}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-5">
              <nav className="mt-sm-3 mt-md-0">
                <ul className="nav justify-content-md-end justify-content-sm-center">
                  <li className="nav-item">
                    <Link
                      to={candidatePath + "/applied-jobs"}
                      className="nav-link btn-link basic-detail-link"
                    >
                      My Jobs
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to={candidatePath + "/recommended-jobs"}
                      className="nav-link btn-link text-white">
                      Recommended Jobs
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      to={candidatePath + "/myprofile"}
                      className="nav-link btn-link basic-detail-link"
                    >
                      My Profile
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={candidatePath + "/refer-a-friend"}
                      className="nav-link btn-link basic-detail-link"
                    >
                      Refer a Friend
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <AppModal open={open} closeModal={handleCloseModal} name={"Basic Info"}>
        <BasicInfoForm />
      </AppModal>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
