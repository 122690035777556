import React from "react";
import "./style.scss";
import waitingIcon from "../../../../assets/images/employer/waiting.svg";

const SupportTeam = () => {
  return (
    <div className="support-team-cont">
      <div className="message-cont">
        <h3 className="message-heading">
          Your company profile has been submitted
        </h3>
        <p className="message-body">
          Our team will reach out within 1-2 business days and help you onboard
          so you can start posting jobs and interacting with candidates.
        </p>
        <img className="waiting-icon" src={waitingIcon} alt="waiting" />
      </div>
    </div>
  );
};

export default SupportTeam;
