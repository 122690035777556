import React, { useState } from "react";
import "./style.scss";
import {
  faBuilding,
  faGlobe,
  faIdCard,
  faRegistered,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonComponent from "../../../../components/Button";
import NormalInput from "../../../../components/Input";
import { useFormik } from "formik";
import SelectComponent from "../../../../components/Select";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import UploadPopup from "../UploadPopup";
import { useEffect } from "react";
import { request } from "../../../../utils/api";
import { companyInfoSchema } from "../../../../schemas/employerSchema";
import { setSnackbarMessage, toggleSnackbar } from "../../../../redux/actions";
import { connect } from "react-redux";
import { getEmployerID, getToken } from "../../../../utils/user";
import { ClipLoader } from "react-spinners";
import BlackScreen from "../../../../components/BlackScreen";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const orgList = [
  {
    id: 1,
    title: "Individual/Self-Employed",
  },
  {
    id: 2,
    title: "Private Limited Company",
  },
  {
    id: 3,
    title: "Public Limited Company",
  },
  {
    id: 4,
    title: "Government Agency",
  },
  {
    id: 5,
    title: "Non-Profit(NGO)",
  },
  {
    id: 6,
    title: "Sole Proprietorship",
  },
  {
    id: 7,
    title: "Limited Liability Partnership",
  },
  {
    id: 8,
    title: "Partnership",
  },
  {
    id: 9,
    title: "Foundation/Trust",
  },
  {
    id: 10,
    title: "Educational",
  },
];

const msmeRegValues = [
  {
    id: 1,
    title: "Yes",
  },
  {
    id: 2,
    title: "No",
  },
];

const CompanyInfo = ({
  setSnackbarState,
  setSnackbarMessage,
  setPage,
  page,
}) => {
  // const { basic_info, branding } = data;

  const [orgTypeList, setOrgTypeList] = useState(orgList);
  const [orgType, setOrgType] = useState(orgList[0]);
  const [industryList, setIndustryList] = useState([]);
  const [industry, setIndustry] = useState(null);
  const [showMsmeRegNo, setShowMsmeRegNo] = useState(false);
  const [showOtherIndustry, setShowOtherIndustry] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [imageFileSrc, setImageFileSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [data, setData] = useState(null);
  // const [uploadBtnTitle, setUploadBtnTitle] = useState("Upload Logo");

  const handleNext = (values) => {
    console.log(values);

    const {
      orgName,
      gstin,
      msmeReg,
      msmeRegNo,
      otherIndustry,
      pancard,
      website,
    } = values;

    if (showMsmeRegNo & (msmeRegNo === "")) {
      console.log("here");
      setSnackbarState(true);
      setSnackbarMessage("MSME registration no is required!");
      return;
    }

    if (showOtherIndustry & (otherIndustry === "")) {
      setSnackbarState(true);
      setSnackbarMessage("Industry name is required!");
      return;
    }

    setLoading(true);

    const reqBody = {
      employerId: getEmployerID(),
      orgType: orgType.title,
      msmeUdyamRegdNo: showMsmeRegNo ? msmeRegNo : "",
      industryType: industry.id,
      indusrtyName: showOtherIndustry ? otherIndustry : "",
      gstNo: gstin,
      pancard: pancard,
      website: website,
    };

    request
      .post("employer/account-info", reqBody, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log(res);

        setLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          setPage(page + 1);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState(true);
        setSnackbarMessage("Something went wrong!");
      });
  };

  const { values, setValues, errors, handleSubmit, handleBlur, handleChange } =
    useFormik({
      initialValues: {
        orgName: localStorage.getItem("USER_NAME"),
        gstin: "",
        pancard: "",
        msmeReg: "",
        msmeRegNo: "",
        otherIndustry: "",
        website: "",
      },
      validationSchema: companyInfoSchema,
      onSubmit: (values) => {
        handleNext(values);
      },
    });

  const fetchDetails = async () => {
    try {
      const res = await request.post(
        `employer/details`,
        {
          employerId: getEmployerID(),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Employer details", res.data);
      setData(res.data.data);

      let basic_info = res.data.data.basic_info;
      let branding = res.data.data.branding;

      //set org type
      let org = orgList.find(
        (orgType) => orgType.title === basic_info.org_type
      );

      if (org) {
        setOrgType(org);
      }

      //set msme register field
      if (basic_info.msme_udyam_regd_no) {
        setShowMsmeRegNo(true);
      }

      //set logo image
      if (branding.logo) {
        setImageFileSrc(branding.logo);
      }

      setValues({
        ...values,
        website: basic_info.website ? basic_info.website : "",
        gstin: basic_info.gst_no ? basic_info.gst_no : "",
        pancard: basic_info.pancard ? basic_info.pancard : "",
        msmeReg: basic_info.msme_udyam_regd_no ? 1 : 2,
        msmeRegNo: basic_info.msme_udyam_regd_no
          ? basic_info.msme_udyam_regd_no
          : "",
      });

      return basic_info;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const src = localStorage.getItem("ORG_LOGO");

    if (src) {
      setImageFileSrc(src);
    }

    fetchDetails().then((basic_info_data) => {
      //get industry lists
      request
        .get("master/industries")
        .then((res) => {
          console.log(res);

          const newIndustryList = [...res.data.data];
          newIndustryList.push({ id: 0, title: "Other" });

          setIndustryList(newIndustryList);
          setIndustry(newIndustryList[0]);

          //set industry type

          let indsry = newIndustryList.find(
            (list) => list.title === basic_info_data.industry_type
          );

          if (indsry) {
            setIndustry(indsry);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, []);

  const getPancardNumber = (value) => {
    return value.substring(2, 12);
  };

  const handleIndustryChange = (value) => {
    setIndustry(value);
    if (value.title === "Other") {
      setShowOtherIndustry(true);
    } else {
      setShowOtherIndustry(false);
    }
  };

  const handleUploadPopup = () => {
    setShowUploadPopup(!showUploadPopup);

    const src = localStorage.getItem("ORG_LOGO");

    if (src) {
      setImageFileSrc(src);
    }
  };

  const handleUploadPhoto = (imageFile) => {
    setUploadLoading(true);
    request
      .post(
        "employer/logo/update",
        { logo: imageFile },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response);
        setUploadLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(response.data.message);

        if (response.data.status) {
          localStorage.setItem("ORG_LOGO", imageFileSrc);
          handleUploadPopup();
        }
      })
      .catch((error) => {
        console.log(error);
        setUploadLoading(false);
        setSnackbarState(true);
        setSnackbarMessage("Something went wrong!");
      });
  };

  return (
    <>
      <h2 className="company-info-title">
        Let's add some info about your company
      </h2>
      <p className="company-info-logo-title">Upload company logo</p>
      <div onClick={handleUploadPopup} className="company-info-logo-img">
        {imageFileSrc ? (
          <img className="company-logo-img" src={imageFileSrc} alt="org-logo" />
        ) : (
          <FontAwesomeIcon fontSize={40} icon={faBuilding} color="grey" />
        )}
        <div className="edit-icon-cont">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            style={{ width: 20, color: "black" }}>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
        </div>
      </div>
      {/* <ButtonComponent
        title={imageFileSrc ? "Change Logo" : "Upload Logo"}
        variant={"contained"}
        style={{
          width: "fit-content",
          fontSize: 14,
          padding: "5px 15px",
          marginBottom: "2rem",
        }}
        onClick={handleUploadPopup}
      /> */}
      <div className="company-info-form">
        <NormalInput
          id={"orgName"}
          name={"orgName"}
          title={"Organisation Name"}
          placeholder={"Enter organisation name"}
          type={"text"}
          value={values.orgName}
          onChange={handleChange}
          error={errors.orgName ? true : false}
          errorMsg={errors.orgName}
          onBlur={handleBlur}
          disabled={true}>
          <FontAwesomeIcon icon={faBuilding} />
        </NormalInput>
        <SelectComponent
          style={{ width: "100%" }}
          title={"Type of Organisation"}
          list={orgTypeList}
          value={orgType}
          onChange={(value) => setOrgType(value)}
        />
        <NormalInput
          id={"website"}
          name={"website"}
          title={"Website"}
          placeholder={"http(s)://abc.com"}
          type={"text"}
          value={values.website}
          onChange={handleChange}
          error={errors.website ? true : false}
          errorMsg={errors.website}
          onBlur={handleBlur}>
          <FontAwesomeIcon icon={faGlobe} />
        </NormalInput>
        <NormalInput
          id={"gstin"}
          name={"gstin"}
          title={"GSTIN"}
          placeholder={"Enter GSTIN number"}
          type={"text"}
          value={values.gstin}
          onChange={(e) => {
            setValues({
              ...values,
              gstin: e.target.value,
              pancard: getPancardNumber(e.target.value),
            });
          }}
          error={errors.gstin ? true : false}
          errorMsg={errors.gstin}
          onBlur={handleBlur}>
          <FontAwesomeIcon icon={faIdCard} />
        </NormalInput>
        <NormalInput
          id={"pancard"}
          name={"pancard"}
          title={"Pan Card Number"}
          placeholder={"Enter Pancard Number"}
          type={"text"}
          value={values.pancard}
          onChange={handleChange}
          error={errors.pancard ? true : false}
          errorMsg={errors.pancard}
          onBlur={handleBlur}
          disabled={false}>
          <FontAwesomeIcon icon={faIdCard} />
        </NormalInput>
        <div className="radio-group-cont msme_reg">
          <p className="radio-group-title">Is MSME Udyam Registered?</p>
          <FormControl error={errors.msmeReg ? true : false} variant="standard">
            <RadioGroup
              className="radio-card-cont"
              row
              name="radio-buttons-group"
              value={values.msmeReg}
              onChange={(e) => {
                setValues({ ...values, msmeReg: e.target.value });
                setShowMsmeRegNo(!showMsmeRegNo);
              }}>
              {msmeRegValues.map((type) => {
                return (
                  <FormControlLabel
                    key={type.id}
                    className="radio-cont"
                    value={type.id}
                    control={<Radio sx={{ padding: 0 }} size="small" />}
                    label={type.title}
                  />
                );
              })}
            </RadioGroup>
            {errors.empType ? (
              <FormHelperText>{errors.msmeReg}</FormHelperText>
            ) : null}
          </FormControl>
        </div>
        {showMsmeRegNo ? (
          <NormalInput
            id={"msmeRegNo"}
            name={"msmeRegNo"}
            title={"MSME Udyam Registration Number"}
            placeholder={"Enter MSME Registration Number"}
            type={"text"}
            value={values.msmeRegNo}
            onChange={handleChange}
            error={errors.msmeRegNo ? true : false}
            errorMsg={errors.msmeRegNo}
            onBlur={handleBlur}>
            <FontAwesomeIcon icon={faRegistered} />
          </NormalInput>
        ) : null}
        <SelectComponent
          style={{ width: "100%" }}
          title={"Industry"}
          list={industryList}
          value={industry}
          onChange={(value) => handleIndustryChange(value)}
        />
        {showOtherIndustry ? (
          <NormalInput
            id={"otherIndustry"}
            name={"otherIndustry"}
            title={"Industry Name"}
            placeholder={"Enter your Industry"}
            type={"text"}
            value={values.otherIndustry}
            onChange={handleChange}
            error={errors.otherIndustry ? true : false}
            errorMsg={errors.otherIndustry}
            onBlur={handleBlur}>
            <FontAwesomeIcon icon={faBuilding} />
          </NormalInput>
        ) : null}
        {loading ? (
          <div className="loader-cont">
            <ClipLoader
              color={"rgba(54, 215, 183, 1)"}
              loading={loading}
              size={35}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <ButtonComponent
            title={"Save"}
            variant={"contained"}
            style={{ width: "fit-content", padding: "6px 3rem" }}
            onClick={handleSubmit}
          />
        )}
      </div>
      {showUploadPopup ? <BlackScreen closePopup={handleUploadPopup} /> : null}
      {showUploadPopup ? (
        <UploadPopup
          closePopup={handleUploadPopup}
          handleUploadPhoto={handleUploadPhoto}
          loading={uploadLoading}
        />
      ) : null}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
