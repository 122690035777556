import React from 'react'
import { Col, Row } from 'reactstrap'
import './Home.css'
import sideImg from '../../assets/images/top-banner-side-img.png'

export default function TopBanner() {
  return (
    <div className='section margin-top-65'>
        <div className='container'>
            <Row>
                <Col md="7" style={{textAlign: 'left'}}>
                    <p><br/><br/><br/></p>
                    <h3 className='live-job-title'>WE HAVE 120,000+ LIVE JOBS</h3>
                    <h1 className='topper-banner-heading'>Find your dream job with <span className='text-blue'>Bultaminds</span></h1>
                    <button type='button' className='explore-btn bg-blue text-white'>Explore Now</button>
                </Col>
                <Col md="5">
                  <img src={sideImg} alt='bultamind-top-banner' className='side-img' />
                </Col>
            </Row>
        </div>
    </div>
  )
}
