import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import logo from '../../assets/images/logo-dark.png';
import './Footer.css';
import {  } from '@fortawesome/fontawesome-svg-core'

export default function Footer() {
  return (
    <footer className='footer bg-white'>
        <div className='container' style={{paddingBottom:'40px'}}>
            <Row>
                <Col md="3" style={{textAlign:'left'}}>
                    <img alt="Bultaminds Jobs" src={logo} style={{height:40}} />
                    <h3 className='footer-heading'>Connect with us</h3>
                    <Nav className='footer-nav' style={{marginTop: "-20px", marginLeft:"-15px"}}>
                        <NavItem>
                            <NavLink href='#'><i className="fa-brands fa-square-facebook"></i></NavLink>
                        </NavItem>
                         <NavItem>
                            <NavLink href='#'><i className="fa-brands fa-linkedin"></i></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#'><i className="fa-brands fa-square-instagram"></i></NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md="2">
                    <Nav className='footer-nav' vertical>
                        <NavItem>
                            <NavLink href='#'>About</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#'>Carrers</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#'>Support</NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md="2">
                    <Nav className='footer-nav' vertical>
                        <NavItem>
                            <NavLink href='#'>Pricing</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#'>Companies</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#'>Sitemap</NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md="2">
                    <Nav className='footer-nav' vertical>
                        <NavItem>
                            <NavLink href='#'>Privacy Policy</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#'>Terms & Condition</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#'>Data Policy</NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md="3">
                    <div className='app-download-sec'>

                    </div>
                </Col>
            </Row>
        </div>
        <div className='container text-center'>
            <div className='copyright'>
                 <p>All rights reserved &copy; 2023 Bultaminds Pvt. Ltd. Bhubaneswar</p>
            </div>
           
        </div>
    </footer>
  )
}
