import React, { useEffect } from "react";
import "./InterviewerInformation.scss";
import { useFormik } from "formik";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  FormHelperText,
} from "@mui/material";
import ButtonComponent from "../../../components/Button";
import NormalInput from "../../../components/Input";
import {
  setInterviewInformation,
  setSnackbarMessage,
  toggleSnackbar,
} from "../../../redux/actions";
import { connect } from "react-redux";
import { getToken } from "../../../utils/user";
import { request } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faEnvelopeOpenText,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import { employerPath } from "../../../utils/path";
import { interviewInformationSchema } from "../../../schemas/employerSchema";
import SelectComponent from "../../../components/Select";

const mapStateToProps = (state) => ({
  interviewInformation: state.postJobReducer.interviewInformation,
});

const mapDispatchToProps = (dispatch) => ({
  onSetInterviewInformation: (data) => dispatch(setInterviewInformation(data)),
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const styles = {
  buttonContinueStyle: {
    backgroundColor: "#1876D1",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    alignSelf: "flex-end",
    color: "#fff",
    fontFamily: "Open Sans",
    fontSize: 12,
  },
  buttonBackStyle: {
    backgroundColor: "#1876D1",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    alignSelf: "flex-end",
    color: "#fff",
    fontFamily: "Open Sans",
    marginRight: 10,
    fontSize: 12,
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: 15,
  },
  buttonPreviewStyle: {
    backgroundColor: "#000077",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
    alignSelf: "flex-end",
    color: "#fff",
    fontFamily: "Open Sans",
    fontSize: 12,
  },
  formHelperText: {
    color: "#d32f2f",
    fontFamily: "Open Sans",
    marginTop: 3,
    fontSize: 12,
    marginLeft: 15,
  },
};

const dropdownList = [
  { id: "0", title: "N/A" },
  { id: "1", title: "Face to Face" },
  { id: "2", title: "Telephonic" },
  { id: "3", title: "Online" },
];

const InterviewerInformation = ({
  screen,
  setScreen,
  interviewInformation,
  onSetInterviewInformation,
  setSnackbarState,
  setSnackbarMessage,
  jobId,
  setJobId,
  isEdit,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDataSet, setIsDataSet] = useState(false);
  const defaultWhatsappNumber = localStorage.getItem("CONTACT_NUMBER");

  const handleNext = (values) => {
    // console.log(values);

    const {
      contactCandidate,
      interviewType,
      whatsappNumber,
      interviewTypeRoundOne,
      interviewTypeRoundTwo,
      interviewTypeRoundThree,
    } = values;

    //save interview nformation in db

    const reqBody = {
      jobId: jobId,
      contactCandidate: contactCandidate,
      interviewMode: interviewTypeRoundOne,
      interviewMode2: interviewTypeRoundTwo,
      interviewMode3: interviewTypeRoundThree,
      whatsappNumber:
        contactCandidate === "2"
          ? whatsappNumber === ""
            ? defaultWhatsappNumber
            : whatsappNumber
          : "",
    };
    setLoading(true);

    request
      .post("job/interviewerInformation/create", reqBody, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);

        setLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status && res.data.data.jobId) {
          navigate(`/employer/jobs/${jobId}`);
          onSetInterviewInformation({
            ...values,
            valid: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };

  const { values, handleChange, handleBlur, errors, setValues, handleSubmit } =
    useFormik({
      initialValues: {
        contactCandidate: interviewInformation.contactCandidate
          ? interviewInformation.contactCandidate
          : "1",
        interviewType: interviewInformation.interviewType
          ? interviewInformation.interviewType
          : "",
        interviewTypeRoundOne: interviewInformation.interviewTypeRoundOne
          ? interviewInformation.interviewTypeRoundOne
          : "1",
        interviewTypeRoundTwo: interviewInformation.interviewTypeRoundTwo
          ? interviewInformation.interviewTypeRoundTwo
          : "0",
        interviewTypeRoundThree: interviewInformation.interviewTypeRoundThree
          ? interviewInformation.interviewTypeRoundThree
          : "0",
        whatsappNumber: interviewInformation.whatsappNumber
          ? interviewInformation.whatsappNumber
          : defaultWhatsappNumber,
      },
      validationSchema: interviewInformationSchema,
      onSubmit: (values) => {
        handleNext(values);
      },
    });

  const handleBack = () => {
    setScreen(screen - 1);
  };

  useEffect(() => {
    console.log(jobId);
    if (isEdit && setValues && !isDataSet && defaultWhatsappNumber !== "") {
      setLoading(true);
      request
        .post(
          "jobDeatilsById",
          {
            jobUniqueCode: jobId,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((res) => {
          const jobData = res.data.data;
          console.log(jobData);
          setValues({
            contactCandidate:
              jobData.contact_candidate_via_call_whatsapp === "No" ? "1" : "2",
            interviewTypeRoundOne:
              jobData.interviewTypeRoundOne !== null &&
              jobData.interviewTypeRoundOne !== ""
                ? dropdownList.find(
                    (item) => item.title === jobData.interview_mode
                  ).id
                : "1",
            interviewTypeRoundTwo:
              jobData.interviewTypeRoundTwo !== null &&
              jobData.interviewTypeRoundTwo !== ""
                ? dropdownList.find(
                    (item) => item.title === jobData.interview_mode_2
                  ).id
                : "0",
            interviewTypeRoundThree:
              jobData.interviewTypeRoundThree !== null &&
              jobData.interviewTypeRoundThree !== ""
                ? dropdownList.find(
                    (item) => item.title === jobData.interview_mode_3
                  ).id
                : "0",
            whatsappNumber:
              jobData.contact_candidate_via_call_whatsapp === "Yes"
                ? jobData.whatsapp_number &&
                  jobData.whatsapp_number !== "" &&
                  jobData.whatsapp_number !== null
                  ? jobData.whatsapp_number
                  : defaultWhatsappNumber
                : "",
          });
          console.log(res.data.data);
          setIsDataSet(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [isEdit, setValues, isDataSet, jobId, defaultWhatsappNumber]);

  return (
    <>
      {/* Interviewer Information */}
      <h2 className="heading">Communication Preferences</h2>
      {/* <h4 className="sub_heading">
        We'll use these requirement details to make your job visible to the
        right candidates
      </h4> */}
      <div className="list-cont">
        <div className="radio-group-cont contact-candidates">
          <p className="radio-group-title">
            Do you want candidates to contact you via Call/Whatsapp after they
            apply?
          </p>
          <div
            style={{
              display: "flex",
              backgroundColor: "transparent",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <RadioGroup
              className="radio-card-cont"
              style={{ marginTop: 30, marginBottom: 22 }}
              row
              name="radio-buttons-group"
              value={values.contactCandidate}
              onChange={(e) =>
                setValues({ ...values, contactCandidate: e.target.value })
              }
            >
              <FormControlLabel
                className="radio-cont"
                value={"2"}
                control={<Radio sx={{ padding: 0 }} size="small" />}
                label={"Yes"}
              />
              <FormControlLabel
                className="radio-cont"
                value={"1"}
                control={<Radio sx={{ padding: 0 }} size="small" />}
                label={"No"}
              />
            </RadioGroup>
            {values.contactCandidate === "2" ? (
              <NormalInput
                id={"whatsappNumber"}
                name={"whatsappNumber"}
                title={"Whatsapp number"}
                titleStyle={{ marginLeft: 50 }}
                style={{ width: "50%", marginTop: 0, marginLeft: 6 }}
                placeholder={`${defaultWhatsappNumber} will be used by default for whatsapp communication`}
                type={"text"}
                value={values.whatsappNumber}
                onChange={(value) => {
                  setValues({
                    ...values,
                    whatsappNumber: value.target.value,
                  });
                }}
                error={errors.whatsappNumber ? true : false}
                errorMsg={errors.whatsappNumber}
                onBlur={handleBlur}
              >
                {/* <FontAwesomeIcon icon={faEnvelopeOpenText} color={values.whatsappNumber === undefined || values.whatsappNumber === "" || values.whatsappNumber === null ? "#757575" : "#1876D1"} /> */}
              </NormalInput>
            ) : null}
          </div>
        </div>
      </div>
      <Divider />
      {console.log(
        dropdownList
          .map((item) => (item.id !== "0" ? item : null))
          .filter(Boolean)
      )}
      <h2 className="heading">Interview Information</h2>
      <div className="list-cont">
        <div
          className="radio-group-cont interview-type"
          style={{ display: "flex" }}
        >
          <SelectComponent
            style={{ width: "90%", marginBottom: 0 }}
            title={"Round 1"}
            list={dropdownList
              .map((item) => (item.id !== "0" ? item : null))
              .filter(Boolean)}
            value={dropdownList.find(
              (item) => item.id === values.interviewTypeRoundOne
            )}
            onChange={(value) =>
              setValues({ ...values, interviewTypeRoundOne: value.id })
            }
          />
          <SelectComponent
            style={{ width: "90%", marginBottom: 0 }}
            title={"Round 2"}
            list={dropdownList}
            value={dropdownList.find(
              (item) => item.id === values.interviewTypeRoundTwo
            )}
            onChange={(value) =>
              setValues({ ...values, interviewTypeRoundTwo: value.id })
            }
          />
          <SelectComponent
            style={{ width: "90%", marginBottom: 0 }}
            title={"Round 3"}
            list={dropdownList}
            value={
              values.interviewTypeRoundTwo === "0"
                ? { id: "0", title: "N/A" }
                : dropdownList.find(
                    (item) => item.id === values.interviewTypeRoundThree
                  )
            }
            onChange={(value) =>
              setValues({ ...values, interviewTypeRoundThree: value.id })
            }
            disabled={values.interviewTypeRoundTwo === "0"}
          />
          {/* <RadioGroup
            className="radio-card-cont"
            row
            name="radio-buttons-group"
            value={values.interviewType}
            onChange={(e) =>
              setValues({ ...values, interviewType: e.target.value })
            }>
            <FormControlLabel
              className="radio-cont"
              value={"1"}
              control={<Radio sx={{ padding: 0 }} size="small" />}
              label={"Face to Face"}
            />
            <FormControlLabel
              className="radio-cont"
              value={"2"}
              control={<Radio sx={{ padding: 0 }} size="small" />}
              label={"Telephonic"}
            />
            <FormControlLabel
              className="radio-cont"
              value={"3"}
              control={<Radio sx={{ padding: 0 }} size="small" />}
              label={"Online"}
            />
          </RadioGroup> */}
        </div>
      </div>
      <div>
        {loading ? (
          <div
            style={{
              alignSelf: "center",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SyncLoader
              color={"rgba(54, 215, 183, 1)"}
              loading={loading}
              style={{ alignSelf: "center" }}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div style={styles.buttonWrapper}>
            <Button
              onClick={() => navigate(employerPath + `/jobs/${jobId}`)}
              style={styles.buttonPreviewStyle}
              variant="contained"
            >
              <FontAwesomeIcon
                style={{ marginRight: 5 }}
                icon={faReplyAll}
                color={"#fff"}
              />
              Exit
            </Button>
            <div style={{ display: "flex" }}>
              <Button
                onClick={handleBack}
                style={styles.buttonBackStyle}
                // className="button"
                variant="contained"
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5 }}
                  icon={faArrowLeft}
                  color="#fff"
                />
                Back
              </Button>
              <Button
                onClick={handleSubmit}
                style={styles.buttonContinueStyle}
                // className="button"
                variant="contained"
                disabled={JSON.stringify(errors) !== "{}"}
              >
                Continue
                <FontAwesomeIcon
                  style={{ marginLeft: 5 }}
                  icon={faArrowRight}
                  color="#fff"
                />
              </Button>
            </div>
          </div>
          // <>
          //   <ButtonComponent
          //     title={"Back"}
          //     variant={"outlined"}
          //     onClick={handleBack}
          //   />
          //   <ButtonComponent
          //     title={"Continue"}
          //     variant={"contained"}
          //     onClick={handleSubmit}
          //   />
          // </>
        )}
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewerInformation);
