import React from "react";
import "./style.scss";

const IconComponent = ({ style, onClick, children }) => {
  return (
    <div
      onClick={onClick ? onClick : null}
      style={style ? style : null}
      className="icon-container">
      {children}
    </div>
  );
};

export default IconComponent;
