import React from "react";
import AppNavbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import NormalInput from "../../../components/Input";
import ButtonComponent from "../../../components/Button";
import { useState } from "react";
import { connect } from "react-redux";
import { setSnackbarMessage, toggleSnackbar } from "../../../redux/actions";
import { apiUrl, candidatePath } from "../../../utils/path";
import { request } from "../../../utils/api";
import { getUserId } from "../../../utils/user";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../../schemas";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const ChangePassword = (props) => {
  const navigate = useNavigate();

  const handleResetPassword = (values) => {
    const { password, confirmPassword } = values;

    request
      .post("candidate/changePassword", {
        newPassword: password,
        confPassword: confirmPassword,
        userId: getUserId(),
      })
      .then((res) => {
        console.log(res);
        props.setSnackbarState(true);
        props.setSnackbarMessage(res.data.message);

        navigate(candidatePath + "/auth/login");
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          props.setSnackbarState(true);
          props.setSnackbarMessage(error.data.message);
        }
      });
  };

  const { values, handleChange, handleBlur, handleSubmit, errors, setValues } =
    useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validationSchema: changePasswordSchema,
      onSubmit: (values) => {
        // console.log(values);
        handleResetPassword(values);
      },
    });

  return (
    <>
      <AppNavbar />
      <div className="section">
        <form className="change-password-form">
          <p className="change-password-heading">Set new password</p>
          <p className="change-password-text">
            Your new password must be different to previously used passwords.
          </p>
          <NormalInput
            id="password"
            name="password"
            title={"Password"}
            placeholder={"Enter password"}
            type={"password"}
            value={values.password}
            onChange={handleChange}
            error={errors.password ? true : false}
            errorMsg={errors.password}
            onBlur={handleBlur}>
            <VpnKeyIcon fontSize="medium" />
          </NormalInput>
          <NormalInput
            id="confirmPassword"
            name="confirmPassword"
            title={"Password"}
            placeholder={"Enter Confirm password"}
            type={"password"}
            value={values.confirmPassword}
            onChange={handleChange}
            error={errors.confirmPassword ? true : false}
            errorMsg={errors.confirmPassword}
            onBlur={handleBlur}>
            <VpnKeyIcon fontSize="medium" />
          </NormalInput>
          <ButtonComponent
            title={"Reset Password"}
            variant={"contained"}
            onClick={handleSubmit}
          />
        </form>
      </div>
      <Footer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
