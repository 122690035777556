import { createStore, combineReducers } from "redux";
import {
  snackbarReducer,
  userDetailsReducer,
  postJobReducer,
  drawerReducer,
} from "./reducer";

export const store = createStore(
  combineReducers({
    snackbarReducer,
    userDetailsReducer,
    postJobReducer,
    drawerReducer,
  })
);
