import React from "react";
import "./FileInput.css";
import { MuiFileInput } from "mui-file-input";
import { useState } from "react";

const FileInputComponent = ({ title, placeholder, value, onChange }) => {
  return (
    <div className="file-input-cont">
      <p className="file-input-title">{title}</p>
      <MuiFileInput
        className="file-input"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default FileInputComponent;
