export const numberToAmount = (num, code) => {
  //   console.log(
  //     Number(num).toLocaleString("en-US", { style: "currency", currency: "INR" })
  //   );
  if (code === "$") {
    return Number(num).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    return Number(num).toLocaleString("en-US", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
};
