import React from "react";
import "./style.scss";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { useState } from "react";
import { useEffect } from "react";

const MapContainer = ({ google, address = "" }) => {
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const geocodeAddress = () => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: "bhubaneswar, odisha" }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        setMapCenter({
          lat: location.lat(),
          lng: location.lng(),
        });
      } else {
        alert(`Geocode was not successful for the following reason: ${status}`);
      }
    });
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
    geocodeAddress();
  }, [address]);

  return (
    <Map
      google={google}
      zoom={14}
      style={{ width: "100%", height: "400px" }}
      initialCenter={{ lat: 0, lng: 0 }}
      center={mapCenter}>
      <Marker position={mapCenter} />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(MapContainer);
