import React from "react";
import "./Input.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import { InputAdornment, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

const style = {
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 14px",
    width: "100%",
    fontSize: "14px",
    paddingLeft: 0,
    fontFamily: "Open Sans",
  },
};

const NormalInput = ({
  id,
  name,
  title,
  placeholder,
  children,
  type,
  value,
  onChange,
  disabled = false,
  error,
  errorMsg,
  onBlur,
  style,
  titleStyle = {},
}) => {
  return (
    <div style={style ? style : null} className="input-cont">
      {title ? <p style={titleStyle} className="input-title">{title}</p> : null}
      <TextField
        id={id}
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        type={type}
        style={{backgroundColor: disabled ? 'rgb(235, 235, 228, 0.5)' : 'transparent'}}
        sx={style}
        placeholder={placeholder}
        InputProps={{
           startAdornment: children ? (
            <InputAdornment className="icon-cont" position="start">
              {children}
            </InputAdornment>
          ) : null,
        }}
        error={error}
        helperText={errorMsg}
        onBlur={onBlur}></TextField>
    </div>
  );
};

export default NormalInput;
