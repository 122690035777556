import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faLock,
  faLockOpen,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  setBasicDetails,
  setSnackbarMessage,
  toggleSnackbar,
} from "../../../../redux/actions";
import { request } from "../../../../utils/api";
import SelectComponent from "../../../../components/Select";
import NormalInput from "../../../../components/Input";
import { basicDetailsSchema } from "../../../../schemas/employerSchema";
import ButtonComponent from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../../../../components/Input/PasswordInput";
import { ClipLoader } from "react-spinners";

const mapStateToProps = (state) => ({
  // basicDetails: state.userDetailsReducer.basicDetails,
});

const mapDispatchToProps = (dispatch) => ({
  // onSetBasicDetails: (data) => dispatch(setBasicDetails(data)),
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const BasicDetailsForm = ({
  setScreen,
  screen,
  setSnackbarState,
  setSnackbarMessage,
}) => {
  const [whoamilist, setWhoamilist] = useState([]);
  const [whoami, setWhoami] = useState(null);
  const [leadSourceList, setLeadSourceList] = useState([]);
  const [leadSource, setLeadSource] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //get whoami list

    request
      .get("master/who-am-i")
      .then((res) => {
        console.log(res);
        setWhoamilist(res.data.data);
        setWhoami(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    //get leadsource list

    request
      .get("master/lead-source")
      .then((res) => {
        console.log(res);
        setLeadSourceList(res.data.data);
        setLeadSource(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleNext = (values) => {
    console.log(values);

    const { email, phoneNumber, orgName, password, confirmPassword } = values;

    if (password !== confirmPassword) {
      setSnackbarState(true);
      setSnackbarMessage("Password and Confirm Password doesn't match");
      return;
    }

    //save basic details in db

    const reqBody = {
      whoAmI: whoami.id,
      leadSource: leadSource.id,
      orgName: orgName,
      mobileNo: phoneNumber.toString(),
      email: email,
      password: password,
    };

    setLoading(true);

    request
      .post("employer/signup", reqBody)
      .then((res) => {
        console.log(res);

        setLoading(false);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          setScreen(screen + 1);
          localStorage.setItem("employer_id", res.data.data.employer_id);
          localStorage.setItem("user_id", res.data.data.user_id);
          localStorage.setItem("email", email);
          localStorage.setItem("mobile", phoneNumber);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };

  const { values, handleSubmit, handleBlur, handleChange, errors, setValues } =
    useFormik({
      initialValues: {
        orgName: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      validationSchema: basicDetailsSchema,
      onSubmit: (values) => {
        handleNext(values);
      },
    });

  return (
    <div className="basic-details-form-cont">
      {/* <h4 className="heading">Basic Details</h4> */}
      <SelectComponent
        style={{ width: "100%" }}
        title={"Who am I?"}
        list={whoamilist}
        value={whoami}
        onChange={(value) => setWhoami(value)}
      />
      <SelectComponent
        style={{ width: "100%" }}
        title={"Lead Source"}
        list={leadSourceList}
        value={leadSource}
        onChange={(value) => setLeadSource(value)}
      />
      <NormalInput
        id={"orgName"}
        name={"orgName"}
        title={"Organisation Name"}
        placeholder={"Enter organisation name"}
        type={"text"}
        value={values.orgName}
        onChange={handleChange}
        error={errors.orgName ? true : false}
        errorMsg={errors.orgName}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faBuilding} />
      </NormalInput>
      <NormalInput
        id={"phoneNumber"}
        name={"phoneNumber"}
        title={"Phone Number"}
        placeholder={"Enter phone number"}
        type={"number"}
        value={values.phoneNumber}
        onChange={handleChange}
        error={errors.phoneNumber ? true : false}
        errorMsg={errors.phoneNumber}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faPhone} />
      </NormalInput>
      <NormalInput
        id={"email"}
        name={"email"}
        title={"Email"}
        placeholder={"Enter email"}
        type={"email"}
        value={values.email}
        onChange={handleChange}
        error={errors.email ? true : false}
        errorMsg={errors.email}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faEnvelope} />
      </NormalInput>
      <PasswordInput
        id={"password"}
        name={"password"}
        title={"Password"}
        placeholder={"Enter a strong password"}
        type={passwordType}
        setType={setPasswordType}
        value={values.password}
        onChange={handleChange}
        error={errors.password ? true : false}
        errorMsg={errors.password}
        onBlur={handleBlur}>
        {passwordType === "text" ? (
          <FontAwesomeIcon icon={faLockOpen} />
        ) : (
          <FontAwesomeIcon icon={faLock} />
        )}
      </PasswordInput>
      <PasswordInput
        id={"confirmPassword"}
        name={"confirmPassword"}
        title={"Confirm Password"}
        placeholder={"Enter the same password"}
        type={confirmPasswordType}
        setType={setConfirmPasswordType}
        value={values.confirmPassword}
        onChange={handleChange}
        error={errors.confirmPassword ? true : false}
        errorMsg={errors.confirmPassword}
        onBlur={handleBlur}>
        {confirmPasswordType === "text" ? (
          <FontAwesomeIcon icon={faLockOpen} />
        ) : (
          <FontAwesomeIcon icon={faLock} />
        )}
      </PasswordInput>
      <div className="next-btn-cont">
        {loading ? (
          <ClipLoader
            color={"rgba(54, 215, 183, 1)"}
            loading={loading}
            size={35}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <ButtonComponent
            title={"Submit"}
            variant={"contained"}
            onClick={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetailsForm);
