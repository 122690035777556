import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { isCandidate, isEmployer, isLoggedIn } from "./utils/user";
import routes from "./routes/routes";
import candidateRoutes from "./routes/candidateRoutes";
import { setSnackbarMessage, toggleSnackbar } from "./redux/actions";
import { Snackbar } from "@mui/material";
import { connect } from "react-redux";
import Home from "./pages/Home";

const mapStateToProps = (state) => ({
  isSnackbarOpen: state.snackbarReducer.open,
  snackbarMessage: state.snackbarReducer.message,
  snackbarVertical: state.snackbarReducer.vertical,
  snackbarHorizontal: state.snackbarReducer.horizontal,
  snackbarDuration: state.snackbarReducer.duration,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

const App = (props) => {
  const vertical = props.snackbarVertical;
  const horizontal = props.snackbarHorizontal;

  const handleCloseSnackbar = () => {
    props.setSnackbarState(false);
  };

  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            title="Home | Bultaminds Jobs"
            element={<Home />}
          />
          {candidateRoutes.map((item, index) => {
            if (item.hasOwnProperty("isPublic") && item.isPublic) {
              return (
                <Route
                  exact
                  path={item.path}
                  key={index}
                  title={item.title}
                  element={item.component}
                />
              );
            }
            return (
              <Route
                exact
                path={item.path}
                key={index}
                title={item.title}
                element={
                  isLoggedIn() && isCandidate() ? (
                    item.component
                  ) : (
                    <Navigate to={"/"} />
                  )
                }
              />
            );
          })}
          {routes.map((route, index) => {
            if (route.hasOwnProperty("isPublic") && route.isPublic) {
              return (
                <Route
                  exact
                  path={route.path}
                  key={index}
                  title={route.title}
                  element={route.component}
                />
              );
            }
            return (
              <Route
                exact
                path={route.path}
                key={index}
                title={route.title}
                element={
                  isLoggedIn() && isEmployer() ? (
                    route.component
                  ) : (
                    <Navigate to={"/"} />
                  )
                }
              />
            );
          })}
        </Routes>
      </Router>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.isSnackbarOpen}
        onClose={handleCloseSnackbar}
        message={props.snackbarMessage}
        key={vertical + horizontal}
        autoHideDuration={props.snackbarDuration}
      />
    </>
  );
};

const ProtectedRoute = ({ child, ...all }) => {
  return (
    <Route
      {...all}
      element={({ location }) =>
        isLoggedIn() ? (
          child
        ) : (
          <Navigate to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
