import React from "react";
import "./style.scss";
import AppNavbar from "../../../components/Navbar";
import Drawer from "../../../components/Drawer";
import { useState } from "react";
import { getEmployerID, getToken } from "../../../utils/user";
import { useEffect } from "react";
import { request } from "../../../utils/api";
import BasicInfo from "./BasicInfo";
import Branding from "./Branding";
import NewNavbar from "../../../components/NewNavbar";
import Protip from "./Protip";
import RecruiterProfile from "./RecruiterProfile";
import { connect } from "react-redux";
import { setDrawerOpen } from "../../../redux/actions";
const mapStateToProps = (state) => ({
  nav: state.drawerReducer.nav,
});
const mapDispatchToProps = (dispatch) => ({
  onSetDrawerOpen: (data) => dispatch(setDrawerOpen(data)),
});

const Profile = ({ nav }) => {
  const [data, setData] = useState(null);

  const fetchDetails = () => {
    request
      .post(
        `employer/details`,
        {
          employerId: getEmployerID(),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log("Employer details", res.data);
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div className="profile-cont">
      <AppNavbar />
      <Drawer />
      {/* <NewNavbar /> */}
      <div
        className="content mobile-content sidebar-profile-cont"
        style={{ marginLeft: nav.width }}
      >
        <div className="profile-cards-cont">
          {data && <BasicInfo data={data} />}
          <Protip />
          <RecruiterProfile />
          {data && <Branding data={data.branding} />}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
