import React from "react";
import "./NavbarItem.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const NavbarItem = ({ title, color, active, id, isComplete }) => {
  return (
    <div style={{ borderLeft: `6px solid ${color}` }} className="navbar-item">
      <div style={{ backgroundColor: color }} className="select-circle">
        {isComplete() ? (
          <FontAwesomeIcon color="white" icon={faCheck} />
        ) : (
          <p className="select-number">{id}</p>
        )}
      </div>
      <p className="item-title">{title}</p>
    </div>
  );
};

export default NavbarItem;
