import React from "react";
import "./EducationCard.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const EducationCard = ({
  id,
  instituteName,
  courseName,
  degreeName,
  courseType,
  marksPercent,
  courseDuration,
  location,
  onClick,
}) => {
  return (
    <>
      <div className="education-card-cont">
        <div className="name-edit-delete-cont">
          <h4 className="institute-name">{instituteName}</h4>
          <div className="edit-delete-cont">
            <div onClick={() => onClick(id)} className="edit-icon">
              <i
                style={{ fontSize: 12, color: "white" }}
                class="fas fa-pen"></i>
            </div>
          </div>
        </div>
        <p className="institute-location">{location}</p>
        <p className="degree-course-name">
          {degreeName} - {courseName}
        </p>
        <p className="course-duration-type">
          {courseDuration}, {marksPercent}%, {courseType}
        </p>
      </div>
    </>
  );
};

export default EducationCard;
