import React, { useEffect, useState } from "react";
import "./style.scss";
import ButtonComponent from "../Button";
import {
  faMapMarkerAlt,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Chip from "../Chip";
import { Badge, Button } from "@mui/material";
import { employerPath } from "../../utils/path";

const JobCard = ({ job }) => {
  console.log("job: ", job);
  const {
    job_unique_code,
    job_title,
    status,
    company_hiring_for,
    service_type,
    service_plan,
    posted_date,
    location,
    currency,
    minimum_salary,
    maximum_salary,
    employment_type,
    minimum_experience,
    maximum_experience,
    category,
    applications,
  } = job;
  const [totalApplicationCount, setTotalApplicationCount] = useState("0");
  const [shortlistedApplicationCount, setShortlistedApplicationCount] =
    useState("0");
  const [appliedApplicationCount, setAppliedApplicationCount] = useState("0");
  const [hiredApplicationCount, setHiredApplicationCount] = useState("0");
  const [rejectedApplicationCount, setRejectedApplicationCount] = useState("0");

  useEffect(() => {
    applications.filter((item) => {
      if (item.application_status === "Applied") {
        setAppliedApplicationCount(item.total_applications);
      }
      if (item.application_status === "Shortlisted") {
        setShortlistedApplicationCount(item.total_applications);
      }
      if (item.application_status === "Rejected") {
        setRejectedApplicationCount(item.total_applications);
      }
      if (item.application_status === "Hired") {
        setHiredApplicationCount(item.total_applications);
      }
    });
  }, [applications]);

  useEffect(() => {
    setTotalApplicationCount(
      Number(shortlistedApplicationCount) +
        Number(appliedApplicationCount) +
        Number(hiredApplicationCount) +
        Number(rejectedApplicationCount)
    );
  }, [
    shortlistedApplicationCount,
    appliedApplicationCount,
    hiredApplicationCount,
    rejectedApplicationCount,
  ]);

  const navigate = useNavigate();

  const navigateToCandidates = (e) => {
    e.stopPropagation();
    navigate(`/employer/jobs/${job_unique_code}/candidates`);
  };

  const viewJob = (e) => {
    e.stopPropagation();
    navigate(`/employer/jobs/${job_unique_code}`);
  };

  const renderCandidateApplicationCount = () => {
    return (
      <>
        <div className="d-flex justify-content-between candidate-application-count-container">
          <Button
            onClick={(event) => {
              navigate(employerPath + "/jobs/applications", {
                state: { job: job, selectedApplicationType: "total" },
              });
              event.stopPropagation();
            }}
            className="inner"
          >
            <h6 className="head">Applied</h6>
            <span className="application-count">{totalApplicationCount}</span>
          </Button>
          <Button
            onClick={(event) => {
              navigate(employerPath + "/jobs/applications", {
                state: { job: job, selectedApplicationType: "hired" },
              });
              event.stopPropagation();
            }}
            className="inner"
          >
            <h6 className="head">Hired</h6>
            <span className="application-count">{hiredApplicationCount}</span>
          </Button>
          <Button
            onClick={(event) => {
              navigate(employerPath + "/jobs/applications", {
                state: { job: job, selectedApplicationType: "shortlisted" },
              });
              event.stopPropagation();
            }}
            className="inner"
          >
            <h6 className="head">Shortlisted</h6>
            <span className="application-count">
              {shortlistedApplicationCount}
            </span>
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="col-md-4 form-group no-pad">
      <div onClick={viewJob} className="employer-job-card">
        <div className="title-cont">
          <h3 className="title">{job_title}</h3>
          <p className="job-status">Active</p>
        </div>
        <div className="icon-details">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-color" />
          <p className="detail">{location}</p>
        </div>
        <div className="icon-details">
          <FontAwesomeIcon icon={faWallet} className="icon-color" />
          <p className="detail">{`${currency} ${minimum_salary} - ${maximum_salary}`}</p>
        </div>
        <div className="job-details">
          <div className="jobs-chips-cont">
            <Chip
              title={employment_type}
              style={{ backgroundColor: "#f1db6c", color: "#1e1f21" }}
            />
            {minimum_experience && maximum_experience ? (
              <Chip
                title={`${minimum_experience} - ${maximum_experience} Yrs Exp`}
                style={{ backgroundColor: "#e0f4ec", color: "#12714d" }}
              />
            ) : null}
            <Chip
              title={
                category.length > 12
                  ? category.substring(0, 10) + "..."
                  : category
              }
              style={{ backgroundColor: "#e0f4ec", color: "#12714d" }}
            />
          </div>
          {renderCandidateApplicationCount()}
          <div className="d-flex justify-content-between pb-3">
            <div className="posted-date">
              <span>Posted on: {posted_date}</span>
            </div>
            <div>
              <Link
                to={"#"}
                className="view-more renew-plan-link"
                title="Your plan is going to be closed, renew now!"
              >
                Renew your plan
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
