/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-dark.png";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { candidatePath, employerPath, rootPath } from "../../utils/path";
import { setDrawerClose, setDrawerOpen } from "../../redux/actions";
import { connect } from "react-redux";
import { request } from "../../utils/api";
import {
  getUserInfo,
  getUserName,
  isLoggedIn,
  removeUserInfo,
  removeUserName,
  getEmployerID,
  getToken,
} from "../../utils/user";
import {
  MdOutlineHandshake,
  MdOutlineHelpCenter,
  MdOutlineLogout,
  MdOutlineSettings,
} from "react-icons/md";

const CONSTANTS = {
  USER_TYPE: {
    EMPPLOYER: "Employer",
    CANDIDATE: "Candidate",
  },
};

const mapStateToProps = (state) => ({
  nav: state.drawerReducer.nav,
});

const mapDispatchToProps = (dispatch) => ({
  onSetDrawerOpen: (data) => dispatch(setDrawerOpen(data)),
  onSetDrawerClose: (data) => dispatch(setDrawerClose(data)),
});

const AppNavbar = ({
  onSetDrawerOpen,
  onSetDrawerClose,
  nav,
  searchData,
  isSearch,
  getChildData,
}) => {
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState("");
  const [employerBalance, setEmployerBalance] = useState(null);
  // const [searchFilteredData, setSearchFilteredData] = useState([]);
  const navigate = useNavigate();

  const generateShortName = () => {
    if (userName.length > 0) {
      const userNameArray = userName.split(" ");
      const firstCharacter = userNameArray[0].charAt(0);
      let lastCharacter = "";
      if (userNameArray.length > 1) {
        lastCharacter = userNameArray[userNameArray.length - 1].charAt(0);
      }
      return firstCharacter + lastCharacter;
    } else {
      return null;
    }
  };

  const fetchDetails = () => {
    request
      .post(
        `employer/balance`,
        {
          employerId: getEmployerID(),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setEmployerBalance(res.data.data[0].balance_amount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoggedIn()) {
      const userInfo = getUserInfo();
      if (userInfo) {
        setUserType(userInfo.userType);
        setUserName(getUserName());
        fetchDetails();
      }
    }
  }, []);

  const logout = () => {
    removeUserInfo();
    removeUserName();
    navigate(rootPath + "/");
    localStorage.clear();
  };
  //console.log(getUserName());

  const onSearchFieldChange = (e) => {
    const searchQuery = e.target.value;
    // console.log('searchQuery: ', searchQuery)
    // console.log('searchData: ', searchData)
    const filtered =
      searchQuery === "" || searchQuery === null
        ? []
        : searchData.filter(
            (item) =>
              item.job_title.toLowerCase().search(searchQuery.toLowerCase()) !==
                -1 || item.job_unique_code.search(searchQuery) !== -1
          );
    // console.log("filtered: ", filtered);
    getChildData.getFilteredData(filtered, searchQuery);
    e.preventDefault();
  };
  const employerRightMenu = () => {
    return (
      <>
        <li className="nav-item">
          <Link to={"#"} className="nav-link active" aria-current="page">
            <span className="balance-cont">Balance {employerBalance ?? 0}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"#"} className="nav-link active" aria-current="page">
            <span className="fa-sharp fa-regular fa-bell"></span>
          </Link>
        </li>

        {/* <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            We <span className="fa fa-heart" style={{ color: "red" }}></span>{" "}
            {userName}
          </Link>
          <ul className="dropdown-menu">
            
          </ul>
        </li> */}
        <li className="nav-item dropdown">
          <Link
            className="nav-link"
            to="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="short-name">{generateShortName()}</span>
          </Link>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <Link className="dropdown-item" to={employerPath + "/profile"}>
                <span className="badge badge-primary">
                  View & Update Profile
                </span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={employerPath + "/settings"}>
                <MdOutlineSettings />
                <span className="pl-2 pt-1">Settings</span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={candidatePath + "/faqs"}>
                <MdOutlineHelpCenter />
                <span className="pl-2 pt-1">FAQs</span>
              </Link>
            </li>
            {/* <li>
              <Link className="dropdown-item" to={employerPath + "/faqs"}>
                <MdOutlineHandshake />
                <span className="pl-2 pt-1">Candidate Agreement</span>
              </Link>
            </li> */}
            <li>
              <a
                className="dropdown-item"
                to="javascript:void(0)"
                onClick={logout}
              >
                <MdOutlineLogout />
                <span className="pl-2 pt-1">Logout</span>
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          {/* <Link
            className="nav-link dropdown-toggle"
            to="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {userName}
          </Link> */}
        </li>
      </>
    );
  };

  const candidateRightMenu = () => {
    return (
      <>
        <li className="nav-item">
          <Link to={"#"} className="nav-link active" aria-current="page">
            <span className="fa-sharp fa-regular fa-bell"></span>
          </Link>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {userName}
          </Link>
          <ul className="dropdown-menu">
            <li>
              <Link className="dropdown-item" to={candidatePath + "/myprofile"}>
                <span className="badge badge-primary">
                  View & Update Profile
                </span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={candidatePath + "/settings"}>
                <MdOutlineSettings />
                <span className="pl-2 pt-1">Settings</span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={candidatePath + "/faqs"}>
                <MdOutlineHelpCenter />
                <span className="pl-2 pt-1">FAQs</span>
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={candidatePath + "/faqs"}>
                <MdOutlineHandshake />
                <span className="pl-2 pt-1">Candidate Agreement</span>
              </Link>
            </li>
            <li>
              <a
                className="dropdown-item"
                to="javascript:void(0)"
                onClick={logout}
              >
                <MdOutlineLogout />
                <span className="pl-2 pt-1">Logout</span>
              </a>
            </li>
          </ul>
        </li>
      </>
    );
  };

  const renderRightMenu = () => {
    return (
      <>
        {isLoggedIn() ? (
          <>
            <ul className="navbar-nav mb-2 mb-lg-0">
              {userType && userType === CONSTANTS.USER_TYPE.CANDIDATE
                ? candidateRightMenu()
                : employerRightMenu()}
            </ul>
          </>
        ) : (
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to={candidatePath + "/auth/login"}>
                <button className="app-btn login-btn me-2">
                  Candidate Login
                </button>
              </Link>
            </li>
            <li className="nav-item">
              <Link to={employerPath + "/auth/login"}>
                <button className="app-btn bg-blue text-white">
                  Employer Login
                </button>
              </Link>
            </li>
          </ul>
        )}
      </>
    );
  };

  const handleDrawerOpen = () => {
    //console.log("kk")
    if (nav.width === 270) {
      onSetDrawerOpen({ open: false, width: 69 });
    } else {
      onSetDrawerOpen({ open: true, width: 270 });
    }
  };

  return (
    <nav
      className="navbar navbar-expand-lg bg-body-tertiary fixed-top"
      id="top-navbar"
    >
      <div className="container-fluid d-flex justify-content-start">
        {isLoggedIn() ? (
          <ul
            className="navbar-nav"
            style={{ marginLeft: "-12px", marginRight: 20 }}
          >
            <li className="nav-item">
              <button
                onClick={() => handleDrawerOpen()}
                type="button"
                className="nav-open-close-btn"
              >
                <span className="fa fa-bars"></span>
              </button>
            </li>
          </ul>
        ) : (
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#app-navbar"
            aria-controls="app-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="fa fa-bars"></span>
          </button>
        )}

        <a
          className="col-1-md navbar-brand"
          href={rootPath + "/"}
          // style={{ marginLeft: 47 }}
        >
          <img alt="Bultaminds Jobs" src={logo} style={{ height: 30 }} />
        </a>
        <div className="collapse navbar-collapse" id="app-navbar">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" style={{ fontSize: 13 }}>
                <span className="fa fa-heart" style={{ color: "red" }}></span>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {userName}
              </Link>
            </li>
          </ul>
          <form className="input-group w-50 mr-3">
            {isSearch ? (
              <input
                type="search"
                className="form-control search-text"
                id="exampleFormControlInput1"
                placeholder="Search active jobs"
                onChange={(e) => onSearchFieldChange(e)}
                aria-label="Search active jobs"
              />
            ) : null}
          </form>
          <div>{renderRightMenu()}</div>
        </div>
      </div>
    </nav>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavbar);
