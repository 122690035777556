import React, { useEffect, useState } from "react";
import "./style.scss";
import { useFormik } from "formik";
import { connect } from "react-redux";
import {
  setContactInformation,
  setSnackbarMessage,
  toggleSnackbar,
} from "../../../../redux/actions";
import { request } from "../../../../utils/api";
import NormalInput from "../../../../components/Input";
import ButtonComponent from "../../../../components/Button";
import { contactInformationSchema } from "../../../../schemas/employerSchema";
import SelectComponent from "../../../../components/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLandmark,
  faMapMarkerAlt,
  faPhone,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = (state) => ({
  contactInformation: state.userDetailsReducer.contactInformation,
});

const mapDispatchToProps = (dispatch) => ({
  onSetContactInformation: (data) => dispatch(setContactInformation(data)),
  setSnackbarState: (state) => dispatch(toggleSnackbar(state)),
  setSnackbarMessage: (msg) => dispatch(setSnackbarMessage(msg)),
});

// const genderList = [
//   {
//     id: 1,
//     name: "Male",
//   },
//   {
//     id: 2,
//     name: "Female",
//   },
//   {
//     id: 3,
//     name: "Not Applicable",
//   },
//   {
//     id: 4,
//     name: "Transgender",
//   },
// ];

// const departmentList = [
//   {
//     id: 1,
//     name: "Administration",
//   },
//   {
//     id: 2,
//     name: "Business Development",
//   },
//   {
//     id: 3,
//     name: "Finance",
//   },
//   {
//     id: 4,
//     name: "Human Resources",
//   },
// ];

// const statesList = [
//   { id: 1, name: "Andaman and Nicobar Islands" },
//   { id: 2, name: "Andhra Pradesh" },
//   { id: 3, name: "Arunachal Pradesh" },
//   { id: 4, name: "Assam" },
//   { id: 5, name: "Bihar" },
//   { id: 6, name: "Chandigarh" },
//   { id: 7, name: "Chhattisgarh" },
//   { id: 8, name: "Dadra and Nagar Haveli and Daman and Diu" },
//   { id: 9, name: "Delhi" },
//   { id: 10, name: "Goa" },
//   { id: 11, name: "Gujarat" },
//   { id: 12, name: "Haryana" },
//   { id: 13, name: "Himachal Pradesh" },
//   { id: 14, name: "Jammu and Kashmir" },
//   { id: 15, name: "Jharkhand" },
//   { id: 16, name: "Karnataka" },
//   { id: 17, name: "Kerala" },
//   { id: 18, name: "Ladakh" },
//   { id: 19, name: "Lakshadweep" },
//   { id: 20, name: "Madhya Pradesh" },
//   { id: 21, name: "Maharashtra" },
//   { id: 22, name: "Manipur" },
//   { id: 23, name: "Meghalaya" },
//   { id: 24, name: "Mizoram" },
//   { id: 25, name: "Nagaland" },
//   { id: 26, name: "Odisha" },
//   { id: 27, name: "Puducherry" },
//   { id: 28, name: "Punjab" },
//   { id: 29, name: "Rajasthan" },
//   { id: 30, name: "Sikkim" },
//   { id: 31, name: "Tamil Nadu" },
//   { id: 32, name: "Telangana" },
//   { id: 33, name: "Tripura" },
//   { id: 34, name: "Uttar Pradesh" },
//   { id: 35, name: "Uttarakhand" },
//   { id: 36, name: "West Bengal" },
// ];

const ContactInformationForm = ({
  screen,
  setScreen,
  onSetContactInformation,
  contactInformation,
  setSnackbarState,
  setSnackbarMessage,
  employerId,
  setEmployerId,
}) => {
  const [statesList, setStatesList] = useState([]);
  const [state, setState] = useState(null);

  useEffect(() => {
    //fetch states list

    request
      .get("master/state")
      .then((res) => {
        console.log(res);

        setStatesList(res.data.data);
        if (contactInformation.state) {
          setState(contactInformation.state);
        } else {
          setState(res.data.data[0]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleNext = (values) => {
    const {
      landmark,
      altEmail,
      altMobileNo,
      landlineNo,
      cityDist,
      address,
      locality,
      pincode,
    } = values;

    console.log(employerId, state);

    //save contact details in db

    const reqBody = {
      employerId: employerId,
      pincode: pincode,
      locality: locality,
      address: address,
      cityDistrict: cityDist,
      state: state.id,
      landLineNo: landlineNo,
      altMobileNo: altMobileNo,
      altEmail: altEmail,
      landmark: landmark,
    };

    request
      .post("employer/contact-info", reqBody)
      .then((res) => {
        console.log(res);

        setSnackbarState(true);
        setSnackbarMessage(res.data.message);

        if (res.data.status) {
          onSetContactInformation({ ...values, state: state });
          setScreen(screen + 1);
          setEmployerId(res.data.data.employer_id);
        }
        // onSetContactInformation({ ...values, state: state });
        // setScreen(screen + 1);
      })
      .catch((err) => {
        console.log(err);
        setSnackbarState(true);
        setSnackbarMessage(err.response.data.message);
      });
  };

  const handleBack = () => {
    onSetContactInformation({ ...values, state: state });
    setScreen(screen - 1);
  };

  const { values, handleSubmit, handleBlur, handleChange, errors, setValues } =
    useFormik({
      initialValues: {
        // firstName: contactInformation.firstName
        //   ? contactInformation.firstName
        //   : "",
        // middleName: contactInformation.middleName
        //   ? contactInformation.middleName
        //   : "",
        // lastName: contactInformation.lastName
        //   ? contactInformation.lastName
        //   : "",
        // gender: contactInformation.gender
        //   ? contactInformation.gender
        //   : genderList[0],
        // email: contactInformation.email ? contactInformation.email : "",
        // mobile: contactInformation.mobile ? contactInformation.mobile : "",
        // department: contactInformation.department
        //   ? contactInformation.department
        //   : departmentList[0],
        // designation: contactInformation.designation
        //   ? contactInformation.designation
        //   : "",
        pincode: contactInformation.pincode ? contactInformation.pincode : "",
        locality: contactInformation.locality
          ? contactInformation.locality
          : "",
        address: contactInformation.address ? contactInformation.address : "",
        cityDist: contactInformation.cityDist
          ? contactInformation.cityDist
          : "",
        // state: contactInformation.state
        //   ? contactInformation.state
        //   : statesList[0],
        landlineNo: contactInformation.landlineNo
          ? contactInformation.landlineNo
          : "",
        altMobileNo: contactInformation.altMobileNo
          ? contactInformation.altMobileNo
          : "",
        altEmail: contactInformation.altEmail
          ? contactInformation.altEmail
          : "",
        landmark: contactInformation.landmark
          ? contactInformation.landmark
          : "",
      },
      validationSchema: contactInformationSchema,
      onSubmit: (values) => {
        handleNext(values);
      },
    });
  return (
    <div className="contact-information-form-cont">
      <h4 className="heading">Business Address</h4>
      <NormalInput
        id={"pincode"}
        name={"pincode"}
        title={"Pincode"}
        placeholder={"Enter pincode"}
        type={"text"}
        value={values.pincode}
        onChange={handleChange}
        error={errors.pincode ? true : false}
        errorMsg={errors.pincode}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </NormalInput>
      <NormalInput
        id={"locality"}
        name={"locality"}
        title={"Locality"}
        placeholder={"Enter locality"}
        type={"text"}
        value={values.locality}
        onChange={handleChange}
        error={errors.locality ? true : false}
        errorMsg={errors.locality}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </NormalInput>
      <NormalInput
        id={"address"}
        name={"address"}
        title={"Address"}
        placeholder={"Enter address"}
        type={"text"}
        value={values.address}
        onChange={handleChange}
        error={errors.address ? true : false}
        errorMsg={errors.address}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </NormalInput>
      {/* <SelectComponent
        style={{ width: "100%" }}
        title={"Gender"}
        list={genderList}
        value={values.gender}
        onChange={(value) => setValues({ ...values, gender: value })}
      /> */}
      <NormalInput
        id={"cityDist"}
        name={"cityDist"}
        title={"City/District"}
        placeholder={"Enter city or district"}
        type={"text"}
        value={values.cityDist}
        onChange={handleChange}
        error={errors.cityDist ? true : false}
        errorMsg={errors.cityDist}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </NormalInput>
      <SelectComponent
        style={{ width: "100%" }}
        title={"State"}
        list={statesList}
        value={state}
        onChange={(value) => setState(value)}
      />
      <NormalInput
        id={"landlineNo"}
        name={"landlineNo"}
        title={"Landline Number"}
        placeholder={"Enter landline number"}
        type={"text"}
        value={values.landlineNo}
        onChange={handleChange}
        error={errors.landlineNo ? true : false}
        errorMsg={errors.landlineNo}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faPhone} />
      </NormalInput>
      <NormalInput
        id={"altMobileNo"}
        name={"altMobileNo"}
        title={"Alternate Mobile Number"}
        placeholder={"Enter alternate mobile number"}
        type={"number"}
        value={values.altMobileNo}
        onChange={handleChange}
        error={errors.altMobileNo ? true : false}
        errorMsg={errors.altMobileNo}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faPhone} />
      </NormalInput>
      {/* <SelectComponent
        style={{ width: "100%" }}
        title={"Department"}
        list={departmentList}
        value={values.department}
        onChange={(value) => setValues({ ...values, department: value })}
      /> */}
      <NormalInput
        id={"altEmail"}
        name={"altEmail"}
        title={"Alternate Email"}
        placeholder={"Enter alternate email"}
        type={"text"}
        value={values.altEmail}
        onChange={handleChange}
        error={errors.altEmail ? true : false}
        errorMsg={errors.altEmail}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faEnvelope} />
      </NormalInput>
      <NormalInput
        id={"landmark"}
        name={"landmark"}
        title={"Landmark"}
        placeholder={"Enter landmark"}
        type={"text"}
        value={values.landmark}
        onChange={handleChange}
        error={errors.landmark ? true : false}
        errorMsg={errors.landmark}
        onBlur={handleBlur}>
        <FontAwesomeIcon icon={faLandmark} />
      </NormalInput>
      <div className="back-save-btn-cont">
        <ButtonComponent
          onClick={handleBack}
          title={"Back"}
          variant={"outlined"}
        />
        <ButtonComponent
          onClick={handleSubmit}
          title={"Preview"}
          variant={"contained"}
        />
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInformationForm);
