import React from "react";
import "./SelectComponent.css";
import { MenuItem, OutlinedInput, Select } from "@mui/material";

const SelectComponent = ({
  title,
  list,
  value,
  onChange,
  showCode = false,
  style,
  disabled = false
}) => {
  const handleChange = (e) => {
    console.log("e.target.value", e.target.value);
    // onChange(e.target.value);
    let obj = list.find((val) => val.title === e.target.value);
    onChange(obj);
  };

  // console.log(value);

  return (
    <div style={style ? style : null} className="select-component-cont">
      {title ? <p className="select-component-title">{title}</p> : null}
      <Select
        input={<OutlinedInput />}
        className="select-component"
        style={{ ...style, backgroundColor: disabled ? 'rgb(235, 235, 228, 0.5)' : 'transparent' }}
        defaultValue={""}
        disabled={disabled}
        value={
          value
            ? value.name || value.value || value.title || value.course_name
            : ""
        }
        onChange={handleChange}
        MenuProps={{ classes: { list: "menu-item-parent" } }}
      >
          {list.map((item, index) => {
            if (showCode) {
              return (
                <MenuItem className="select-menu-item" value={item.title} key={index}>
                  {item.code}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem className="select-menu-item" value={item.title} key={index}>
                  {item.name || item.value || item.course_name || item.title}
                </MenuItem>
              );
            }
          })}
      </Select>
    </div>
  );
};

export default SelectComponent;
