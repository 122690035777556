import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("*Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "*Invalid email address"
    ),
  password: yup.string().required("*Required"),
});

export const basicDetailsSchema = yup.object().shape({
  orgName: yup.string().required("*Required"),
  phoneNumber: yup
    .string()
    .required("*Required")
    .matches(/^[6789]\d{9}$/, "*Invalid Mobile Number"),
  email: yup
    .string()
    .required("*Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "*Invalid email address"
    ),
  password: yup
    .string()
    .required("*Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirmPassword: yup.string().required("*Required"),
});

export const accountInformationSchema = yup.object().shape({
  //   orgCode: yup.string().required("*Required"),
  // displayName: yup.string().required("*Required"),
  website: yup
    .string()
    .required("*Required")
    .matches(
      /^(?:(?:https?):\/\/)?(?:www\.)?[a-z0-9]+[a-z0-9\-\.]+\.[a-z]{2,}(?:\/.*)?$/,
      "*Invalid website name"
    ),
  gstin: yup
    .string()
    .required("*Required")
    // .matches(
    //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z][0-9A-Z]$/,
    //   "*Invalid GSTIN"
    // ),
    .matches(
      /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([A-Z0-9]{1})([Z]{1})([0-9]{1})$/,
      "*Invalid GSTIN"
    ),
});

export const contactInformationSchema = yup.object().shape({
  pincode: yup
    .string()
    .required("*Required")
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, "*Invalid pincode"),
  address: yup.string().required("*Required"),
  cityDist: yup.string().required("*Required"),
  // landlineNo: yup
  //   .string()
  //   .required("*Required")
  //   .matches(
  //     /^[2-9]\d{1,4}-\d{6,10}$/,
  //     "*Landline number must be in the format STDCode-PhoneNumber"
  //   ),
  // altMobileNo: yup
  //   .string()
  //   .required("*Required")
  //   .matches(/^[6789]\d{9}$/, "*Invalid Mobile Number"),
  // altEmail: yup
  //   .string()
  //   .required("*Required")
  //   .matches(
  //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  //     "*Invalid email address"
  //   ),
});

export const passwordSchema = yup.object().shape({
  password: yup.string().required("*Required"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("*Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "*Invalid email address"
    ),
});

export const jobDetailsSchema = yup.object().shape({
  title: yup.string().required("*Required"),
  businessName: yup.string().required("*Required"),
  empType: yup.string().required("*Required"),
  locationType: yup.string().required("*Required"),
  address: yup.string().default(null).when(["locationType", "cityId"],{
    is: (locationType, cityId) => (locationType === "WFH") && (cityId === "0"),
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("*Required"),
  }),
  payType: yup.string().required("*Required"),
  minSalary: yup.string().when("payType", {
    is: (payType) => payType === "5",
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("*Required"),
  }),
  maxSalary: yup.string().when("payType", {
    is: (payType) => payType === "5",
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("*Required"),
  }),
  incentive: yup.string().when("payType", {
    is: (payType) => payType === "2" || payType === "5" || payType === "4",
    then: () => yup.string().required("*Required"),
    otherwise: () => yup.string().notRequired(),
  }),
  variable: yup.string().when("payType", {
    is: (payType) => payType === "3" || payType === "4",
    then: () => yup.string().required("*Required"),
    otherwise: () => yup.string().notRequired(),
  }),
  payFrequency: yup.string().required("*Required"),
  fieldWorkLocation: yup.string().when("locationType", {
    is: (locationType) => locationType === "FIELD",
    then: () => yup.string().required("*Required"),
    otherwise: () => yup.string().notRequired(),
  }),
  // cityId: yup.string().when("locationType", {
  //   is:(locationType, cityId)=> locationType === "WFH" && cityId !== null,
  //   then:()=> yup.string().required("*Required"),
  //   otherwise:()=> yup.string().notRequired(),
  // }),
  officeStateId: yup.string().when(["locationType", "cityId"], {
    is: (locationType, cityId) => (locationType === "WFH") && (cityId === "0"),
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("*Required"),
  }),
  officeCityId: yup.string().default(null).when(["locationType", "cityId"],{
    is: (locationType, cityId) => (locationType === "WFH") && (cityId === "0"),
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("*Required"),
    // is:(locationType, cityId)=> locationType === "WFH",
    // then:()=> yup.string().notRequired(),
    // otherwise:()=> yup.string().required("*Required"),
  }),
  // officeAddress: yup.string().required("*Required"),
  officePin: yup.string().default(null).when(["locationType", "cityId"],{
    is: (locationType, cityId) => (locationType === "WFH") && (cityId === "0"),
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("*Required"),
  }),
  officeLat: yup.string().notRequired(),
  officeLong: yup.string().notRequired(),
  officeLandmark: yup.string().default(null).when(["locationType", "cityId"],{
    is: (locationType, cityId) => (locationType === "WFH") && (cityId === "0"),
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("*Required"),
  }),
});

export const candidateRequirementsSchema = yup.object().shape({
  minEducation: yup.string().when("degreePref", {
    is: (degreePref) => degreePref === "" || degreePref === "7" || degreePref === "5" || degreePref === "6" || degreePref === "4",
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("*Required"),
  }),
  femaleCandidatesPref: yup.string().required("*Required"),
  degreePref: yup.string().required("*Required"),
  minExperience: yup.string().required("*Required"),
  maxExperience: yup.string().required("*Required"),
  description: yup.string().required("*Required"),
});

export const interviewInformationSchema = yup.object().shape({
  whatsappNumber: yup.string().when("contactCandidate", {
    is: (contactCandidate) => contactCandidate === "2",
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().notRequired(),
  }),
  // interviewType: yup.string().required("*Required"),
  interviewTypeRoundOne: yup.string().required("*Required"),
  interviewTypeRoundTwo: yup.string().required("*Required"),
  interviewTypeRoundThree: yup.string().required("*Required"),
  contactCandidate: yup.string().required("*Required"),
});

export const companyInfoSchema = yup.object().shape({
  orgName: yup.string().required("*Required"),
  gstin: yup.string().required("*Required"),
});
