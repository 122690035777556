import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./YearDatePicker.css";

const YearDatePickerComponent = ({
  title,
  value,
  onChange,
  id,
  name,
  error,
  errorMsg,
}) => {
  return (
    <div className="date-picker-cont">
      <p className="date-picker-title">{title}</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          id={id}
          name={name}
          views={["year"]}
          sx={{ width: "100%" }}
          className="date-picker"
          format="YYYY"
          orientation="landscape"
          onChange={onChange}
          value={value}
        />
      </LocalizationProvider>
      {error ? <p className="error-msg">{errorMsg}</p> : null}
    </div>
  );
};

export default YearDatePickerComponent;
